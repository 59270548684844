<template>
    <div class="p-3">
        <InfoTiles class="mb-4" />
        <h2 class="mb-2">Formularze</h2>
        <div class="flex justify-content-between align-items-center">
            <CustomCheckbox label="Pokaż zakończone" name="showCompleted" v-model="showFinished" />
            <CustomButton label="Wyczyść" @click="clear" class="mb-3" />
        </div>
        <ProgramsListTable ref="programsListTable" />
        <h2 v-if="isAdmin">Zdarzenia</h2>
        <AdminListTable v-if="isAdmin" ref="adminListTable" />
    </div>
</template>

<script>
    import {computed} from "vue";
    import InfoTiles from "./components/InfoTiles.vue";
    import ProgramsListTable from "./components/ProgramsListTable.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import AdminListTable from "./components/AdminListTable.vue";
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "ProgramsListView",

        components: {
            CustomCheckbox,
            InfoTiles,
            ProgramsListTable,
            CustomButton,
            AdminListTable,
        },

        data() {
            return {
                showFinished: false,
            };
        },

        provide() {
            return {
                showFinished: computed(() => this.showFinished),
            };
        },

        methods: {
            clear() {
                this.$refs.programsListTable.clear();
            },
        },

        computed: {
            isAdmin() {
                return SystemRole.isAdmin();
            },
        },
    };
</script>

<style lang="scss">
</style>
