<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="position" :geobjects="positions"
                              :controls="controls" v-model:dangers="internalForm.dangers">
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition :field="field" v-model="position" v-model:step="internalStep" :positions="positions"/>
        </template>
        <template v-slot:controlNestData>
            <div class="mt-1 flex column-gap-6 flex-wrap">
                <CustomSelectOne label="Umiejscowienie gniazda" v-model="position.treeType" :items="dicts.treeType"
                                 name="treeType" :disabled="!editing" required class="w-4"
                                 :showErrors="showErrorMessages"
                                 style="min-width: 220px;"/>
                <CustomCheckbox label="Nowe gniazdo" v-model="position.newNest" :disabled="!editing" name="newNest"/>
                <CustomCheckbox label="Sztuczne gniazdo" v-model="position.artificialNest" :disabled="!editing"
                                name="artificialNest"/>
            </div>
        </template>
        <template v-slot:controlMainData="slotProps">
            <SingleControlMGR v-model:control="slotProps.control" :secondControl="slotProps.index === 1"
                              :index="slotProps.index"/>
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import SingleControlMGR from "./SingleControlMGR.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";

    export default {
        name: "FormMGRObservations",

        components: {
            CustomCheckbox,
            CustomSelectOne,
            FormObservationsTemplate,
            ObservationsSelectPosition,
            SingleControlMGR,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
            allPositions: {
                type: Array,
                default: () => [],
            },
            field: {
                type: Array,
                default: () => [],
            },
            step: {
                type: Number,
                required: true,
            },
        },

        inject: ["editing", "dicts", "showErrorMessages"],

        emits: ["update:step"],

        data() {
            return {
                positions: [],
                position: null,
                activeIndex: 0,
                controls: [],
            };
        },

        beforeMount() {
            this.positions = this.allPositions.filter((p) => (p.notAudited === null || !p.notAudited));
            this.updatePositionAndControls(0);
        },

        methods: {
            updatePositionAndControls(newActiveIndex) {
                this.position = this.positions[newActiveIndex];
                if (this.positions[newActiveIndex].control1 != null) {
                    this.controls[0] = this.positions[newActiveIndex].control1;
                } else {
                    this.controls[0] = _.cloneDeep(EmptyStructures.getEmptyMGRControl());
                }
                if (this.positions[newActiveIndex].control2 != null) {
                    this.controls[1] = this.positions[newActiveIndex].control2;
                } else {
                    this.controls[1] = _.cloneDeep(EmptyStructures.getEmptyMGRControl());
                }
            },
            updateControl(index, value) {
                this.controls[index] = value;
            },
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
            "position.positionId"() {
                this.activeIndex = this.positions.indexOf(this.position);
            },
            activeIndex(value) {
                this.updatePositionAndControls(value);
            },
        },
    };
</script>

<style lang="scss" scoped>
@import "../../../assets/theme/mytheme/variables";
</style>
