<template>
    <DataTable :lazy="true" :value="geobjects" dataKey="id" :paginator="true" :totalRecords="totalRecords"
               :loading="loading" @page="onPage" @sort="onPage" removableSort :rows="10"
               :rowsPerPageOptions="[5,10,20,50]" size="small"
               v-model:first="searchCriteria.page.offset" v-model:rows="searchCriteria.page.limit"
               scrollable scroll-height="64.5vh" show-gridlines sortMode="multiple"
               paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
               :currentPageReportTemplate="currentPageReportTemplate">
        <Column header="Nazwa" field="name" sortable class="w-1 w-min"/>
        <Column v-if="name === 'position'" header="Nazwa zwyczajowa" field="commonName" sortable class="w-1 w-min"/>
        <Column v-if="name === 'position'" header="Właściciel" field="owner" sortField="owner.name" sortable class="w-1 w-min">
            <template #body="slotProps">
                {{ slotProps.data.owner == null ? "" : slotProps.data.owner.name }}
            </template>
        </Column>
        <Column v-if="name !== 'field'" header="Kod powierzchni" field="field.name" class="w-1" sortable/>
        <Column v-else header="Kod nadpowierzchni" field="superField.name" class="w-1" sortable sortField="field.name"/>
        <Column header="Program" field="program" class="w-1" sortable sortField="field.program"/>
        <Column header="Zaakceptowany" field="accepted" class="w-2">
            <template #body="slotProps">
                <div class="flex flex-column gap-3">
                    <div class="flex gap-2 justify-content-between align-items-center">
                        <div v-if="name !== 'field'" :class="slotProps.data.accepted ? 'accepted' : 'not-accepted'">
                            <span>{{liczbaPojedyncza}}:&nbsp;{{ slotProps.data.accepted ? "Tak" : "Nie" }}</span>
                        </div>
                        <div v-else :class="slotProps.data.accepted ? 'accepted' : 'not-accepted'">
                            <span>{{ slotProps.data.accepted ? "Tak" : "Nie" }}</span>
                        </div>
                        <CustomButton v-if="!slotProps.data.accepted && isAdmin" label="Akceptuj"
                                      @click="onAcceptGeobject(slotProps.data)"/>
                    </div>
                    <div v-if="name !== 'field'" class="flex gap-2 justify-content-between align-items-center">
                        <div :class="isHistoryAccepted(slotProps.data) ? 'accepted' : 'not-accepted'">
                            <span>Lokalizacja:&nbsp;{{ slotProps.data.lastHistoryId == null
                                ? "brak" : (isHistoryAccepted(slotProps.data) ? "Tak" : "Nie") }}</span>
                        </div>
                        <CustomButton v-if="slotProps.data.lastHistoryId != null && !isHistoryAccepted(slotProps.data)"
                                      label="Akceptuj" @click="onAcceptGeobjectHistory(slotProps.data)"/>
                    </div>
                </div>
            </template>
        </Column>
        <Column v-if="name === 'point' || name === 'transect'" header="Numer Kolejności" field="order" sortable class="w-1"/>
        <Column header="Szczegóły" class="w-3 w-min">
            <template #body="slotProps">
                <div class="flex gap-3">
                    <CustomButton label="Edytuj" @click="navigateToEditGeobject(slotProps.data.id)" icon="pi pi-pencil"/>
                </div>
            </template>
        </Column>
        <Column header="Województwo" field="geography.wojewodztwo">
            <template #body="slotProps">{{ getNames(slotProps.data.geography,"wojewodztwo") }}</template>
        </Column>
        <Column header="Powiat" field="geography.powiat">
            <template #body="slotProps">{{ getNames(slotProps.data.geography,"powiat") }}</template>
        </Column>
        <Column header="Gmina" field="geography.gmina">
            <template #body="slotProps">{{ getNames(slotProps.data.geography,"gmina") }}</template>
        </Column>
        <Column header="RDLP" field="geography.rdlp">
            <template #body="slotProps">{{ getNames(slotProps.data.geography,"rdlp") }}</template>
        </Column>
        <Column header="Nadleśnictwo" field="geography.nadlesnictwo">
            <template #body="slotProps">{{ getNames(slotProps.data.geography,"nadlesnictwo") }}</template>
        </Column>
        <Column header="Leśnictwo" field="geography.lesnictwo">
            <template #body="slotProps">{{ getNames(slotProps.data.geography,"lesnictwo") }}</template>
        </Column>
        <Column header="OSO" field="geography.obszarSpecjalnejOchrony">
            <template #body="slotProps">{{ getNames(slotProps.data.geography,"obszarSpecjalnejOchrony") }}</template>
        </Column>
        <Column header="OChK" field="geography.obszarChronionegoKrajobrazu">
            <template #body="slotProps">{{ getNames(slotProps.data.geography,"obszarChronionegoKrajobrazu") }}</template>
        </Column>
        <Column header="SOO" field="geography.specjalnyObszarOchrony">
            <template #body="slotProps">{{ getNames(slotProps.data.geography,"specjalnyObszarOchrony") }}</template>
        </Column>
        <Column header="RAMSAR" field="geography.ramsar">
            <template #body="slotProps">{{ getNames(slotProps.data.geography,"ramsar") }}</template>
        </Column>
        <Column header="Rezerwat" field="geography.rezerwat">
            <template #body="slotProps">{{ getNames(slotProps.data.geography,"rezerwat") }}</template>
        </Column>
        <Column header="Park krajobrazowy" field="geography.parkKrajobrazowy">
            <template #body="slotProps">{{ getNames(slotProps.data.geography,"parkKrajobrazowy") }}</template>
        </Column>
        <Column header="Park narodowy" field="geography.parkNarodowy">
            <template #body="slotProps">{{ getNames(slotProps.data.geography,"parkNarodowy") }}</template>
        </Column>
    </DataTable>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {FileUtils} from "@/utils/FileUtils";
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "DictTableTemplate",
        components: {
            CustomButton,
            DataTable,
            Column,
        },

        props: {
            modelValue: {
                type: Object,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            liczbaPojedyncza: {
                type: String,
                required: true,
            },
            liczbaMnoga: {
                type: String,
                required: true,
            },
            searchGeobjects: {
                type: Function,
                required: true,
            },
            searchGeobjectsCount: {
                type: Function,
                required: true,
            },
            acceptGeobject: {
                type: Function,
                required: true,
            },
            acceptGeobjectHistory: {
                type: Function,
                required: true,
            },
        },

        data() {
            return {
                geobjects: [],
                loading: false,
                totalRecords: 0,
            };
        },

        mounted() {
            this.search();
        },

        methods: {
            async search() {
                this.loading = true;
                let fieldNames;
                if (this.searchCriteria.fieldNames != null) {
                    fieldNames = this.searchCriteria.fieldNames.map((field) => field.name);
                }
                this.searchGeobjects({body: {...this.searchCriteria, fieldNames}})
                    .then((response) => {
                        this.geobjects = response.data;
                        console.log(this.geobjects);
                        return this.searchGeobjectsCount({body: {...this.searchCriteria, fieldNames}});
                    })
                    .then((response) => {
                        this.totalRecords = response.data;
                        this.loading = false;
                    });
            },
            onPage(event) {
                this.searchCriteria.page = {
                    offset: event.first,
                    limit: event.rows,
                    multiSort: event.multiSortMeta,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
            },
            navigateToEditGeobject(id) {
                this.$router.push({path: `/${this.name}/${id}`});
            },
            exportData() {
                FileUtils.downloadFile({}, `${this.liczbaMnoga}.xlsx`, `/${this.name}/export`);
            },
            getNames(data, key) {
                if (data === null) return "";
                return data[key].map((d) => d.name).join(", ");
            },
            onAcceptGeobject(data) {
                console.log(data);
                this.acceptGeobject({id: data.id})
                    .then(() => { this.search(); });
            },
            onAcceptGeobjectHistory(data) {
                this.acceptGeobjectHistory({id: data.lastHistoryId})
                    .then(() => { this.search(); });
            },
            isHistoryAccepted(data) {
                if (this.isAdmin) return data.adminAcceptedLastHistory;
                return data.acceptedLastHistory;
            },
        },

        computed: {
            isAdmin() {
                return SystemRole.isAdmin();
            },
            searchCriteria: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            currentPageReportTemplate() {
                return "Rekordy od "
                    + "{first}"
                    + " do "
                    + "{last}"
                    + " z "
                    + "{totalRecords}"
                    + " (Strona {currentPage}"
                    + " z "
                    + "{totalPages})";
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    this.search();
                    this.$emit("update:modelValue", value);
                },
                deep: true,
            },
        },

    };
</script>

<style scoped lang="scss">
.accepted, .not-accepted {
    padding: 2px 8px;
    width: fit-content;
    border-radius: 4px;
}
.accepted {
    color: var(--secondary-color);
}
.not-accepted {
    color: var(--red);
}
.accept-link {
    cursor: default;
}
</style>
