<template>
    <ImportCastDialog v-model="showImportCastDialog" />
    <CustomButton label="Importuj obserwatorów"
                  bgColor="var(--secondary-color)" color="var(--surface-a)"
                  @click="this.showImportCastDialog = true;" />
</template>

<script>

    import ImportCastDialog
        from "@/views/monitoringProgram/components/observer/ImportCastDialog.vue";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "GlobalImportCastView",

        components: {
            CustomButton,
            ImportCastDialog,
        },

        data() {
            return {
                showImportCastDialog: true,
            };
        },
    };
</script>
