<template>
    <div class="monitoring-program-regional-coordinators">
        <ImportRegionalCoordinatorsDialog v-model="showImportCastDialog"
                                          @reloadProgramRegionalCoord="reloadProgramRegionalCoord" />
        <HeaderComponent headerText="Koordynatorzy regionalni" class=" my-0" :buttons="buttons"
                         @clickButton="clickButton" />
        <!-- <RegionalCoordinatorsSearchCriteria @edit="edit" :editCast="internalEditCast" class="mx-2" /> -->
        <RegionalCoordinatorsTable :editCast="internalEditCast" @reloadProgramState="reloadProgramState"
                                   ref="regionalCoordinatorsTable" :key="key"
                                   v-model="regionalCoordinators" />
    </div>
</template>

<script>
    import {FileUtils} from "@/utils/FileUtils";
    import {SystemRole} from "@/utils/SystemRole";
    // import RegionalCoordinatorsSearchCriteria from "./RegionalCoordinatorsSearchCriteria.vue";
    import RegionalCoordinatorsTable
        from "./RegionalCoordinatorsTable.vue";
    import HeaderComponent from "@/components/HeaderComponent.vue";
    import ImportRegionalCoordinatorsDialog
        from "./ImportRegionalCoordinatorsDialog.vue";

    export default {
        name: "RegionalCoordinators",

        components: {
            ImportRegionalCoordinatorsDialog,
            HeaderComponent,
            RegionalCoordinatorsTable,
            // RegionalCoordinatorsSearchCriteria,
        },

        props: {
            editCast: null,
            modelValue: {
                type: Array,
            },
        },

        inject: ["programCode", "programEditionState", "fields"],

        data() {
            return {
                showImportCastDialog: false,
                key: 1,
                regCoords: [],
                buttons: [
                    {
                        label: "Importuj koordynatorów",
                        onClick: () => { this.showImportCastDialog = true; },
                        show: false,
                    },
                    {
                        label: "Eksportuj",
                        bgColor: "var(--surface-a)",
                        labelColor: "var(--text-color)",
                        onClick: () => this.exportRegionalCoordinators(),
                        show: false,
                    },
                ],
            };
        },

        emits: ["reloadProgramState", "update:modelValue", "update:editCast"],

        beforeMount() {
            this.buttons[0].show = this.internalEditCast && this.canImportCast;
            this.buttons[1].show = this.canExport;
        },

        methods: {
            reloadProgramState(value) {
                this.$emit("reloadProgramState", value);
            },
            edit() {
                this.internalEditCast = !this.internalEditCast;
            },
            reloadProgramRegionalCoord() {
                this.reloadProgramState();
                setTimeout(() => {
                    this.key += 1;
                }, 1000);
            },
            clickButton(value) {
                if (this.buttons[value].onClick) {
                    this.buttons[value].onClick();
                }
            },
            exportRegionalCoordinators() {
                FileUtils.downloadFile(
                    "", "obsadaRegionalna.xlsx", `/programEdition/regional-cast/export/${this.$route.params.id}`);
            },
            // getFilteredFieldsList(regFields) {
            //     listSubfields({programCode: this.programCode})
            //         .then((response) => {
            //             this.fields = response.data;
            //             this.checkAvailableFields(regFields);
            //             this.loadFields = true;
            //         });
            // },
            // checkAvailableFields(value) {
            //     const index = [];
            //     for (let i = 0; i < this.fields.length; i += 1) {
            //         for (let j = 0; j < value.length; j += 1) {
            //             if (this.fields[i].name === value[j]) {
            //                 index.push(i);
            //             }
            //         }
            //     }
            //     // eslint-disable-next-line
            //     this.fields = this.fields.filter((val, ind) => {
            //         return index.indexOf(ind) === -1;
            //     });
            // },
        },

        // watch: {
        //     regCoords: {
        //         handler(newVal) {
        //             const arr = [];
        //             for (let i = 0; i < newVal.length; i += 1) {
        //                 for (let j = 0; j < newVal[i].fields.length; j += 1) {
        //                     arr.push(newVal[i].fields[j].name);
        //                 }
        //             }
        //             this.getFilteredFieldsList(arr);
        //         },
        //         deep: true,
        //     },
        // },

        computed: {
            canImportCast() {
                return SystemRole.isMainCoordinator()
                    || (SystemRole.isNationalCoordinator() && this.programEditionState !== "PROGRAM_FINISHED");
            },
            internalEditCast: {
                get() { return this.editCast; },
                set(value) { this.$emit("update:editCast", value); },
            },
            regionalCoordinators: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            canExport() {
                return (this.programEditionState !== "CAST_NOT_ENTERED"
                    && this.programEditionState !== "CAST_WAITING_FOR_NATIONAL_APPROVAL"
                    && this.programEditionState !== "CAST_WAITING_FOR_MAIN_APPROVAL");
            },
        },

        watch: {
            regionalCoordinators: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
            internalEditCast(value) {
                this.buttons[0].show = value && this.canImportCast;
            },
            canExport(value) {
                this.buttons[1].show = value;
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../../assets/theme/mytheme/variables";
    .monitoring-program-regional-coordinators {
        border: 1px solid var(--surface-border);
        margin-top: 1.5rem;
    }
</style>
