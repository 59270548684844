<template>
    <div class="overflow-x-auto">
        <DataTable :value="internalDangers" v-model:editingRows="editingRows" editMode="row" dataKey="ordinal"
                   @row-edit-save="onRowEditSave" size="small" :rowClass="() => 'rowWidth'"
                   class="navigate-table" @click="arrowsNavigateTable">
            <template #header>
                <AddDanger v-model="internalDangers" style="margin-left: 4.1666%;" :error="showError()" />
            </template>
            <Column header="Lp." field="ordinal" style="width: 4.1666%;" />
            <Column header="Zagrożenia" field="type" class="w-3">
                <template #body="slotProps">
                    {{ slotProps.data.type?.label }}
                </template>
                <template #editor="{data,field}">
                    <CustomSelectOne name="type" v-model="data[field]" :items="dicts.danger" />
                </template>
            </Column>
            <Column header="Uwagi" field="notes" class="w-3">
                <template #editor="{data,field}">
                    <CustomInputText name="notes" v-model="data[field]" />
                </template>
            </Column>
            <Column class="w-4"/>
            <Column v-if="editing" :rowEditor="true" style="text-align: center;" class="w-1"/>
            <Column v-if="editing" style="width: 4.1666%; text-align: center;">
                <template #body="slotProps">
                    <CustomButton icon="pi pi-trash" borderColor="transparent"
                                  @click="deleteDanger(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import AddDanger from "./AddDanger.vue";
    import TableArrowsNavigation from "@/utils/TableArrowsNavigation";
    import CustomButton from "@/components/form/CustomButton.vue";
    import TableUtils from "@/utils/TableUtils";

    export default {
        name: "FormDangers",

        components: {
            DataTable,
            Column,
            CustomSelectOne,
            CustomInputText,
            AddDanger,
            CustomButton,
        },

        props: {
            modelValue: {
                type: Array,
                default: () => [],
            },
        },

        inject: ["editing", "dicts", "showErrorMessages"],

        data() {
            return {
                editingRows: ref([]),
            };
        },

        methods: {
            arrowsNavigateTable(ctx) {
                TableArrowsNavigation.arrowsNavigateTable(ctx);
            },
            onRowEditSave(event) {
                const {newData, index} = event;
                this.internalDangers[index] = newData;
            },
            deleteDanger(item) {
                const index = this.internalDangers.indexOf(item);
                this.internalDangers.splice(index, 1);
            },
            showError() {
                return this.showErrorMessages && ((this.internalDangers == null
                    || this.internalDangers.length === 0));
            },
        },

        computed: {
            internalDangers: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            internalDangers: [{
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            }, {
                handler(value) { TableUtils.addOrdinal(value); },
                immediate: true,
            }],
        },
    };
</script>

<style lang="scss">
</style>
