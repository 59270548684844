<template>
    <div class="flex flex-1 flex-wrap add-in-table-header flex-column gap-3" v-focustrap>
        <div class="flex flex-1 gap-2 flex-wrap align-items-center">
            <div class="w-3">
                <CustomSelectOne name="type" label="Zagrożenie" v-model="newItem.type"
                                 :items="dicts.danger" :disabled="!editing"
                                 required :showErrors="showErrors" />
            </div>
            <div class="w-3">
                <CustomInputText name="notes" label="Uwagi" v-model="newItem.notes"
                                 :disabled="!editing" />
            </div>
            <CustomButton v-if="editing" icon="pi pi-plus" label="Dodaj" @click="add" :error="false"
                          style="position: absolute; right: 14px; margin-bottom: 1rem" v-on:keydown.tab="add" />
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import FocusTrap from "primevue/focustrap";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "AddDanger",

        components: {
            CustomSelectOne,
            CustomInputText,
            CustomButton,
        },

        directives: {
            focustrap: FocusTrap,
        },

        props: {
            modelValue: {
                type: Array,
                default: () => [],
            },
            error: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["editing", "dicts"],

        data() {
            return {
                newItem: _.cloneDeep(EmptyStructures.getEmptyDanger()),
                showErrors: false,
            };
        },

        methods: {
            add() {
                if (!ValidateUtils.validateNotEmpty(this.newItem, ["type"])) {
                    this.showErrors = true;
                } else {
                    this.dangers.push({
                        ...this.newItem,
                        ordinal: this.dangers.length + 1,
                    });
                    this.newItem = _.cloneDeep(EmptyStructures.getEmptyDanger());
                    this.showErrors = false;
                }
            },
        },

        computed: {
            dangers: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            dangers: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
.add-in-table-header {
    margin-bottom: -1rem,
}
</style>
