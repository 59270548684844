<template>
    <div class="">
        <div class="register-panel-outer p-4 sm:py-4 md:px-8">
            <div class="register-panel-header text-center">
                <img src="@/assets/logo.png" alt="logo" class="max-w-full">
            </div>
            <div class="register-panel-content">
                <h1 class="py-3 text-center">{{ $t('message.register.title') }}</h1>
                <VeeForm @submit="register" v-slot="{ errors }">
                    <CustomInputText name="firstName" v-model="registerForm.firstName"
                                     :label="$t('message.register.firstName')" rules="required"/>
                    <CustomInputText name="lastName" v-model="registerForm.lastName"
                                     :label="$t('message.register.lastName')" rules="required"/>
                    <CustomInputText name="email" v-model="registerForm.email"
                                     :label="$t('message.register.email')" rules="required|email"/>
                    <CustomPassword name="newPassword" v-model="registerForm.password"
                                    :label="$t('message.register.password')"
                                    rules="required|validPassword"/>
                    <CustomPassword name="confirmPassword" v-model="registerForm.confirmPassword"
                                    :label="$t('message.register.confirmPassword')"
                                    rules="required|confirmed:@newPassword,Hasła nie są takie same"/>
                    <Button :label="$t('message.register.action.register')" class="mt-3" type="submit"
                            :disabled="isDisabled(errors)"/>
                </VeeForm>
                <router-link :to="{name: 'login'}" class="text-center block my-3">
                    {{ $t('message.register.action.backToLogin') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import {Form as VeeForm} from "vee-validate";
    // Po wygenerowaniu metody registerUsingPOST odkomentować
    // import {registerUsingPOST as registerRequest} from "@/swagger/vue-api-client";
    import CustomPassword from "../components/form/CustomPassword";
    import CustomInputText from "../components/form/CustomInputText";
    // Po wygenerowaniu metody registerUsingPOST odkomentować
    // import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "RegisterView",
        components: {
            CustomInputText, CustomPassword, Button, VeeForm,
        },

        data() {
            return {
                registerForm: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    confirmPassword: "",
                },
            };
        },

        methods: {
            isDisabled(errors) {
                return !(errors && Object.keys(errors).length === 0
                    && Object.getPrototypeOf(errors) === Object.prototype);
            },
            register() {
                // Po wygenerowaniu metody registerUsingPOST odkomentować

                // registerRequest({registrationRequest: this.registerForm})
                //     .then(() => {
                //         ToastUtils.addToast(this, {
                //             severity: "success",
                //             summary: "Sukces",
                //             detail: "Dziękujemy za rejestrację.",
                //         });
                //         this.$router.push({name: "login"});
                //     })
                //     .catch((error) => {
                //         if (error.response && error.response.status === 409) {
                //             ToastUtils.addToast(this, {
                //                 severity: "error",
                //                 summary: "Błąd",
                //                 detail: "Użytkownik o podanym adresie e-mail już istnieje",
                //             });
                //         } else {
                //             ToastUtils.addToast(this, {
                //                 severity: "error",
                //                 summary: "Błąd",
                //                 detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                //             });
                //         }
                //     });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .register-panel-outer {
        background-color: var(--surface-a);
    }
</style>
