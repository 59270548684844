<template>
    <div class="border-1 surface-border mt-3 p-3">
        <DataTable :value="emailSendingRules">
            <Column header="Reguła e-mail">
                <template #body="slotProps">
                    {{ computedText(slotProps.data) }}
                </template>
            </Column>
            <Column header="Typ e-maila" field="mailType">
                <template #body="slotProps">
                    {{ computedEmailType(slotProps.data.emailType) }}
                </template>
            </Column>
            <Column style="width: 4.1666%; text-align: center;">
                <template #body="slotProps">
                    <CustomButton icon="pi pi-trash" borderColor="transparent"
                                  @click="deleteRule(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "ProgramEmailSendingTable",

        components: {
            DataTable,
            Column,
            CustomButton,
        },

        props: {
            modelValue: {
                type: Object,
            },
            emailTypes: {
                type: Array,
                default: () => [],
            },
        },

        emits: ["deleteRule"],

        methods: {
            deleteRule(item) {
                const index = this.emailSendingRules.indexOf(item);
                this.$emit("deleteRule", index);
            },
            computedText(item) {
                if (item.type === "DAYS_AFTER") return `co ${item.daysAfter} dni po`;
                if (item.type === "DAYS_BEFORE") return `${item.daysBefore} dni przed`;
                if (item.type === "AT_DEADLINE") return "w dniu";
                return "";
            },
            computedEmailType(type) {
                let label = "";
                this.emailTypes.forEach((et) => {
                    if (et.key === type) label = et.label;
                });
                return label;
            },
        },

        computed: {
            emailSendingRules: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },
    };
</script>

<style lang="scss">
</style>
