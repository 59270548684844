<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="position" :geobjects="positions"
                              v-model:additionalInformation="position" v-model:dangers="internalForm.dangers">
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition v-model="position" :positions="positions" :field="field" v-model:step="internalStep"/>
        </template>
        <template v-slot:controlData>
            <ControlTypeAndDates v-model="position" />
            <ControlWeather v-model="position" hideAudibility />
            <div class="flex column-gap-2 pr-4 flex-direction-column">
                <CustomSelectOne label="Temperatura" v-model="position.temperature" name="temperature" :disabled="!editing"
                                 :items="dicts.temperature" class="w-3 max-width" required :showErrors="showErrorMessages"/>
                <CustomSelectOne label="Metoda obserwacji" name="controlMethod" v-model="position.controlMethod"
                                 class="w-3 max-width" :items="dicts.controlMethod" required :disabled="!editing"
                                 :showErrors="showErrorMessages" />
            </div>
        </template>
        <template v-slot:birdsData>
            <FormBirds v-model:birds="position.birds" v-model:noBirds="position.noBirds"
                       :minWidth="530" />
        </template>
        <template v-slot:controlHabitatData>
            <HabitatDataMNZ :habitatData="position.habitatData" v-model:habitatDataToChange="position.habitatDataToChange"/>
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import ControlWeather from "@/components/formSharedComponents/ControlWeather";
    import ControlTypeAndDates from "@/components/formSharedComponents/ControlTypeAndDates";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import FormBirds from "@/components/formBirds/FormBirds.vue";
    import HabitatDataMNZ from "./HabitatDataMNZ.vue";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";

    export default {
        name: "FormMNZObservations",

        components: {
            FormObservationsTemplate,
            ObservationsSelectPosition,
            ControlWeather,
            ControlTypeAndDates,
            CustomSelectOne,
            FormBirds,
            HabitatDataMNZ,
        },

        props: {
            allPositions: {
                type: Array,
                default: () => [],
            },
            field: {
                type: Array,
                default: () => [],
            },
            step: {
                type: Number,
                required: true,
            },
            form: {
                type: Object,
                required: true,
            },
        },

        emits: ["update:step", "update:form"],

        inject: ["showErrorMessages", "editing", "dicts"],

        data() {
            return {
                positions: [],
                position: _.cloneDeep(EmptyStructures.getEmptyMNZPosition()),
            };
        },

        beforeMount() {
            this.positions = this.allPositions.filter((p) => (p.notAudited === null || !p.notAudited));
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../../assets/theme/mytheme/variables";

    @media screen and (max-width: 850px) {
        .flex-direction-column {
            flex-direction: column;
            padding-right: 0 !important;
            .max-width {
                width: 100% !important;
            }
        }
    }
</style>
