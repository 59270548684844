<template>
    <div class="p-fluid custom-input field custom-calendar relative">
        <span class="p-float-label">
            <Calendar :class="computedClasses" :id="name" v-model="internalValue" :showTime="showTime"
                      :timeOnly="timeOnly" :disabled="disabled"
                      :showIcon="!inline && showIcon"
                      @date-select="onChange" :dateFormat="dateFormat"
                      :manualInput="!inline" :showOnFocus="true" :selectionMode="selectionMode"
                      :min-date="min" :max-date="max" :inline="inline"
                      :panelClass="inline ? 'min-w-min w-20rem' : ''" :view="view"
                      :placeholder="placeholder" @input="inputYear" @focus="onFocus" />
            <CustomLabel :name="name" :label="label" :required="required" :disabled="disabled" />
        </span>
        <CustomValidationMessage :errorMessage="errorMessage" />
        <CustomValidationMessage :errorMessage="customErrorMessage" />
        <CustomValidationMessage v-if="showRequiredError" :errorMessage="$t('message.validations.required')" />
    </div>
</template>

<script>
    import Calendar from "primevue/calendar";
    import {useField} from "vee-validate";
    import CustomValidationMessage from "./inner/CustomValidationMessage";
    import CustomLabel from "./inner/CustomLabel";

    export default {
        name: "CustomCalendar",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            showTime: {
                type: Boolean,
                default: false,
            },
            timeOnly: {
                type: Boolean,
                default: false,
            },
            modelValue: null,
            rules: undefined,
            showIcon: {
                type: Boolean,
                default: true,
            },
            icon: {
                type: String,
            },
            customErrorMessage: {
                type: String,
                default: "",
            },
            customErrorWithoutMessage: {
                type: Boolean,
                default: false,
            },
            showErrors: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            selectionMode: {
                type: String,
                default: undefined,
            },
            min: {
                type: Date,
                default: undefined,
            },
            max: {
                type: Date,
                default: undefined,
            },
            inline: {
                type: Boolean,
                default: false,
            },
            view: {
                type: String,
                default: "date",
            },
            dateFormat: {
                type: String,
                default: "dd.mm.yy",
            },
            placeholder: {
                type: String,
                default: "DD.MM.YYYY",
            },
        },
        emits: ["update:modelValue"],

        computed: {
            computedClasses() {
                if (this.customErrorMessage !== "" || this.customErrorWithoutMessage || this.showRequiredError) {
                    return "p-invalid";
                }
                return "";
            },
            showRequiredError() {
                return this.showErrors && (this.internalValue == null || this.internalValue === "");
            },
            internalValue: {
                get() {
                    return this.convertDateToDateFormat(this.modelValue);
                },
                set(value) {
                    this.handleChange(value);
                    this.$emit("update:modelValue", value);
                },
            },
        },

        methods: {
            onChange(event) {
                this.handleChange(event);
            },
            convertDateToDateFormat(date) {
                if (date && this.selectionMode === "range") {
                    if (date[0]) {
                        date[0] = new Date(date[0]);
                    }
                    if (date[1]) {
                        date[1] = new Date(date[1]);
                    }
                    return date;
                }
                if (date) {
                    return new Date(date);
                }
                return this.modelValue;
            },
            inputYear(e) {
                if (this.dateFormat === "yy") {
                    const year = e.target.value;
                    if (year.length > 3) {
                        this.internalValue = new Date(year);
                    }
                } else {
                    const numChars = e.target.value.length;
                    if ((numChars === 2 || numChars === 5) && e.inputType === "insertText") {
                        e.target.value += ".";
                    }
                }
            },
            onFocus() {
                const regLet = /[a-zA-Z]/;
                const regNum = /[0-9]/;
                document.querySelector(".p-calendar .p-inputtext").addEventListener("keypress", (e) => {
                    if (regLet.test(e.key)) {
                        e.preventDefault();
                    }
                    if (this.dateFormat === "yy" && regNum.test(e.key) && e.target.value.length > 3) {
                        e.target.value = "";
                    } else if (e.target.value.length > 9) {
                        e.target.value = "";
                    }
                });
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {Calendar, CustomValidationMessage, CustomLabel},
    };
</script>

<style lang="scss">
    @import "../../assets/theme/mytheme/variables";

    .p-datepicker table td > span.p-highlight {
        color: var(--text-color) !important;
    }
</style>
