<template>
    <FormObservationsTemplate ref="observationsTemplate" :controls="controls"
                              v-model:dangers="internalForm.dangers">
        <template v-slot:controlMainData="slotProps">
            <ControlTypeAndDates v-model="slotProps.control" :index="slotProps.index" controlTypeDates />
            <ControlWeather v-model="slotProps.control" hideAudibility />
            <CustomInputNumber label="Liczba śpiewających samców" name="singingMales" :disabled="!editing"
                               v-model="slotProps.control.singingMales" required :showErrors="showErrorMessages"/>
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import ControlTypeAndDates from "@/components/formSharedComponents/ControlTypeAndDates.vue";
    import ControlWeather from "@/components/formSharedComponents/ControlWeather.vue";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";

    export default {
        name: "FormMWOPObservations",

        components: {
            FormObservationsTemplate,
            ControlWeather,
            ControlTypeAndDates,
            CustomInputNumber,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
        },

        inject: ["editing", "showErrorMessages"],

        emits: ["update:form"],

        data() {
            return {
                controls: [],
            };
        },

        beforeMount() {
            this.controls[0] = this.form.control1;
            this.controls[1] = this.form.control2;
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) {
                    this.$emit("update:form", value);
                    this.controls[0] = value.control1;
                    this.controls[1] = value.control2;
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
@import "../../../assets/theme/mytheme/variables";
</style>
