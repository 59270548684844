<template>
    <div class="px-3">
        <RejectFormDialog v-model="showRejectDialog" @reject="exit" />
        <SaveDespiteErrorsDialog v-model="showSaveDespiteErrorsDialog" @save="saveAndExitDespiteErrors" />
        <ApproveFormDespiteErrorsDialog v-model="showApproveInfo" :programEditionId="programEditionId"
                                        @approve="() => approveThisForm(false)" />
        <SemiRawAndAggregatesDialog v-model="showSemiRawAndAggregatesDialog" :aggregates="aggregates"
                                    :semiRawData="semiRawData" :programEditionId="programEditionId"/>
        <div class="flex justify-content-end flex-wrap gap-3">
            <CustomButton class="form-button" label="Wyjdź" bgColor="rgba(193, 197, 204, 0.4)" @click="exit" />
            <CustomButton v-if="canGoUp" class="form-button" label="W górę" @click="goUp"
                          bgColor="rgba(193, 197, 204, 0.4)"/>
            <CustomButton v-if="canGoBack" class="form-button" label="Poprzedni krok"
                          bgColor="rgba(193, 197, 204, 0.4)" @click="goToStep(1)" />
            <CustomButton v-if="canApprove" class="form-button" label="Zatwierdź"
                          bgColor="var(--secondary-color)" color="var(--surface-a)"
                          @click="callForValidate('approve')" />
            <CustomButton v-if="showSemiRawAndAggregatesButton" class="form-button"
                          label="Pokaż dane skumulowane i zagregowane"
                          bgColor="var(--secondary-color)" color="var(--surface-a)"
                          @click="showSemiRawAndAggregatesDialog = true"
                          style="width: fit-content"/>
            <CustomButton v-if="canReject" class="form-button reject-button" label="Odrzuć"
                          bgColor="rgba(193, 197, 204, 0.4)" @click="showRejectDialog = true" color="#EA3030" />
            <CustomButton v-if="canSendToVerification" class="form-button w-min"
                          label="Wyślij do weryfikacji" bgColor="rgba(193, 197, 204, 0.4)"
                          @click="callForValidate('sendToVerification')"/>
            <CustomButton v-if="canGoNext" class="form-button" label="Następny krok"
                          bgColor="rgba(193, 197, 204, 0.4)" @click="goToStep(2)" />
            <CustomButton v-if="canSave" class="form-button" label="Zapisz"
                          @click="callForValidate('save')" bgColor="rgba(193, 197, 204, 0.4)" />
            <CustomButton v-if="canSave" class="form-button" label="Zapisz i wyjdź"
                          @click="callForValidate('saveAndExit')" bgColor="rgba(193, 197, 204, 0.4)" />
        </div>
    </div>
</template>

<script>
    import CustomButton from "@/components/form/CustomButton.vue";
    import {
        approveForm,
        sendFormToVerification,
        updateForm,
    } from "@/swagger/vue-api-client";
    import RejectFormDialog from "@/components/formSharedComponents/RejectFormDialog.vue";
    import SaveDespiteErrorsDialog from "@/components/formSharedComponents/SaveDespiteErrorsDialog.vue";
    import ApproveFormDespiteErrorsDialog from "@/components/formSharedComponents/ApproveFormDespiteErrorsDialog.vue";
    import SemiRawAndAggregatesDialog from "@/components/formSharedComponents/SemiRawAndAggregatesDialog.vue";
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "SaveForm",

        components: {
            SemiRawAndAggregatesDialog,
            ApproveFormDespiteErrorsDialog,
            SaveDespiteErrorsDialog,
            RejectFormDialog,
            CustomButton,
        },

        props: {
            validationCorrect: {
                type: Boolean,
                default: false,
            },
            request: {
                type: Object,
            },
            programEditionId: {
                type: Number,
            },
            state: {
                type: String,
            },
            someGeobjectWasAudited: {
                type: Boolean,
                default: true,
            },
            onlyStepOne: {
                type: Boolean,
                default: false,
            },
            onlyStepTwo: {
                type: Boolean,
                default: false,
            },
            showErrorMessages: {
                type: Boolean,
            },
            step: {
                type: Number,
                default: 1,
            },
            noFormControl: {
                type: Boolean,
                default: false,
            },
            isDynamicPosition: {
                type: Boolean,
                default: false,
            },
            someGeobjectLength: {
                type: Number,
                default: 0,
            },
        },

        inject: ["editing"],

        data() {
            return {
                showRejectDialog: false,
                showApproveInfo: false,
                showSaveDespiteErrorsDialog: false,
                showSemiRawAndAggregatesDialog: false,
                showSemiRawAndAggregatesButton: false,
                semiRawData: [],
                aggregates: [],
                filesSaved: false,
                action: null,
            };
        },

        emits: ["validate", "goTo2Step", "update:showErrorMessages", "update:step"],

        methods: {
            callForValidate(action = null) {
                this.action = action;
                this.$emit("validate");
            },
            goToStep(number) {
                this.$emit("update:step", number);
                let query = `?step=${number}`;
                if (this.$route.query.controlCode != null) query += `&controlCode=${this.$route.query.controlCode}`;
                if (this.$route.query.birdCode != null) query += `&birdCode=${this.$route.query.birdCode}`; // dodane na potrzeby makiet do MFGP, być może potem będzie można usunąć
                window.scrollTo({ top: 0, behavior: "smooth" });
                window.history.pushState(null, "", this.$route.path + query);
            },
            goUp() {
                window.scrollTo({ top: 0, behavior: "smooth" });
            },
            addErrorToast(message) {
                this.$toast.add({
                    severity: "error",
                    summary: "Błąd",
                    detail: message,
                    life: 30000,
                });
            },
            async saveFormAPI(request, validate, exit) {
                await updateForm({
                    formId: this.$route.params.id,
                    body: request,
                    validate,
                })
                    .then((response) => {
                        if (response.status === 200) {
                            this.$toast.add({
                                severity: "success",
                                summary: "Zapisano",
                                detail: "Operacja wykonana pomyślnie",
                                life: 3000,
                            });
                        }
                        if (exit) this.exit();
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        if (error.response != null && Array.isArray(error.response.data)) {
                            error.response.data.forEach((e) => { this.addErrorToast(e); });
                        } else {
                            this.addErrorToast(error.response.data);
                        }
                        throw error;
                    });
            },
            async uploadFilesInChild() {
                if (this.$parent.$parent.$refs.observationsView
                    && this.$parent.$parent.$refs.observationsView.$refs.observationsTemplate && !this.filesSaved) {
                    this.filesSaved = true;
                    await this.$parent.$parent.$refs.observationsView.$refs.observationsTemplate.uploadFilesInChild();
                }
            },
            async save(exit = false) {
                await this.uploadFilesInChild();
                if (this.validationCorrect || !exit) {
                    await this.saveFormAPI(this.request, true, exit)
                        .catch(() => {
                            this.saveFormAPI(this.request, false, exit);
                        });
                }
                if (!this.validationCorrect) {
                    this.$emit("update:showErrorMessages", true);
                    if (exit) this.showSaveDespiteErrorsDialog = true;
                }
            },
            async saveAndExitDespiteErrors() {
                await this.saveFormAPI(this.request, false, true);
            },
            async approveThisForm(validate = true) {
                await this.uploadFilesInChild();
                if (!validate || this.validationCorrect) {
                    this.saveFormAPI(this.request, validate, false)
                        .then(() => approveForm({formId: this.$route.params.id, validate}))
                        .then((response) => {
                            if (this.state !== "APPROVED") {
                                this.semiRawData = response.data.semiRawDataList;
                                this.aggregates = response.data.aggregatesList;
                                this.showSemiRawAndAggregatesDialog = true;
                            }
                            this.editing = false;
                            this.showSemiRawAndAggregatesButton = true;
                        })
                        .catch((error) => {
                            if (validate && error.response.status === 422) {
                                // if frontend validation does not catch something and backend validation does and there is an error, we still want to be able to validate with errors
                                this.$emit("update:showErrorMessages", true);
                                this.showApproveInfo = true;
                                this.addErrorToast(error.message);
                            }
                        });
                } else {
                    this.$emit("update:showErrorMessages", true);
                    this.showApproveInfo = true;
                }
            },
            async sendToVerification() {
                await this.uploadFilesInChild();
                this.saveFormAPI(this.request, true, true)
                    .then(() => {
                        sendFormToVerification({formId: this.$route.params.id})
                            .then(() => { this.exit(); })
                            .catch((error) => {
                                console.log(error.response.data);
                            });
                    })
                    .catch(() => {});
            },
            exit() {
                console.log("exit");
                this.$router.push(`/monitoring-program/${this.programEditionId}`);
            },
        },

        computed: {
            isStep1() {
                return this.step === 1;
            },
            isStep2() {
                return this.step === 2;
            },
            isCoordinator() {
                return SystemRole.isMainCoordinator() || SystemRole.isRegionalCoordinator()
                    || SystemRole.isNationalCoordinator();
            },
            isAdmin() {
                return SystemRole.isAdmin();
            },
            canApprove() {
                return (this.isCoordinator || this.isAdmin) && (this.isStep2 || !this.someGeobjectWasAudited || this.onlyStepOne || this.noFormControl)
                    && (((this.isDynamicPosition && this.someGeobjectLength > 0) || this.noFormControl) || !this.isDynamicPosition)
                    && (this.editing || this.state === "SENT_TO_VERIFICATION");
            },
            canReject() {
                return (this.isCoordinator || this.isAdmin) && this.state === "SENT_TO_VERIFICATION";
            },
            canSendToVerification() {
                return SystemRole.isObserver() && (this.isStep2 || !this.someGeobjectWasAudited || this.onlyStepOne || this.noFormControl)
                    && this.state === "DRAFT";
            },
            canSave() {
                return (this.isStep2 || !this.someGeobjectWasAudited || this.onlyStepOne || this.noFormControl)
                    && this.editing && this.state !== "APPROVED";
            },
            canGoUp() {
                return this.isStep2;
            },
            canGoNext() {
                return this.someGeobjectWasAudited && this.isStep1 && !this.onlyStepOne && !this.noFormControl;
            },
            canGoBack() {
                return this.isStep2 && !this.onlyStepTwo;
            },
        },

        watch: {
            request() {
                // event "validate" had been emitted, then validation has completed and request recreated and reassigned
                // so after every emit "validation" this code runs
                if (this.action === "save") {
                    this.save(false);
                } else if (this.action === "saveAndExit") {
                    this.save(true);
                } else if (this.action === "approve") {
                    this.approveThisForm(true);
                } else if (this.action === "sendToVerification") {
                    this.sendToVerification();
                }
            },
        },
    };
</script>

<style lang="scss">
@import "../../assets/theme/mytheme/variables";
.form-button {
    width: 130px;
    height: 59px !important;
    border-radius: 10px !important;
    border: none !important;
    &.p-button {
        label {
            color: var(--text-color-secondary);
        }
    }
    label {
        width: max-content;
    }
    justify-content: center;
    align-items: center;
}
</style>
