<template>
    <div class="p-3">
        <h2>Zmiana hasła</h2>
        <Form @submit="changePassword">
            <CustomPassword name="newPassword" v-model="newPassword" label="Nowe hasło"
                            class="max-width" rules="required|validPassword"
                            :disabled="isGios" />
            <CustomPassword name="repeatPassword" v-model="repeatPassword" label="Powtórz hasło"
                            class="max-width" rules="required|confirmed:@newPassword,Hasła"
                            :disabled="isGios" />
            <div class="flex justify-content-end max-width">
                <CustomButton label="Zmień hasło" type="submit" :disabled="isGios" />
            </div>
        </Form>
    </div>
</template>

<script>
    import {Form} from "vee-validate";
    import {changeMyPassword} from "@/swagger/vue-api-client";
    import CustomPassword from "@/components/form/CustomPassword";
    import CustomButton from "@/components/form/CustomButton";

    export default {
        name: "ChangeMyPasswordView",

        components: {
            Form,
            CustomPassword,
            CustomButton,
        },

        data() {
            return {
                newPassword: "",
                repeatPassword: "",
                isGios: false,
            };
        },

        beforeMount() {
            this.isGios = localStorage.getItem("email").includes("@gios.gov.pl");
        },

        methods: {
            changePassword() {
                changeMyPassword({body: this.newPassword})
                    .then(() => {
                        this.$toast.add({
                            severity: "success",
                            summary: "Hasło zostało zmieninone",
                            life: 3000,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd, spróbuj ponownie",
                            life: 3000,
                        });
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .max-width {
        max-width: 600px;
    }
</style>
