<template>
    <div class="whats-new-view p-3">
        <h2>W przygotowaniu</h2>
        <ul>
            <li>Zagrożenia  - Dodanie zagrożeń w zarządzaniu danymi (170)</li>
        </ul>
        <h2>Wersja 1.1.4 - 1.04.2025</h2>
        <ul>
            <li>Importy MWOP - poprawki importu danych surowych i obsady (240)</li>
            <li>Dane skumulowane/Agregaty MWOP - Nie działa generowanie danych skumulowanych i zagregowanych (234)</li>
            <li>Dane skumulowane/Agregaty MMC - W danych skumulowanych nie pokazuje się w której kontroli została wykonana obserwacja, co powinno działać dla pola Inne gatunki (151)</li>
            <li>Formularze MMC - błędna walidacja formularza (233)</li>
        </ul>
        <h2>Wersja 1.1.3 - 26.03.2025</h2>
        <ul>
            <li>Zagrożenia  - Dodanie zagrożeń do formularzy (168)</li>
            <li>Ogólne  - Dodanie zakładki dla ogólnego importu obsady (247)</li>
            <li>Importy  - Dane surowe MMC - zgłoszony błąd (122)</li>
            <li>Importy MZO - uwzględnienie danych siedliskowych w imporcie (248)</li>
            <li>Importy MZO - poprawki importu danych surowych i obsady (239)</li>
            <li>Importy MMC - poprawki import danych surowych i obsady (153)</li>
            <li>Importy  - kody w listach wyboru - usuwać przed porównaniem polskie litery (214)</li>
            <li>Słownik stanowisk/transektów/punktów  - Nie da się usunąć stanowiska (245)</li>
            <li>Słownik gatunków  - Zablokowanie czeczotkę brozową jako gatunek możliwy do wpsiania (123)</li>
            <li>Słownik gatunków  - użytkownicy mogą zmieniać wyświetlany kod i język gatunku (128)</li>
            <li>Dane skumulowane/Agregaty MZO - źle pokazują się liczebności gatunków - w danych skumulowanych liczebności żołny są opisane nazwą gatunku "brzegówka", zaś liczebności brzegówki mają pole na gatunek puste (125)</li>
            <li>Formularze MZO - błędna walidacja formularza (230)</li>
            <li>Formularze MZO - Po wprowadzeniu danych (bez kombinowania) nie da się zatwierdzić formularza JEŚLI zaznaczy się którykolwiek check-box w żołnie czy brzegówce (kiedy wpisana jest liczebność formularz da się zatwierdzić) (154)</li>
            <li>Formularze MZO - Nie udało się dodać nowego stanowiska (229)</li>
        </ul>
        <h2>Wersja 1.1.2 – 13.03.2025</h2>
        <ul>
            <li>Importy  - nie działa import koordynatorów regionalnych (191)</li>
            <li>Słownik stanowisk/transektów/punktów  - nie działa import stanowisk (195)</li>
            <li>Dane surowe  - poprawka wyświetlania danych surowych i siedliskowych koordynatorom krajowym i regionalnym (ograniczenie widzianych danych tylko do rekordów, w których użytkownik pełnił daną rolę) (177)</li>
        </ul>
        <h2>Wersja 1.1.1 – 11.03.2025 r</h2>
        <ul>
            <li>Ogólne  - Koordynator krajowy powinien mieć zakładkę Zarządzania danymi, ale w ograniczonym stopniu (84)</li>
            <li>Ogólne  - dodanie znacznika wersji i zakładki co nowego (112)</li>
            <li>Ogólne  - Wyświetlanie nazw punktów na mapie (51)</li>
            <li>Ogólne  - poprawienie formatu daty (175)</li>
            <li>Ogólne  - Lista w Filtrze powinna zamykać się od razu po kliknięciu Enter - w niektórych filtrach (93)</li>
            <li>Strona główna i lista programów  - Tabela na stronie głównej powinna mieć nagłówek (76)</li>
            <li>Użytkownicy  - Koordynator główny powinien mieć możliwość edycji użytkowników – ale tylko ROLI (99)</li>
            <li>Słownik powierzchni  - Przy dodawaniu kolejnych punktów w Edycji powierzchni wyszarzone pozostaje pkt  5, a powinien być możliwy do edycji (zablokowany do edycji powinien być tylko ostatni punkt) (116)</li>
            <li>Słownik powierzchni  - sortowanie w słowniku (26)</li>
            <li>Słownik powierzchni  - naprawić komunikat z błędami przy imporcie (27)</li>
            <li>Słownik stanowisk/transektów/punktów  - Punkt „Wyświetlany” jest zaznaczony czerwonym znacznikiem, który chowa się pod szarym jeśli mają bardzo bliską lokalizację (118)</li>
            <li>Słownik stanowisk/transektów/punktów  - Przy tworzeniu stanowiska w Słowniku można wybrać wszystkie programy - powinno wybierać się z listy programów które są stanowiskowe (114)</li>
            <li>Mailing  - zakładka treści mailingu automatycznego (119)</li>
            <li>Słownik list wyboru  - Sortowanie (44)</li>
            <li>Dane surowe MZPM - Nie działają dane surowe MZPM (165)</li>
            <li>Dane surowe  - przywrócenie w danych surowych kolumny "formularz" (171)</li>
            <li>Archiwum plików  - filtrowanie po typie pliku (rozszerzeniu) (96)</li>
            <li>Dane siedliskowe  - Tak/nie nie pojawiają się w wybranych filtrach (TagContainer) (172)</li>
            <li>Dane siedliskowe  - klikalny rok (174)</li>
            <li>Dane siedliskowe  - multiplikowanie wierszy na każdy rok (105)</li>
            <li>Formularze  - widok programu dla obserwatora nie działa (163)</li>
            <li>Formularze MZPM - MZPM w kontroli obserwatora (tzn. nie kapitana) nie działa tabela ptaków (164)</li>
            <li>Formularze  - MZPM - nie da się dodać obsady (157)</li>
            <li>Formularze MPG - Po edycji lokalizacji punktu, nie ma możliwości zatwierdzenia i pojawia się błąd (Wcześniej udało się zatwierdzić formularz pomyślnie) (144)</li>
            <li>Formularze MPWR - Przy zaznaczeniu w kontroli “Brak ptaków’ formularz wciąż wymaga uzupełnienia pól, które znikły po zaznaczeniu checkboxa (141)</li>
            <li>Formularze MPWR - Nie da się zatwierdzić uzupełnionego formularza - po kliknięciu zatwierdź formularz się nie zamyka, nie pojawia się żaden błąd wszystko jest uzupełnione (jak się zaznaczy Kontrola nie została wykonana formularz się zatwierdza) (140)</li>
            <li>Formularze MLS - Nie da się zatwierdzić formularza - wydaje mi się że przy zaznaczeniu check-boxa brak ssaków formularz domaga się żeby uzupełnić i tak pola (160)</li>
            <li>Formularze MNG - Nie da się dodać obsady (189)</li>
            <li>Formularze MZPM - wyłączyć możliwość edycji lokalizacji transektu (176)</li>
            <li>Formularze  - pasek ze stanowiskami/transektami/punktami pozwala na wejście do nie kontrolowanybh obiektów (162)</li>
            <li>Formularze MNZ - Przy ręcznym wpisywaniu godziny pojawia się NaN (150)</li>
            <li>Formularze MNZ - W trybie bez edycji można zmieniać wszystkie pola formularza poza tabelą ptaków (67)</li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "WhatsNew",
    };
</script>

<style lang="scss">
    .whats-new-view {
        h2 {
            margin-bottom: 0;
        }
        ul {
            margin-top: 0;
        }
    }
</style>
