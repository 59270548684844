<template>
    <div v-if="!loading" class="program-email-sending">
        <h3 class="mt-6">Reguły wysyłania e-maili</h3>
        <div class="flex column-gap-7 row-gap-3 flex-wrap">
            <div class="flex gap-2 align-items-center flex-wrap">
                <CustomInputNumber name="daysBeforeQuantity" class="mb-0" style="width: 64px"
                                   v-model="newEmailRule.DAYS_BEFORE.daysBefore" />
                <span class="text-style">dni przed: </span>
                <CustomSelectOne name="daysBefore" :items="emailTypes" class="mb-0" :emptyValue="false"
                                 label="Typ e-maila" style="min-width: 256px" itemValue="key"
                                 v-model="newEmailRule.DAYS_BEFORE.emailType" />
                <CustomButton icon="pi pi-plus" @click="addEmailRule('DAYS_BEFORE')" />
            </div>
            <div class="flex gap-2 align-items-center flex-wrap">
                <span class="text-style">w dniu:</span>
                <CustomSelectOne name="onDay" :items="emailTypes" class="mb-0" :emptyValue="false"
                                 label="Typ e-maila" style="min-width: 256px" itemValue="key"
                                 :disabled="noMoreAtDeadlineRule" v-model="newEmailRule.AT_DEADLINE.emailType" />
                <CustomButton v-if="!noMoreAtDeadlineRule" icon="pi pi-plus" @click="addEmailRule('AT_DEADLINE')" />
            </div>
            <div class="flex gap-2 align-items-center flex-wrap">
                <span class="text-style">co</span>
                <CustomInputNumber name="everyXDaysQuantity" class="mb-0" style="width: 64px"
                                   v-model="newEmailRule.DAYS_AFTER.daysAfter" />
                <span class="text-style">dni po:</span>
                <CustomSelectOne name="everyXDays" :items="emailTypes" class="mb-0" :emptyValue="false"
                                 label="Typ e-maila" style="min-width: 256px" itemValue="key"
                                 v-model="newEmailRule.DAYS_AFTER.emailType" />
                <CustomButton icon="pi pi-plus" @click="addEmailRule('DAYS_AFTER')" />
            </div>
        </div>
        <ProgramEmailSendingTable v-model="program.emailSendingRules" :emailTypes="emailTypes" @deleteRule="deleteEmailRule"/>
    </div>
</template>

<script>
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import ProgramEmailSendingTable
        from "@/views/programsTable/components/ProgramEmailSendingTable.vue";
    import {getListOfEmailTypes, updateProgram} from "@/swagger/vue-api-client";

    export default {
        name: "ProgramEmailSending",

        components: {
            ProgramEmailSendingTable,
            CustomInputNumber,
            CustomSelectOne,
            CustomButton,
        },

        props: {
            modelValue: {
                type: Object,
            },
        },

        data() {
            return {
                loading: true,
                emailTypes: [],
                newEmailRule: {
                    DAYS_BEFORE: {
                        daysBefore: null,
                        type: "DAYS_BEFORE",
                        emailType: null,
                    },
                    AT_DEADLINE: {
                        type: "AT_DEADLINE",
                        emailType: null,
                    },
                    DAYS_AFTER: {
                        daysAfter: null,
                        type: "DAYS_AFTER",
                        emailType: null,
                    },
                },
                noMoreAtDeadlineRule: false,
            };
        },

        beforeMount() {
            this.updateNoMoreAtDeadlineRule(this.program);
            getListOfEmailTypes({onlyReminders: true})
                .then((response) => {
                    this.emailTypes = response.data;
                    this.loading = false;
                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        this.$router.push({name: "forbidden"});
                    }
                });
        },

        methods: {
            deleteEmailRule(index) {
                const deletedItem = this.program.emailSendingRules.splice(index, 1)[0];
                updateProgram({id: this.$route.params.programName, body: this.program})
                    .then(() => {
                        this.toastSuccess("Reguła została usunięta.");
                    })
                    .catch((error) => {
                        this.toastError("Nie udało się usunąć reguły: " + error);
                        this.program.emailSendingRules.splice(index, 0, deletedItem);
                    });
            },
            addEmailRule(type) {
                const properties = ["emailType"];
                if (type === "DAYS_BEFORE") properties.push("daysBefore");
                if (type === "DAYS_AFTER") properties.push("daysAfter");

                if (ValidateUtils.validateNotEmpty(this.newEmailRule[type], properties)) {
                    this.program.emailSendingRules.push(this.newEmailRule[type]);
                    updateProgram({id: this.$route.params.programName, body: this.program})
                        .then(() => {
                            this.toastSuccess("Reguła została dodana.");
                            this.clearEmailRuleByType(type);
                        })
                        .catch((error) => {
                            this.toastError("Nie udało się dodać reguły: " + error);
                            const lastIndex = this.program.emailSendingRules.length - 1;
                            this.program.emailSendingRules.splice(lastIndex, 1);
                        });
                } else {
                    this.toastError("Niepoprawne dane reguły.");
                }
            },
            clearEmailRuleByType(type) {
                const newRuleObject = {
                    type: type,
                    emailType: null,
                };
                if (type === "DAYS_BEFORE") {
                    newRuleObject.daysBefore = null;
                } else if (type === "DAYS_AFTER") {
                    newRuleObject.daysAfter = null;
                }
                this.newEmailRule[type] = newRuleObject;
            },
            updateNoMoreAtDeadlineRule(programValue) {
                this.noMoreAtDeadlineRule = false;
                programValue.emailSendingRules.forEach((esr) => {
                    if (esr.type === "AT_DEADLINE") {
                        this.noMoreAtDeadlineRule = true;
                    }
                });
            },
            toastError(message) {
                this.$toast.add({
                    severity: "error",
                    summary: "Błąd",
                    detail: message,
                    life: 3000,
                });
            },
            toastSuccess(message) {
                this.$toast.add({
                    severity: "success",
                    summary: "Sukces",
                    detail: message,
                    life: 3000,
                });
            },
        },

        computed: {
            program: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            program: {
                handler(value) {
                    this.updateNoMoreAtDeadlineRule(value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
@import "../../../assets/theme/mytheme/variables";

.program-email-sending {
    .text-style {
        color: var(--text-color-secondary);
        font-weight: 600;
    }
}
@media screen and (max-width: 600px) {
    .flex-direction-column-600 {
        flex-direction: column;
        padding-right: 0 !important;
        .max-width {
            width: 100% !important;
        }
    }
}
@media screen and (max-width: 900px) {
    .flex-direction-column-900 {
        flex-direction: column;
        padding-right: 0 !important;
        .max-width {
            width: 100% !important;
        }
    }
}
@media screen and (max-width: 1350px) {
    .flex-direction-column-1350 {
        flex-direction: column;
        padding-right: 0 !important;
        .max-width {
            width: 100% !important;
        }
    }
}
</style>
