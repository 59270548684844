<template>
    <div class="p-3">
        <h2>Wyświetlanie gatunków</h2>
        <VeeForm v-if="loaded" @submit="saveSettings">
            <CustomSelectOne v-model="speciesSettings.speciesNameLang" :items="languages" itemValue="code"
                             name="selectSpeciesNameLang" label="Język nazw gatunków" class="w-4 max-width"
                             rules="required"/>
            <CustomSelectOne v-model="speciesSettings.speciesCode" :items="codes" itemValue="code"
                             name="selectSpeciesCode" label="Kod gatunku" class="w-4 max-width"
                             rules="required"/>
            <CustomButton label="Zapisz" type="submit" icon="pi pi-check"
                          color="var(--surface-a)"
                          bgColor="var(--secondary-color)" />
        </VeeForm>
    </div>
</template>

<script>
    import {Form as VeeForm} from "vee-validate";
    import {getSpeciesSettings, saveSpeciesSettings} from "@/swagger/vue-api-client";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "SpeciesSettingsView",

        data() {
            return {
                speciesSettings: {
                    speciesNameLang: null,
                    speciesCode: null,
                },
                languages: [
                    {code: "PL", label: "polski"},
                    {code: "EN", label: "angielski"},
                    {code: "LAT", label: "łaciński"},
                ],
                codes: [
                    {code: "MPPL", label: "MPPL"},
                    {code: "RING", label: "obrączkarski"},
                ],
                loaded: false,
            };
        },

        beforeMount() {
            getSpeciesSettings().then((response) => {
                this.speciesSettings = response.data;
                this.loaded = true;
            });
        },

        methods: {
            saveSettings() {
                saveSpeciesSettings({body: this.speciesSettings})
                    .then(() => {
                        this.$toast.add({
                            severity: "success",
                            summary: "Ustawienia zostały zmienione",
                            life: 3000,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd, spróbuj ponownie",
                            life: 3000,
                        });
                    });
            },
        },

        components: {
            VeeForm,
            CustomButton,
            CustomSelectOne,
        },
    };
</script>

<style lang="scss" scoped>
.max-width {
    max-width: 600px;
}
</style>
