<template>
    <!--    <Toast />-->
    <!--    <Dialog header="Importuj" v-model:visible="importDialogVisible" class="import-cast-dialog" :closable="false" modal-->
    <!--            :draggable="false">-->
    <!--        <template #header>-->
    <!--            <CustomFileUpload @importFile="importFile" :multiple="false" accept=".xlsx, .xls"-->
    <!--                              class="import-cast-file-upload" />-->
    <!--        </template>-->
    <!--        <div class="dialog-file-upload-buttons import-cast-dialog-buttons w-100 h-100">-->
    <!--            <CustomButton label="Anuluj" class="flex justify-content-center red-button"-->
    <!--                          @click="importDialogVisible = false" bgColor="transparent" />-->
    <!--            <CustomButton label="Importuj plik" class="green-button" @click="importData"/>-->
    <!--        </div>-->
    <!--    </Dialog>-->
    <div class="flex justify-content-between">
        <div class="filters flex gap-3 row-gap-0 flex-wrap">
            <div class="flex gap-3 flex-wrap row-gap-0">
                <CustomMultiSelect id="programFilter" label="Program" name="programFilter"
                                   v-model="searchCriteria.programs" :items="programs" class="w-12rem"
                                   itemLabel="" />
                <CustomMultiSelect v-if="geobject !== 'field'" id="fieldFilter" name="fieldFilter" v-model="searchCriteria.fieldNames"
                                   :items="fields" label="Filtruj według nazwy powierzchni"
                                   :disabled="fields.length < 1" item-label="name" class="w-21rem"
                                   virtual-scrolling />
                <CustomMultiSelect v-else id="superFieldFilter" name="superFieldFilter" v-model="searchCriteria.superFieldIds"
                                   :items="fields" label="Filtruj według nazwy nadpowierzchni"
                                   :disabled="fields.length < 1" item-label="name" class="w-22rem"
                                   virtual-scrolling itemValue="id" />
                <CustomInputText id="name" name="name" v-model="searchCriteria.name" placeholder="Szukaj według nazwy"
                                 label="Nazwa" class="w-23rem"/>
                <GeographicalFilters v-model="searchCriteria" ref="geographicalFilters"
                                     v-model:matchMode="searchCriteria.matchMode" :filterBy="matchModeOptions" />
            </div>
            <div class="flex align-self-end justify-content-end field">
                <CustomButton label="Wyczyść" @click="clear"/>
            </div>
        </div>
    </div>
    <div class="flex gap-3 justify-content-end flex-wrap mb-3">
        <CustomButton @click="$emit('create')" :label="'Utwórz ' + label" />
        <CustomButton label="Eksportuj" @click="$emit('export');" />
        <ImportButton :url="`/${geobject}/import`" />
    </div>
</template>

<script>
    // import Dialog from "primevue/dialog";
    // import Toast from "primevue/toast";
    import {getListOfPrograms, listFieldsForProgram} from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import GeographicalFilters from "@/components/filters/GeographicalFilters.vue";
    // import CustomFileUpload from "@/components/CustomFileUpload.vue";
    import ImportButton from "@/components/ImportButton.vue";

    export default {
        name: "DictSearchCriteria",

        components: {
            ImportButton,
            // Dialog,
            // CustomFileUpload,
            CustomMultiSelect,
            CustomInputText,
            CustomButton,
            // Toast,
            GeographicalFilters,
        },

        props: {
            modelValue: {
                type: Object,
                required: true,
            },
            label: {
                type: String,
                default: "",
            },
            geobject: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                programs: [],
                fields: [],
                importDialogVisible: false,
                importedFile: null,
                matchModeOptions: [
                    {value: "FIELD", label: "Powierzchni"},
                    {value: "POINT", label: "Punktach"},
                    {value: "POSITION", label: "Stanowiskach"},
                    {value: "TRANSECT", label: "Transektach"},
                ],
                programType: null,
            };
        },

        emits: ["import", "export", "create", "update:modelValue"],

        beforeMount() {
            this.programType = this.checkProgramType(this.$route.path);
            getListOfPrograms({type: this.programType})
                .then((response) => {
                    this.programs = response.data;
                });

            this.matchModeOptions = this.matchModeOptions
                .filter((opt) => opt.value === "FIELD" || opt.value === this.geobject.toUpperCase());
        },

        methods: {
            checkProgramType(url) {
                if (url.includes("position")) return "POSITION";
                if (url.includes("transect")) return "TRANSECT";
                if (url.includes("point")) return "POINT";
                return "";
            },
            getFieldsForPrograms() {
                if (!this.searchCriteria.programs || this.searchCriteria.programs.length === 0) {
                    this.fields = [];
                    return;
                }
                const promises = this.searchCriteria.programs.map((program) => listFieldsForProgram({ program }));
                Promise.all(promises)
                    .then((responses) => {
                        this.fields = responses.reduce((acc, response) => acc.concat(response.data), []);
                    })
                    .catch((error) => {
                        console.error("An error occurred:", error);
                    });
            },
            clear() {
                this.searchCriteria.programs = [];
                this.searchCriteria.name = "";
                this.searchCriteria.field.name = "";
                this.searchCriteria.order = null;
                this.$refs.geographicalFilters.clear();
                this.$emit("update:modelValue", this.searchCriteria);
            },
            importFile(value) {
                this.importedFile = value;
            },
            importData() {
                if (this.importedFile != null) {
                    this.$emit("import", this.importedFile);
                    this.importDialogVisible = false;
                }
            },
        },

        computed: {
            searchCriteria: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            searchCriteria: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
            "searchCriteria.programs"() {
                this.getFieldsForPrograms();
            },
        },
    };
</script>

<style scoped lang="scss"></style>
