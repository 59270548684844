<template>
    <div class="p-3">
        <h2>Szczegóły gatunku ptaka</h2>
        <div class="flex column-gap-3 mb-2 flex-direction-column">
            <CustomInputText label="Kod gatunku (MPPL)" name="ident1" v-model="bird.ident1"
                             class="w-3 max-width" />
            <CustomInputText label="Kod gatunku (obrączkarski)" name="ident2" v-model="bird.ident2"
                             class="w-3 max-width" />
            <CustomInputNumber label="Identyfikator EURING" name="identEuring" v-model="bird.identEuring"
                               class="w-3 max-width" />
            <CustomInputText label="Indeks" name="indexVal" v-model="bird.indexVal"
                             class="w-3 max-width" />
        </div>
        <div class="flex column-gap-3 mb-2 flex-direction-column">
            <CustomInputText label="Nazwa polska" name="namePl" v-model="bird.namePl"
                             class="w-4 max-width" />
            <CustomInputText label="Nazwa angielska" name="nameEn" v-model="bird.nameEn"
                             class="w-4 max-width" />
            <CustomInputText label="Nazwa łacińska" name="nameLat" v-model="bird.nameLat"
                             class="w-4 max-width" />
        </div>
        <div class="flex column-gap-3 flex-direction-column">
            <CustomMultiSelect label="Programy" name="programs" v-model="selectedPrograms" :items="programs" preventSorting
                               itemLabel="program" itemValue="" class="w-6 max-width" itemDisabled="disabled"/>
            <CustomSelectOne label="Program priorytetowy" name="priorityProgram" v-model="bird.priorityProgram"
                             :items="programsForPriority" itemLabel="" itemValue="" class="w-6 max-width"/>
        </div>
        <div class="flex column-gap-4 my-3 flex-wrap">
            <CustomCheckbox label="Gatunek możliwy do wpisywania w formularzach" name="choosable" v-model="bird.choosable" />
        </div>
        <div class="flex column-gap-4 my-3 flex-wrap">
            <CustomCheckbox label="Ochrona gatunkowa" name="protSpec" v-model="bird.protSpec" />
            <CustomCheckbox label="Ochrona strefowa" name="protZone" v-model="bird.protZone" />
        </div>
        <div class="flex column-gap-4 mb-3 flex-wrap">
            <CustomCheckbox label="Dyrektywa Ptasia, Par. 4(1) i Zał. 1" name="dpAneks1" v-model="bird.dpAneks1" />
            <CustomCheckbox label="Dyrektywa Ptasia, Zał. 2/1" name="dpAneks21" v-model="bird.dpAneks21" />
            <CustomCheckbox label="Dyrektywa Ptasia, Zał. 2/2" name="dpAneks22" v-model="bird.dpAneks22" />
            <CustomCheckbox label="Dyrektywa Ptasia, Zał. 3/1" name="dpAneks31" v-model="bird.dpAneks31" />
            <CustomCheckbox label="Dyrektywa Ptasia, Zał. 3/2" name="dpAneks32" v-model="bird.dpAneks32" />
        </div>
        <div class="flex column-gap-4 mb-3 flex-wrap">
            <CustomCheckbox label="Konwencja Berneńska, Zał. 2" name="bernApp2" v-model="bird.bernApp2" />
            <CustomCheckbox label="Konwencja Berneńska, Zał. 3" name="bernApp3" v-model="bird.bernApp3" />
            <CustomCheckbox label="Konwencja Bońska, Zał. 1" name="bonnApp1" v-model="bird.bonnApp1" />
            <CustomCheckbox label="Konwencja Bońska, Zał. 2" name="bonnApp2" v-model="bird.bonnApp2" />
        </div>
        <div class="flex column-gap-3 mb-2 flex-direction-column">
            <CustomInputNumber label="Referencyjna wielkość populacji" name="popRef" v-model="bird.popRef"
                               class="w-3 max-width" longLabel />
            <CustomInputNumber label=" Minimalna wielkość populacji" name="curPopMin" v-model="bird.curPopMin"
                               class="w-3 max-width" longLabel />
            <CustomInputNumber label="Maksymalna wielkość populacji" name="curPopMax" v-model="bird.curPopMax"
                               class="w-3 max-width" longLabel />
            <CustomInputText label="Źródło danych o wielkości populacji" name="curPopSource"
                             v-model="bird.curPopSource" class="w-3 max-width" longLabel />
        </div>
        <div class="flex column-gap-3 mb-2 flex-direction-column">
            <CustomInputText label="Status gatunku w Polsce" name="statusKf" v-model="bird.statusKf" class="w-3 max-width" />
            <CustomInputText label="Kategoria wg klasyfikacji Komisji Faunistycznej SO PTZool oraz AERC"
                             name="occurrenceKf" v-model="bird.occurrenceKf" class="w-4 max-width" longLabel />
            <CustomInputText label="Globalny status zagrożenia wg kryteriów IUCN" name="iucn" v-model="bird.iucn"
                             class="w-3 max-width" longLabel />
            <CustomInputText label="Kategorie SPEC" name="specCat" v-model="bird.specCat"
                             class="w-2 max-width" />
        </div>
        <div>
            <p class="text-xl mb-0">Ustawienia gatunku w programach:</p>
            <CustomCheckbox label="Wszystkie programy mają takie same ustawienia" name="allProgramsHaveSameSettings"
                            v-model="allProgramsHaveSameSettings" class="my-5" />
            <div v-if="allProgramsHaveSameSettings">
                <CustomCheckbox label="Liczony jest wiek" name="ageCounting" v-model="programSettings.ageCounting" />
                <CustomCheckbox label="Liczona jest płeć" name="sexCounting" v-model="programSettings.sexCounting" />
                <CustomCheckbox label="Gatunek musi być liczony" name="mustBeCounted" v-model="programSettings.mustBeCounted" />
            </div>
            <div v-else class="flex flex-wrap gap-8">
                <div v-for="speciesProgram in bird.programs" :key="speciesProgram.program">
                    <p>{{ speciesProgram.program }}</p>
                    <CustomCheckbox label="Liczony jest wiek" name="ageCounting" v-model="speciesProgram.ageCounting" />
                    <CustomCheckbox label="Liczona jest płeć" name="sexCounting" v-model="speciesProgram.sexCounting" />
                    <CustomCheckbox label="Gatunek musi być liczony" name="mustBeCounted" v-model="speciesProgram.mustBeCounted" />
                </div>
            </div>
        </div>
        <div class="flex gap-3 mt-8 justify-content-end">
            <CustomButton class="form-button" label="Zapisz"
                          bgColor="var(--secondary-color)" color="var(--surface-a)" @click="save"/>
            <CustomButton class="form-button" label="Anuluj" name=""
                          bgColor="rgba(193, 197, 204, 0.4)" />
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import {
        getBird,
        getListOfPrograms,
        updateBird,
        createBird,
    } from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "BirdsDetailsView",

        components: {
            CustomInputText,
            CustomInputNumber,
            CustomCheckbox,
            CustomSelectOne,
            CustomButton,
            CustomMultiSelect,
        },

        data() {
            return {
                bird: EmptyStructures.getEmptyDictBird(),
                allProgramsHaveSameSettings: false,
                programs: [],
                programsForPriority: [],
                cloneBirdPrograms: null,
                programSettings: EmptyStructures.getEmptyProgramSpecies(""),
            };
        },

        beforeMount() {
            if (this.$route.params.id !== "new") {
                getBird({id: this.$route.params.id})
                    .then((response) => {
                        this.bird = response.data;
                        if (this.bird.programs == null) this.bird.programs = [];
                        if (this.bird.nonEditablePrograms == null) this.bird.nonEditablePrograms = [];
                        /* eslint-disable */
                        this.bird.programs = this.bird.programs.map((p) => {
                            return {...p, disabled: false};
                        });
                        this.bird.nonEditablePrograms = this.bird.nonEditablePrograms.map((p) => {
                            return {program: p, disabled: true};
                        });
                        /* eslint-enable */

                        if (this.bird.programs.length > 0 && this.allEqual(this.bird.programs, "ageCounting")
                            && this.allEqual(this.bird.programs, "sexCounting") && this.allEqual(this.bird.programs, "mustBeCounted")) {
                            this.allProgramsHaveSameSettings = true;
                            this.programSettings = _.cloneDeep(this.bird.programs[0]);
                            this.programSettings.program = "";
                        }
                        return getListOfPrograms();
                    })
                    .then((response) => {
                        this.setPrograms(response);
                    });
            } else {
                getListOfPrograms()
                    .then((response) => {
                        this.setPrograms(response);
                    });
            }
        },

        methods: {
            setPrograms(response) {
                this.programsForPriority = response.data;
                /* eslint-disable */
                this.programs = response.data.map((p) => {
                    return {disabled: false, program: p};
                });
                /* eslint-enable */
                this.bird.nonEditablePrograms.forEach((nep) => {
                    this.programs.forEach((p) => {
                        if (p.program === nep.program) p.disabled = true;
                    });
                });
            },
            allEqual(list, property) {
                if (list === null || list === undefined || list.length === 0) return true;
                const first = list[0][property];
                return list.every((obj) => obj[property] === first);
            },
            save() {
                const body = {
                    ...this.bird,
                    nonEditablePrograms: null,
                    specialSpecies: null,
                };
                if (this.$route.params.id !== "new") {
                    updateBird({id: this.bird.id, body})
                        .then(() => {
                            this.$toast.add({
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zapisano dane",
                                life: 3000,
                            });
                        })
                        .catch((error) => {
                            this.$toast.add({
                                severity: "error",
                                summary: "Wystąpił błąd",
                                detail: "Podczas zapisywania danych wystąpił błąd: " + error,
                                life: 3000,
                            });
                        });
                } else {
                    createBird({body: body})
                        .then(() => {
                            this.$toast.add({
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zapisano dane",
                                life: 3000,
                            });
                            this.$router.push("/dicts/birds");
                        }).catch(() => {
                            this.$toast.add({
                                severity: "error",
                                summary: "Wystąpił błąd",
                                detail: "Podczas zapisywania danych wystąpił błąd",
                                life: 3000,
                            });
                        });
                }
            },
        },
        computed: {
            selectedPrograms: {
                get() {
                    const ps = [];
                    this.bird.programs.forEach((p) => ps.push({disabled: p.disabled, program: p.program}));
                    this.bird.nonEditablePrograms.forEach((p) => ps.push(p));
                    return ps;
                },
                set(newValue) {
                    const oldPrograms = this.bird.programs.map((p) => p.program);
                    const newPrograms = newValue.map((p) => p.program);
                    this.bird.programs = this.bird.programs.filter((p) => newPrograms.includes(p.program));
                    newValue.forEach((p) => {
                        if (!oldPrograms.includes(p.program) && !p.disabled) {
                            if (this.allProgramsHaveSameSettings) {
                                const newProgram = _.cloneDeep(this.programSettings);
                                newProgram.program = p.program;
                                this.bird.programs.push(newProgram);
                            } else {
                                this.bird.programs.push(EmptyStructures.getEmptyProgramSpecies(p.program));
                            }
                        }
                    });
                },
            },
        },

        watch: {
            allProgramsHaveSameSettings(newValue) {
                if (newValue === true && this.bird.programs.length > 0) {
                    const first = this.bird.programs[0];
                    this.programSettings = {
                        ageCounting: first.ageCounting,
                        sexCounting: first.sexCounting,
                        mustBeCounted: first.mustBeCounted,
                        disabled: false,
                    };
                }
            },
            programSettings: {
                handler(value) {
                    this.bird.programs.forEach((p) => {
                        p.ageCounting = value.ageCounting;
                        p.sexCounting = value.sexCounting;
                        p.mustBeCounted = value.mustBeCounted;
                    });
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 900px) {
        .flex-direction-column {
            flex-direction: column;
            .max-width {
                width: 100% !important;
            }
        }
    }
</style>
