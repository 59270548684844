<template>
    <div class="program-main-info">
        <div class="flex column-gap-3 pr-6 flex-direction-column-900">
            <CustomInputText label="Kod" class="w-3 max-width" name="key" v-model="program.key" />
            <CustomInputText label="Nazwa" class="w-3 max-width" name="name" v-model="program.name" />
        </div>
        <div class="flex column-gap-3 flex-direction-column-600">
            <div class="flex column-gap-3 w-6 flex-direction-column-1350 max-width">
                <CustomCalendar label="Data początkowa" class="w-6 max-width" name="startTime"
                                v-model="program.startTime" />
                <CustomCalendar label="Czas upływu kontroli regionalnej" class="w-6 max-width" name="regionalDeadline"
                                v-model="program.regionalDeadline" />
            </div>
            <div class="flex column-gap-3 w-6 flex-direction-column-1350 max-width">
                <CustomCalendar label="Czas upływu kontroli krajowej" class="w-6 max-width" name="nationalDeadline"
                                v-model="program.nationalDeadline" />
                <CustomCalendar label="Czas upływu głównej kontroli" class="w-6 max-width" name="mainDeadline"
                                v-model="program.mainDeadline" />
            </div>
        </div>
        <div v-if="program.suggestedGeographicalObjects != null" class="flex column-gap-3 pr-6 flex-direction-column-900">
            <CustomInputNumber label="Sugerowana liczba obiektów" class="w-3 max-width" name="suggestedGeographicalObjects"
                               v-model="program.suggestedGeographicalObjects" disabled/>
        </div>
        <div class="flex column-gap-4 justify-content-between mt-2">
            <CustomCheckbox label="Z pominięciem koordynatorów regionalnych" v-model="noRegionalCoordinators" disabled
                            name="noRegionalCoordinators"/>
            <CustomCheckbox label="Jeden obserwator wykonuje wszystkie kontrole" v-model="program.allControls" disabled name="allControls"/>
            <CustomCheckbox label="Posiada podpowierzchnie" v-model="program.hasSubfields" disabled name="hasSubfields"/>
            <CustomCheckbox label="Posiada dane siedliskowe" v-model="program.hasHabitatData" disabled name="hasHabitatData"/>
            <CustomCheckbox label="Wszystkie gatunki są liczone" v-model="program.hasAllSpecies" disabled name="hasAllSpecies"/>
        </div>
    </div>
</template>

<script>
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import CustomInputText from "@/components/form/CustomInputText";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";

    export default {
        name: "ProgramMainInfo",

        components: {
            CustomInputNumber,
            CustomCheckbox,
            CustomCalendar,
            CustomInputText,
        },

        props: {
            modelValue: {
                type: Object,
            },
        },

        computed: {
            program: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            noRegionalCoordinators() {
                // if (this.program == null)
                return !this.program.hasRegionalCoordinators;
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../assets/theme/mytheme/variables";

    .program-main-info {
        .text-style {
            color: var(--text-color-secondary);
            font-weight: 600;
        }
    }
    @media screen and (max-width: 600px) {
        .flex-direction-column-600 {
            flex-direction: column;
            padding-right: 0 !important;
            .max-width {
                width: 100% !important;
            }
        }
    }
    @media screen and (max-width: 900px) {
        .flex-direction-column-900 {
            flex-direction: column;
            padding-right: 0 !important;
            .max-width {
                width: 100% !important;
            }
        }
    }
    @media screen and (max-width: 1350px) {
        .flex-direction-column-1350 {
            flex-direction: column;
            padding-right: 0 !important;
            .max-width {
                width: 100% !important;
            }
        }
    }
</style>
