<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="point" :geobjects="points" geobjectType="punkt"
                              additionalInformationInMain v-model:additionalInformation="internalForm"
                              birdsHeader="Gatunki docelowe" v-model:dangers="internalForm.dangers">
        <template v-slot:mainData>
            <ControlTypeAndDates v-model="internalForm" />
            <ControlWeather v-model="internalForm" hideAudibility
                            hideVisibility hideRain />
        </template>
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition header="Punkty" v-model="point" :positions="points" :field="field" v-model:step="internalStep"/>
        </template>
        <template v-slot:controlData>
            <div class="flex-direction-column-800">
                <CustomCheckbox class="w-4" label="Brak liczenia" name="noCounting" v-model="point.noControl" :disabled="!editing" />
                <CustomSelectOne v-if="point.noControl" class="w-4 max-width" label="Przyczyna pominięcia punktu"
                                 :items="internalNoControlReason" name="noCounting"
                                 v-model="point.noControlReason"
                                 :disabled="!editing" />
            </div>
            <div v-if="!point.noControl" class="flex column-gap-2 flex-direction-column-700">
                <CustomTimePicker label="Czas rozpoczęcia" class="w-4 max-width" v-model="point.startTime"
                                  name="pointStartTime" :showErrors="showErrorMessages" required
                                  :customErrorMessage="getPointStartTimeErrorMessage()"
                                  :disabled="!editing" />
                <CustomSelectOne class="w-4 max-width" label="Wabienie" name="animalDisplay" :items="dicts.animalDisplay"
                                 v-model="point.animalDisplay"
                                 :disabled="!editing" required :showErrors="showErrorMessages" />
                <CustomMultiSelect label="Inne gatunki dzięciołów" name="otherBirds" v-model="point.species"
                                   :items="internalOtherBirds" itemLabel="name" class="w-4 max-width"
                                   :disabled="!editing" />
            </div>
        </template>
        <template v-if="!point.noControl" v-slot:birdsData>
            <FormBirds v-model:birds="point.birds" v-model:noBirds="point.noBirds" label="Brak gatunków docelowych" />
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import ControlTypeAndDates from "@/components/formSharedComponents/ControlTypeAndDates.vue";
    import ControlWeather from "@/components/formSharedComponents/ControlWeather";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomTimePicker from "@/components/form/CustomTimePicker";
    import {DateUtils} from "@/utils/DateUtils";
    import FormBirds from "@/components/formBirds/FormBirds.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";

    export default {
        name: "FormMRDObservations",

        components: {
            FormObservationsTemplate,
            ObservationsSelectPosition,
            ControlTypeAndDates,
            ControlWeather,
            CustomCheckbox,
            CustomSelectOne,
            CustomTimePicker,
            FormBirds,
            CustomMultiSelect,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
            step: {
                type: Number,
                required: true,
            },
            field: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                points: [],
                activeIndex: 0,
                index: 0,
                point: _.cloneDeep(EmptyStructures.getEmptyMRDPoint()),
                internalNoControlReason: [],
                internalOtherBirds: [],
            };
        },

        emits: ["update:step", "update:form"],

        inject: ["showErrorMessages", "editing", "dicts"],

        beforeMount() {
            if (this.dicts.controlNotRealizedReason?.length > 0) {
                this.internalNoControlReason = this.dicts.controlNotRealizedReason.filter((cNRR) => cNRR.info?.length > 0 && cNRR.code !== "LATER");
            }
            if (this.dicts.programBirds?.length > 0) {
                this.internalOtherBirds = this.dicts.programBirds.filter((b) => b.info === undefined || b.info == null);
            }
            this.points = this.internalForm.points.filter((p) => (p.notAudited === null || !p.notAudited));
        },

        methods: {
            getPointStartTimeErrorMessage() {
                if (DateUtils.isTimeEarlier(this.point.startTime, this.form.startTime)
                    || DateUtils.isTimeEarlier(this.form.endTime, this.point.startTime)) {
                    return "Czas rozpoczęcia liczenia musi znajdować się między czasem rozpoczęcia i zakończenia kontroli";
                }
                return "";
            },
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 700px) {
        .flex-direction-column-700 {
            flex-direction: column;
            .max-width {
                width: 100% !important;
            }
        }
    }
    @media screen and (max-width: 800px) {
        .flex-direction-column-800 {
            flex-direction: column;
            .max-width {
                width: 100% !important;
            }
        }
    }
</style>
