<template>
    <div class="monitoring-program-observers">
        <ImportCastDialog v-model="showImportCastDialog" @reloadProgramObservers="reloadProgramObservers" />
        <HeaderComponent headerText="Obserwatorzy" class="my-0" :buttons="buttons" @clickButton="clickButton" />
        <!-- <ObserverSearchCriteria v-model="searchCriteria" @edit="edit" :editCast="editCast" class="mx-2"/> -->
        <ObserverTable :editCast="editCast" @reloadProgramState="reloadProgramState"
                       ref="observerTable" />
    </div>
</template>

<script>
    import {FileUtils} from "@/utils/FileUtils";
    import {SystemRole} from "@/utils/SystemRole";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    // import ObserverSearchCriteria from "@/views/monitoringProgram/components/observer/ObserverSearchCriteria.vue";
    import ObserverTable from "@/views/monitoringProgram/components/observer/ObserverTable.vue";
    import HeaderComponent from "@/components/HeaderComponent.vue";
    import ImportCastDialog from "@/views/monitoringProgram/components/observer/ImportCastDialog.vue";

    export default {
        name: "MonitoringProgramObservers",

        components: {
            ImportCastDialog,
            HeaderComponent,
            ObserverTable,
            // ObserverSearchCriteria,
        },

        props: {
            modelValue: null,
        },

        inject: ["programEditionState", "year", "programCode"],

        data() {
            return {
                searchCriteria: SearchCriteria.getClearSearchCriteriaObserver(this.$route.params.id),
                showImportCastDialog: false,
                buttons: [
                    {
                        label: "Eksportuj",
                        bgColor: "var(--surface-a)",
                        labelColor: "var(--text-color)",
                        onClick: () => this.exportCast(),
                        show: false,
                    },
                ],
            };
        },

        emits: ["reloadProgramState", "update:modelValue"],

        beforeMount() {
            this.buttons[0].show = this.canExport;
        },

        methods: {
            reloadProgramState() {
                this.$emit("reloadProgramState");
            },
            edit() {
                this.editCast = !this.editCast;
            },
            reloadProgramObservers() {
                this.$refs.observerTable.search();
            },
            search() {
                this.$refs.observerTable.search();
            },
            clickButton(value) {
                if (this.buttons[value].onClick) {
                    this.buttons[value].onClick();
                }
            },
            exportCast() {
                FileUtils.downloadFile("", `obsada_${this.programCode}_${this.year}.xlsx`, `/programEdition/cast/export/${this.$route.params.id}`);
            },
        },

        computed: {
            canImportCast() {
                return SystemRole.isMainCoordinator()
                    || (SystemRole.isNationalCoordinator() && this.programEditionState !== "PROGRAM_FINISHED");
            },
            editCast: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            canExport() {
                return (this.programEditionState !== "CAST_NOT_ENTERED"
                    && this.programEditionState !== "CAST_WAITING_FOR_NATIONAL_APPROVAL"
                    && this.programEditionState !== "CAST_WAITING_FOR_MAIN_APPROVAL");
            },
        },

        watch: {
            editCast(value) {
                this.buttons[0].show = value && this.canImportCast;
            },
            canExport(value) {
                this.buttons[0].show = value;
            },
        },
    };
</script>

<style lang="scss">
@import "../../../../assets/theme/mytheme/variables";
    .monitoring-program-observers {
        border: 1px solid var(--surface-border);
        margin-top: 1.5rem;
    }
</style>
