<template>
    <FormObservationsTemplate ref="observationsTemplate" geobjectType="punkt" v-model:geobject="point" :geobjects="points"
                              v-model:additionalInformation="internalForm" additionalInformationInMain
                              v-model:dangers="internalForm.dangers">
        <template v-slot:mainData>
            <BasicInformations v-model="internalForm" timePicker />
        </template>
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition header="Punkty" v-model="point" :positions="points" :field="field"
                                        v-model:step="internalStep"/>
        </template>
        <template v-slot:controlData>
            <CustomTimePicker label="Czas rozpoczęcia" class="w-3 pr-1" v-model="point.pointStartTime"
                              name="pointStartTime" :showErrors="showErrorMessages" required
                              :customErrorMessage="getPointStartTimeErrorMessage()"
                              style="min-width: 200px;"/>
            <ControlWeather v-model="point" hideVisibility />
        </template>
        <template v-slot:birdsData>
            <FormBirdsMSKR v-model:birds="point.birds" v-model:noBirds="point.noBirds"/>
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import CustomTimePicker from "@/components/form/CustomTimePicker";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import ControlWeather from "@/components/formSharedComponents/ControlWeather";
    import FormBirdsMSKR from "@/views/formMSKR/components/FormBirdsMSKR.vue";
    import BasicInformations from "@/components/formSharedComponents/BasicInformations.vue";
    import {DateUtils} from "@/utils/DateUtils";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";

    export default {
        name: "FormMSKRObservations",

        components: {
            FormObservationsTemplate,
            FormBirdsMSKR,
            CustomTimePicker,
            ObservationsSelectPosition,
            ControlWeather,
            BasicInformations,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
            step: {
                type: Number,
                required: true,
            },
            field: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                points: [],
                activeIndex: 0,
                index: 0,
                point: _.cloneDeep(EmptyStructures.getEmptyMSKRPoint()),
            };
        },

        emits: ["update:step", "update:form"],

        inject: ["showErrorMessages", "editing", "controlTimeFrame"],

        beforeMount() {
            this.points = this.internalForm.points.filter((p) => (p.notAudited === null || !p.notAudited));
        },

        methods: {
            getMinMaxErrorMessage(min, max) {
                if (min != null && max != null && min > max) return "max mniejsze niż min";
                return "";
            },
            getPointStartTimeErrorMessage() {
                if (DateUtils.isTimeEarlier(this.point.pointStartTime, this.form.startTime)
                    || DateUtils.isTimeEarlier(this.form.endTime, this.point.pointStartTime)) {
                    return "Czas rozpoczęcia liczenia musi znajdować się między czasem rozpoczęcia i zakończenia kontroli";
                }
                return "";
            },
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
@import "../../../assets/theme/mytheme/variables";
</style>
