/* eslint-disable */
export const EmptyStructures = {

    getEmptyMPSControl() {
        return {
            id: null,
            birds: [],
            control: null,
            controlDate: "",
            startTime: "",
            endTime: "",
            noBirds: false,
            rain: null,
            visibility: null,
            wind: null,
            clouds: null,
            additionalObservers: "",
            notes: "",
        };
    },

    getEmptyMGRControl() {
        return {
            controlDate: null,
            notes: null,
            additionalObservers: null,
            nestingCriteria: null,
            humanActivity: null,
            breedingResult: null,
            controlMethod: null,
            nestlings: null,
            lossesStage: null,
            entireBroodLost: null,
            lossesCircumstances: null,
        };
    },

    getEmptyMCHControl() {
        return {
            additionalObservers: null,
            control: null,
            controlDate: null,
            noControl: null,
            endTime: null,
            id: null,
            noBirds: false,
            notes: null,
            startTime: null,
        };
    },

    getEmptyMPSPosition() {
        return {
            nestType: null,
            noNest: null,
            treeType: null,
            additionalInfo: null,
            notAudited: null,
            notAuditedReason: null,
            longitude: null,
            latitude: null,
            position: {
                name: "",
                coordinates: {
                    longitude: null,
                    latitude: null,
                },
                accepted: false,
            },
            controls: [],
        };
    },

    getEmptyMCHPosition() {
        return {
            plantsType: null,
            noNest: null,
            habitatType: null,
            notAudited: null,
            notAuditedReason: null,
            longitude: null,
            latitude: null,
            position: {
                name: "",
                coordinates: {
                    longitude: null,
                    latitude: null,
                },
                accepted: false,
            },
            birds: [],
        };
    },

    getEmptyMPSBirds() {
        return {
            countingMethod: null,
            nestingCriteria: null,
            males: null,
            females: null,
            otherAdults: null,
            nestlings: null,
            additionalInfo: "",
        };
    },

    getEmptyMCHBirds() {
        return {
            ordinal: null,
            species: null,
            controlType: null,
            countingMethod: null,
            nests: null,
            adults: null,
            pairs: null,
            nestingCriteria: [],
        };
    },

    getEmptyAddMPSPosition(value) {
        return {
            additionalInfo: "",
            controls: [],
            nestType: null,
            noNest: null,
            positionId: value.positionId,
            name: value.name,
            coordinates: {
                longitude: value.coordinates.longitude,
                latitude: value.coordinates.latitude,
            },
            accepted: null,
            status: "CREATED",
        };
    },

    getEmptyBirds() {
        return {
            id: null,
            transectSection: null,
            species: null,
            species2: null,
            count: null,
            singingMales: null,
            sex: null,
            age: null,
            distance: null,
            controlType: null,
            countingMethod: null,
            nests: null,
            adults: null,
            pairs: null,
            nestlings: null,
            males: null,
            females: null,
            otherAdults: null,
            nestingCriteria: null,
            additionalInfo: null,
            inFlight: false,
            notCounted: false,
            counted: true,
            transectZone: null,
            noBirds: false,
            transect: null,
            reviers: null,
            reaction: null,
            controlTime: null,
            count1: null,
            count2: null,
            count3: null,
            minimum: null,
            maximum: null,
            activity: null,
            young: null,
        };
    },

    getEmptyMMCBird() {
        return {
            nestingCriteria1: null,
            nestingCriteria2: null,
            loss: false,
            pair: {key: "NIE", code: "NIE", label: "Nie"},
        };
    },

    getEmptyMMCOtherBird() {
        return {
            species: null,
            countingMethod: {key: "MNG_1", code: "1", label: "Dokładne liczenie"},
            pairs: null,
            whenCounted: null,
        };
    },

    getEmptyMammals() {
        return {
            ordinal: null,
            mammal: null,
            count: null,
            distance: null,
        };
    },

    getEmptyNests() {
        return {
            species: null,
            count: null,
            nestlings: null,
        };
    },

    getEmptyObservation() {
        return {
            transectSection: null,
            startTime1: null,
            depth: null,
            seaState: null,
            rain: null,
            clouds: null,
        };
    },

    getEmptyAddMCHPosition(value) {
        return {
            plantsType: null,
            noNest: null,
            habitatType: null,
            notAudited: null,
            notAuditedReason: null,
            longitude: null,
            latitude: null,
            positionId: value.positionId,
            name: value.name,
            coordinates: {
                longitude: value.coordinates.longitude,
                latitude: value.coordinates.latitude,
            },
            birds: [],
            accepted: null,
            status: "CREATED",
        };
    },

    getEmptyMNGPosition() {
        return {
            notAudited: null,
            notAuditedReason: null,
            name: "",
            coordinates: {
                latitude: null,
                longitude: null,
            },
            accepted: null,
            status: "CREATED",
            control: {},
            flocks: [],
        };
    },

    getEmptyMNGControl() {
        return {
            controlDate: null,
            startTime: null,
            endTime: null,
            notes: "",
            clouds: null,
            rain: null,
            wind: null,
            visibility: null,
            additionalObservers: "",
            noBirds: false,
            noControl: false,
        };
    },

    getEmptyMNGFlock(ordinal) {
        return {
            id: null,
            ordinal,
            birds: [],
        };
    },

    getEmptyMNGBirds() {
        return {
            id: null,
            ordinal: null,
            species: null,
            controlMethod: null,
            countingMethod: null,
            birds: null,
        };
    },

    getEmptyMKOPosition() {
        return {
            notAudited: null,
            notAuditedReason: null,
            name: "",
            coordinates: {
                latitude: null,
                longitude: null,
            },
            accepted: null,
            status: "CREATED",
            control: {
                controlDate: "",
                startTime: "",
                endTime: "",
                noBirds: false,
                occupiedNests: null,
                groundNests: null,
                humanActivity: null,
                humanActivityDescription: "",
                notes: "",
                additionalObservers: "",
            },
        };
    },

    getEmptyMSKRPoint() {
        return {
            point: null,
            notAudited: null,
            notAuditedReason: null,
            name: "",
            coordinates: {
                latitude: null,
                longitude: null,
            },
            accepted: null,
            status: "CREATED",
            control: {
                startTime: null,
                noBirds: false,
                clouds: null,
                rain: null,
                wind: null,
                audibility: null,
                birds: [],
            },
        };
    },

    getEmptyMLSLPoint() {
        return {
            point: null,
            notAudited: null,
            notAuditedReason: null,
            name: "",
            coordinates: {
                latitude: null,
                longitude: null,
            },
            accepted: null,
            status: "CREATED",
            control: {
                startTime: null,
                noBirds: null,
                clouds: null,
                rain: null,
                wind: null,
                audibility: null,
                birds: [],
            },
        };
    },

    getEmptyMRCPosition() {
        return {
            position: null,
            notAudited: null,
            notAuditedReason: null,
            name: "",
            coordinates: {
                latitude: null,
                longitude: null,
            },
            accepted: null,
            status: "CREATED",
            nestlings: null,
            additionalInfo: null,
        };
    },

    getEmptyMRCControl() {
        return {
            controlDate: null,
            startTime: null,
            endTime: null,
            control: null,
            noBirds: false,
            additionalObservers: null,
            additionalInfo: null,
            noControl: null,
            noControlReason: null,
            controlMethod: null,
            occupiedNests: null,
        };
    },

    getEmptyMGZPosition() {
        return {
            position: null,
            notAudited: null,
            notAuditedReason: null,
            name: "",
            coordinates: {
                latitude: null,
                longitude: null,
            },
            accepted: null,
            status: "CREATED",
            control: {
                controlDate: null,
                startTime: null,
                endTime: null,
                noBirds: false,
                habitat1: null,
                habitat2: null,
                additionalObservers: null,
                notes: null,
                birds: [],
            },
        };
    },

    getEmptyMPDPosition() {
        return {
            position: null,
            notAudited: null,
            notAuditedReason: null,
            name: "",
            coordinates: {
                latitude: null,
                longitude: null,
            },
            accepted: null,
            status: "CREATED",
            control: {
                controlDate: null,
                startTime: null,
                endTime: null,
                noBirds: false,
                habitat1: null,
                habitat2: null,
                additionalObservers: null,
                notes: null,
                birds: [],
            },
        };
    },

    getEmptyMKRPosition() {
        return {
            position: null,
            notAudited: null,
            notAuditedReason: null,
            control1: null,
            control2: null,
        };
    },

    getEmptyMKRControl() {
        return {
            controlDate: null,
            startTime: null,
            endTime: null,
            notes: null,
            clouds: null,
            rain: null,
            wind: null,
            additionalObservers: null,
            noBirds: false,
            noControl: null,
            adults: null,
            young: null,
            nestlings: null,
            nestingCriteria: null,
        };
    },

    getEmptyMWOPControl() {
        return {
            controlDate: null,
            startTime: null,
            endTime: null,
            clouds: null,
            rain: null,
            wind: null,
            visibility: null,
            singingMales: null,
            noControl: null,
        };
    },

    getEmptyMPPMPoint() {
        return {
            point: null,
            notAudited: null,
            notAuditedReason: null,
            name: "",
            coordinates: {
                latitude: null,
                longitude: null,
            },
            accepted: null,
            status: "CREATED",
            noBirds: false,
            birds: [],
        };
    },

    getEmptyMCZPosition() {
        return {
            controlDate: "",
            startTime: "",
            endTime: "",
            notes: "",
            additionalObservers: "",
            noBirds: false,
            birds: [],
            grayHeronCount: null,
            whiteHeronCount: null,
            distance: null,
            mainColonyLocation: null,
            otherColonyLocation: null,
        };
    },

    getEmptyTransectHabitatData(program) {
        return {
            controlDate: null,
            hab1Lev1: null,
            hab1Lev2: null,
            hab1Lev3_1: null,
            hab1Lev3_2: null,
            hab1Lev3_3: null,
            hab2Lev1: null,
            hab2Lev2: null,
            hab2Lev3_1: null,
            hab2Lev3_2: null,
            hab2Lev3_3: null,
            program
        }
    },

    getEmptyTransect() {
        return {
            transect: null,
            noFormControl: false,
            noFormControlReason: null,
            noFormControlNotes: "",
            startTime: null,
            endTime: null,
        };
    },

    getEmptyMWOTHabitatData() {
        return {
            bushHeight: null,
            cane: null,
            clumps: null,
            controlDate: null,
            differentUtilization: null,
            nestPlace: null,
            plantHeight: null,
            plantLitter: null,
            rut: null,
            treeBushesArea: null,
            treeType: null,
            utilization: null,
            water: null,
            when: null,
            program: "MWOT",
        }
    },

    getEmptyMPPMHabitatData() {
        return {
            habitatType: null,
            habitatType2: null,
            thermalModernization: null,
            swiftBoxes: null,
            program: "MPPM",
        }
    },

    isHabitatDataMPPMEmpty() {
        return hd.habitatType === null || hd.habitatType2 === null
            || hd.thermalModernization === null || hd.swiftBoxes === null;
    },

    isHabitatDataEmpty(hd) {
        return hd.controlDate == null && hd.hab1Lev1 == null && hd.hab1Lev2 == null && hd.hab1Lev3_1 == null
            && hd.hab1Lev3_2 == null && hd.hab1Lev3_3 == null && hd.hab2Lev1 == null && hd.hab2Lev2 == null
            && hd.hab2Lev3_1 == null && hd.hab2Lev3_2 == null && hd.hab2Lev3_3 == null;
    },

    getEmptyHabitatData() {
        return {
            controlDate: null,
            hab1Lev1: null,
            hab1Lev2: null,
            hab1Lev3_1: null,
            hab1Lev3_2: null,
            hab1Lev3_3: null,
            hab2Lev1: null,
            hab2Lev2: null,
            hab2Lev3_1: null,
            hab2Lev3_2: null,
            hab2Lev3_3: null,
            bushHeight: null,
            cane: null,
            clumps: null,
            differentUtilization: null,
            nestPlace: null,
            plantHeight: null,
            plantLitter: null,
            rut: null,
            treeBushesArea: null,
            treeType: null,
            utilization: null,
            water: null,
            when: null,
        }
    },

    getEmptyMDUHabitatData() {
        return {
            habitatType: null,
            utilization: null,
            water: null,
            spatial: null,
            succession: null,
            averageHeightPlant: null,
            averageHeightClump: null,
        };
    },

    getEmptyMDUPosition() {
        return {
            position: null,
            notAudited: false,
            notAuditedReason: null,
            notAuditedNotes: "",
            control1: null,
            control2: null,
        };
    },

    getEmptyMDUControl() {
        return {
            controlDate: null,
            startTime: null,
            endTime: null,
            clouds: null,
            rain: null,
            wind: null,
            visibility: null,
            positionThreats: null,
            notes: null,
            additionalObservers: null,
            birds: [],
            noBirds: false,
            habitatData: null,
            countingMethod: null
        };
    },

    getEmptyDictGeobject() {
        return {
            coordinates: {
                latitude: null,
                longitude: null,
            },
            year: null,
            controlVersion: null,
            accepted: null,
            shape: [],
            line: [],
        }
    },

    getEmptyDictBird() {
        return {
            ident1: null,
            ident2: null,
            identEuring: null,
            protSpec: false,
            protZone: false,
            dpAneks1: false,
            dpAneks21: false,
            dpAneks22: false,
            dpAneks31: false,
            dpAneks32: false,
            bernApp2: false,
            bernApp3: false,
            bonnApp1: false,
            bonnApp2: false,
            choosable: false,
            statusKf: null,
            occurrenceKf: null,
            iucn: null,
            specCat: null,
            popRef: null,
            curPopMin: null,
            curPopMax: null,
            curPopSource: null,
            indexVal: null,
            priorityProgram: null,
            deleted: false,
            identEunomen: null,
            namePl: null,
            nameEn: null,
            nameLat: null,
            specialSpecies: null,
            programs: [],
            nonEditablePrograms: [],
            ageCounting: false,
            sexCounting: false,
        }
    },

    getEmptyProgramSpecies(program) {
        return {
            program: program,
            sexCounting: false,
            ageCounting: false,
            mustBeCounted: false,
            disabled: false,
        };
    },

    getEmptyMNZPosition() {
        return {
            position: null,
            controlDate:null,
            startTime: null,
            endTime: null,
            clouds: null,
            rain: {key: "RAIN_1", code: "1", label: "1 – brak"},
            wind: null,
            visibility: {key: "", code: "1", label: "1 - dobra"},
            temperature: null,
            notes: null,
            additionalObservers: null,
            noBirds: false,
            birds: [],
            habitatData: null,
        };
    },

    getEmptyMNZHabitatData() {
        return {
            habitatType: null,
            dominantVegetation: [],
            habitatNotes: null,
        };
    },

    getEmptyMPGPoint() {
        return {
            birds: [],
            noBirds: false,
            startTime: null,
            habitatData: null,
            habitatDataToChange: null,
        };
    },

    getEmptyMPGHabitatData() {
        return {
            location: null,
            landform: null,
            direction: null,
            water: null,
            plantHeight: null,
            grassCovered: null,
            perennialsCovered: null,
            shrubsCovered: null,
            rockSurface: null,
            treeNumber: null,
            waterPresence: null,
        };
    },

    getEmptyMPWRPosition() {
        return {
            birds: [],
            noBirds: false,
            controlDate: null,
            startTime: null,
            endTime: null,
            habitatType: null,
            blackHeadedGull: false,
            controlMethod: null,
        };
    },

    getEmptyMZOPosition() {
        return {
            controlDate: null,
            startTime: null,
            endTime: null,
            noBeeEaterHabitat: false,
            noBeeEater: false,
            beeEaterAdults: null,
            beeEaterHoles: null,
            noSandMartin: false,
            breedingNotCount: false,
            sandMartinHoles: null,
            notes: null,
            additionalObservers: null,
            habitatData: null,
            habitatDataToChange: null,
        };
    },

    getEmptyMZOHabitatData() {
        return {
            habitatType: null,
            crops: null,
            buildingDistance: null,
            waterDistance: null,
            slopeHeight: null,
        };
    },

    getEmptyMSLPosition() {
        return {
            position: null,
            notAudited: false,
            notAuditedReason: null,
            notAuditedNotes: "",
            control1: null,
            control2: null,
            habitatData: null,
            habitatDataToChange: null,
        };
    },

    getEmptyMSLControl() {
        return {
            controlDate: null,
            startTime: null,
            endTime: null,
            birds: [],
            noBirds: false,
        };
    },

    getEmptyMSLHabitatData() {
        return {
            habitatType: null,
            habitatNotes: null,
        };
    },

    getEmptyMPOControl() {
        return {
            controlDate: null,
            startTime: null,
            endTime: null,
            noWater: false,
            noBirds: false,
            birds: [],
            birdNotes: null,
            notes: null,
            additionalObservers: null,
            noControl: false,
            noControlReason: null,
            noControlNotes: null,
        };
    },

    // getEmptyMPOHabitatData() {
    //     return {
    //         habitatType: null,
    //         otherHabitat: null,
    //         vegetation: null,
    //         otherVegetation: null,
    //     };
    // },

    getEmptyMFGPPosition() {
        return {
            position: null,
            notAudited: false,
            notAuditedReason: null,
            notAuditedNotes: "",
            controlDate: null,
            noBirds: false,
            birds: [],
            control1: null,
            control2: null,
        };
    },

    getEmptyMFGPControl() {
        return {
            controlDate: null,
            nestingCriteria: null,
            nestPosition: null,
            controlMethod: null,
            breedingResult: null,
            additionalInfo: null,
            noControl: false,
            noControlReason: null,
            noControlNotes: null,
        };
    },

    getEmptyMRDPoint() {
        return {
            pointStartTime: null,
            birds: [],
            noBirds: false,
            otherBirds: null,
            enticement: null,
            noCounting: false,
            noCountingReason: null,
        };
    },

    getEmptyMCYPosition() {
        return {
            position: null,
            notAudited: false,
            notAuditedReason: null,
            notAuditedNotes: "",
            control1: null,
            control2: null,
            habitatData: null,
            habitatDataToChange: null,
        };
    },

    getEmptyMCYControl() {
        return {
            controlDate: null,
            nestingCriteria: null,
            noBirds: false,
            controlMethod: null,
            count: null,
            young: null,
            youngSize: null,
            breedingResult: null,
            polygamousBehavior: null,
            polygamousDescription: null,
            noControl: false,
            noControlReason: null,
            noControlNotes: null,
        };
    },

    getEmptyMCYHabitatData() {
        return {
            habitatType: null,
            otherHabitat: null,
            surroundings: null,
            dominantVegetation: null,
            water: null,
        };
    },

    getEmptyMMCPosition() {
        return {
            position: null,
            notAudited: false,
            notAuditedReason: null,
            notAuditedNotes: "",
            control1: null,
            control2: null,
            habitatData: null,
            habitatDataToChange: null,
            birds: [],
            others: [],
            noBirds: false,
            noOthers: false,
        };
    },

    getEmptyMMCControl() {
        return {
            noControl: false,
            noControlReason: null,
            noControlNotes: null,
            controlDate: null,
            notes: null,
            additionalObservers: null,
            startTime: null,
            endTime: null,
            controlMethod: null,
        };
    },

    getEmptyMMCHabitatData() {
        return {
            habitatType: null,
            otherHabitatType: null,
            location: null,
            otherColonyLocation: null,
            plantHeight: null,
            treeArea: null,
            bushesArea: null,
        };
    },

    getEmptyDanger() {
        return {
            type: null,
            notes: "",
        };
    },
};
