<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing" v-model:step="step"
                      v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :someGeobjectWasAudited="someGeobjectWasAudited"
                      :validationCorrect="validationCorrect" @goTo2Step="validateFirstStep"
                      :onlyStepTwo="!isCaptain">
        <template v-slot:formMap>
            <TransectFormMap v-model:form="formForMap" />
        </template>
        <template v-slot:formObservations>
            <FormMZPMObservations v-model:form="form" ref="observationsView"/>
        </template>
    </FormViewTemplate>
</template>

<script>
    import {computed} from "vue";
    import {
        getList as getDict,
        getForm,
    } from "@/swagger/vue-api-client";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import TransectFormMap from "@/components/transectForm/TransectFormMap.vue";
    import {DateUtils} from "@/utils/DateUtils";
    import FormMZPMObservations from "./components/FormMZPMObservations.vue";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "FormMZPMView",

        components: {
            FormMZPMObservations,
            TransectFormMap,
            FormViewTemplate,
        },

        data() {
            return {
                programCode: "MZPM",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                formForMap: null,
                transectInfo: [],
                step: 1,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                transectInfo: computed(() => this.transectInfo),
                controlTimeFrame: computed(() => this.form.control),
                readonly: computed(() => this.readonly),
            };
        },

        beforeMount() {
            if (this.isCaptain) {
                if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
                this.getCaptainForm();
            } else {
                this.step = 2;
                let query = "?step=2";
                if (this.$route.query.controlCode != null) query += `&controlCode=${this.$route.query.controlCode}`;
                window.history.pushState(null, "", this.$route.path + query);
                this.getObserverForm();
            }
        },

        methods: {
            getCaptainForm() {
                getForm({program: this.programCode, controlCode: "K1C", formId: this.$route.params.id})
                    .then((response) => {
                        this.form = response.data;
                        this.flattenCaptainData();
                        // we want only shallow copy
                        this.formForMap = {...this.form};
                        this.formForMap.formTransects = this.form.formTransects.filter(
                            (transect) => transect.transectId != null && transect.transectId !== 0);
                        console.log(this.form);
                        this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                        return getDict({programCode: this.programCode, formId: this.$route.params.id});
                    })
                    .then((response) => {
                        this.dicts = response.data;
                        console.log(this.dicts);
                        this.loaded = true;
                    })
                    .catch((err) => {
                        if (this.readonly) {
                            this.loaded = true;
                        } else if (err.response.status === 403) {
                            this.$router.push({name: "forbidden"});
                            this.loaded = false;
                        } else {
                            this.loaded = false;
                        }
                    });
            },
            getObserverForm() {
                getForm({
                    program: this.programCode,
                    controlCode: this.$route.query.controlCode,
                    formId: this.$route.params.id,
                })
                    .then((response) => {
                        this.form = response.data;
                        this.flattenObserverData();
                        // we want only shallow copy
                        this.formForMap = {...this.form};
                        this.formForMap.formTransects = this.form.transectInfo;
                        this.transectInfo = this.form.transectInfo;
                        console.log(this.form);
                        this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                        return getDict({programCode: this.programCode, formId: this.$route.params.id});
                    })
                    .then((response) => {
                        this.dicts = response.data;
                        console.log(this.dicts);
                        this.loaded = true;
                    })
                    .catch((err) => {
                        if (this.readonly) {
                            this.loaded = true;
                        } else if (err.response.status === 403) {
                            this.$router.push({name: "forbidden"});
                            this.loaded = false;
                        } else {
                            this.loaded = false;
                        }
                    });
            },
            flattenCaptainData() {
                for (let i = 0; i < this.form.formTransects.length; i += 1) {
                    const transect = this.form.formTransects[i];
                    if (transect.transect != null) {
                        transect.transectId = transect.transect.transectId;
                        transect.name = transect.transect.name;
                        transect.transectHistoryId = transect.transect.transectHistoryId;
                        transect.line = transect.transect.line;
                        transect.ordinal = transect.transect.ordinal;
                        transect.accepted = transect.transect.accepted;
                    } else {
                        transect.transectId = null;
                    }
                    delete transect.transect;
                }
            },
            flattenObserverData() {
                for (let i = 0; i < this.form.transectInfo.length; i += 1) {
                    const transect = this.form.transectInfo[i];
                    if (transect.transect != null) {
                        transect.transectId = transect.transect.transectId;
                        transect.name = transect.transect.name;
                        transect.transectHistoryId = transect.transect.transectHistoryId;
                        transect.line = transect.transect.line;
                        transect.ordinal = transect.transect.ordinal;
                        transect.accepted = transect.transect.accepted;
                    } else {
                        transect.transectId = 0;
                    }
                    delete transect.transect;
                }
            },
            addErrorToast(detailMessage) {
                console.log("add toast");
                this.$toast.add({
                    severity: "error",
                    summary: "Błąd",
                    detail: detailMessage,
                    life: 5000,
                });
            },
            getTransectsRequest() {
                const allTransectsRequest = [];
                this.form.formTransects.forEach((transect) => {
                    const transectRequest = {...transect};
                    delete transectRequest.habitatDataToChange;
                    delete transectRequest.id;
                    delete transectRequest.line;
                    delete transectRequest.name;
                    delete transectRequest.transectHistoryId;
                    allTransectsRequest.push(transectRequest);
                });
                return allTransectsRequest;
            },
            getFormRequest() {
                const request = ValidateUtils.getFormRequest(this.form, "MZPM-" + (this.isCaptain ? "captain" : "observer"), "formTransects");
                if (!this.form.noFormControl) {
                    if (this.isCaptain) {
                        this.form.controlDate = DateUtils.formatFormDate(this.form.controlDate);
                        this.form.formTransects.forEach((transect) => {
                            transect.startTime1 = DateUtils.formatFormTime(transect.startTime1);
                        });
                        request.notes = this.form.captainNotes;
                        request.formTransects = this.getTransectsRequest();
                    } else {
                        request.notes = this.form.observerNotes;
                        request.formBirds = this.form.formBirds;
                    }
                    ValidateUtils.copyFieldValues(this.form, request, ["controlDate", "shipName", "additionalObservers"]);
                }
                return request;
            },
            validateCaptainTransect(t) {
                let isCorrect = true;
                if (t.notAudited === true) {
                    if (t.notAuditedReason == null || t.notAuditedReason === "") {
                        isCorrect = false;
                        this.addErrorToast("Transekt " + t.name + " nieskontrolowany. Podaj powód.");
                    }
                } else if (t.startTime1 == null || t.depth == null || t.seaState == null || t.rain == null
                    || t.clouds == null || t.startTime1 === "" || t.depth === "") {
                    isCorrect = false;
                }
                return isCorrect;
            },
            validateData() {
                let isCorrect = ValidateUtils.validateNoFormControl(this.form);
                if (isCorrect && !this.form.noFormControl) {
                    const s = this.form;
                    if (this.isCaptain) {
                        if (s.controlDate == null || s.shipName == null || s.controlDate === "" || s.shipName === "") {
                            isCorrect = false;
                        }
                        isCorrect = ValidateUtils.validateIsDateInRange(s.controlDate, this.form.control.controlStart, this.form.control.controlEnd) && isCorrect;
                        s.formTransects.forEach((t) => {
                            isCorrect = this.validateCaptainTransect(t) && isCorrect;
                        });
                    } else {
                        console.log("observer validation");
                    }
                }
                this.validationCorrect = isCorrect;
                this.request = this.getFormRequest();
                console.log(this.request);
                return isCorrect;
            },
            validateFirstStep() {
                const errors = ValidateUtils.validateNotAudited(this.form.formTransects, "Transekt");
                this.addErrorToasts(errors);
                return errors.length === 0;
            },
            addErrorToasts(errors) {
                errors.forEach((message) => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: message,
                        life: 5000,
                    });
                });
            },
        },

        computed: {
            someGeobjectWasAudited() {
                if (!this.isCaptain) return true;
                return ValidateUtils.someGeobjectWasAudited(this.form.formTransects, this.form.noFormControl);
            },
            isCaptain() {
                return (this.$route.query.controlCode === "K1C");
            },
            readonly() {
                return SystemRole.isObserver() && this.form.state === "APPROVED";
            },
        },
    };
</script>

<style lang="scss">
</style>
