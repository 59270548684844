<template>
    <div class="flex column-gap-2 flex-direction-column">
        <CustomSelectOne v-if="showTypeInput" label="Rodzaj kontroli" v-model="control.control" name="controlType"
                         :items="dicts.controlType" class="flex-1 w-0 max-width" :disabled="!editing"
                         :showErrors="showErrorMessages" required/>
        <CustomCalendar v-if="!hideCalendar" :label="controlDateLabel" class="flex-1 w-0 max-width" v-model="control[controlDateKey]"
                        :disabled="!editing" name="controlDate" :showErrors="showErrorMessages" required
                        :customErrorMessage="getDateErrorMessage(control[controlDateKey],index)"/>
        <CustomTimePicker :label="startTimeLabel" class="flex-1 w-0 max-width" v-model="control[startTimeKey]"
                          :disabled="!editing" name="startTime" :showErrors="showErrorMessages" required/>
        <CustomTimePicker :label="endTimeLabel" class="flex-1 w-0 max-width" v-model="control[endTimeKey]"
                          :disabled="!editing" name="endTime" required :showErrors="showErrorMessages"
                          :customErrorMessage="getTimeErrorMessage(control[endTimeKey])"/>
    </div>
</template>

<script>
    import {DateUtils} from "@/utils/DateUtils";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import CustomTimePicker from "@/components/form/CustomTimePicker.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";

    export default {
        name: "ControlTypeAndDates",

        components: {
            CustomSelectOne,
            CustomCalendar,
            CustomTimePicker,
        },

        props: {
            modelValue: {
                type: Object,
            },
            showTypeInput: {
                type: Boolean,
                default: false,
            },
            controlDateLabel: {
                type: String,
                default: "Data kontroli",
            },
            startTimeLabel: {
                type: String,
                default: "Godzina rozpoczęcia",
            },
            endTimeLabel: {
                type: String,
                default: "Godzina zakończenia",
            },
            controlDateKey: {
                type: String,
                default: "controlDate",
            },
            startTimeKey: {
                type: String,
                default: "startTime",
            },
            endTimeKey: {
                type: String,
                default: "endTime",
            },
            hideCalendar: {
                type: Boolean,
                default: false,
            },
            endTimeCanBeEarlier: {
                type: Boolean,
                default: false,
            },
            controlTypeDates: {
                type: Boolean,
                default: false,
            },
            index: null,
        },

        inject: ["editing", "dicts", "showErrorMessages", "controlTimeFrame", "readonly"],

        emits: ["update:modelValue"],

        data() {
            return {
                showWrongTimeMessage: false,
            };
        },

        methods: {
            getRequiredMessageControls(value) {
                if (this.showErrorMessages && (value === null || value === "")) {
                    return this.$t("message.validations.required");
                }
                return "";
            },
            getTimeErrorMessage(value) {
                if (this.endTimeCanBeEarlier) return "";
                if (this.showWrongTimeMessage && value !== null && value !== "") {
                    return this.$t("message.validations.afterTime");
                }
                return this.getRequiredMessageControls(value);
            },
            getDateErrorMessage(value, i) {
                if (this.readonly) return "";
                if (this.control.showErrorControlDate && value !== null && value !== "") {
                    return this.$t("message.validations.afterDate");
                }
                if (!this.controlTypeDates && !ValidateUtils.validateIsDateInRange(value, this.controlTimeFrame.controlStart, this.controlTimeFrame.controlEnd)) {
                    return "Data musi być z przedziału " + this.controlTimeFrame.controlStart + " - "
                        + this.controlTimeFrame.controlEnd;
                }
                if (this.controlTypeDates && !ValidateUtils.validateIsDateInRange(value, this.dicts.controlType[i]?.controlStart, this.dicts.controlType[i]?.controlEnd)) {
                    return "Data musi być z przedziału " + this.dicts.controlType[i].controlStart + " - "
                        + this.dicts.controlType[i].controlEnd;
                }
                return this.getRequiredMessageControls(value);
            },
        },

        computed: {
            control: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            control: {
                handler(v) {
                    if (v[this.startTimeKey] == null) v[this.startTimeKey] = "";
                    if (v[this.endTimeKey] == null) v[this.endTimeKey] = "";
                    if (v[this.startTimeKey] !== "" && v[this.endTimeKey] !== "") {
                        this.showWrongTimeMessage = DateUtils.getTimeOnly(v[this.startTimeKey]) > DateUtils.getTimeOnly(v[this.endTimeKey]);
                    }
                    // this.$emit("update:modelValue", v); i'm think it's not necessary here and also make some troubles in MLS form with transects change
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 850px) {
        .flex-direction-column {
            flex-direction: column;
            .max-width {
                width: 100% !important;
            }
        }
    }
</style>
