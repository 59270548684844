<template>
    <FormObservationsTemplate ref="observationsTemplate" geobjectType="transekt" v-model:geobject="transect" :geobjects="transects"
                              v-model:additionalInformation="internalForm" additionalInformationInMain
                              v-model:dangers="internalForm.dangers">
        <template v-slot:mainData>
            <CustomCalendar label="Data liczenia" class="w-4 mb-4" v-model="internalForm.controlDate"
                            :disabled="!editing" name="controlDate" :showErrors="showErrorMessages" required
                            :customErrorMessage="getDateErrorMessage(internalForm.controlDate)"
                            style="min-width: 280px;" />
        </template>
        <template v-slot:selectGeobject>
            <SelectTransect :transects="transects" :field="form.field.shape" v-model:step="internalStep"
                            v-model:activeIndex="activeIndex" v-model:transect="transect"
                            :segmentsInTransects="segmentsInTransects"/>
        </template>
        <template v-slot:controlData>
            <ControlTypeAndDates v-model="transect" hideCalendar/>
            <div class="flex column-gap-2 flex-direction-column">
                <CustomInputNumber label="L. zwierząt zgryzających (do 300 m od tr.)" name="bitingNumber"
                                   class="w-4 max-width" v-model="transect.bitingNumber" :disabled="!editing"
                                   required :showErrors="showErrorMessages"/>
                <CustomInputText label="Gatunek/-ki zwierząt zgryzających" v-model="transect.bitingSpecies"
                                 name="bitingSpecies" :required="transect.bitingNumber > 0"
                                 :showErrors="showErrorMessages && transect.bitingNumber > 0" class="w-4 max-width"
                                 :disabled="!editing" />
                <CustomInputNumber label="Widoczność w % (do 300m po obu str. tr.)" name="visibility"
                                   class="w-4 max-width" v-model="transect.visibility" :disabled="!editing"
                                   required :showErrors="showErrorMessages" :min="0" :max="100"/>
            </div>
        </template>
        <template v-slot:birdsData>
            <FormBirdsMLS v-model:birds="transect.birds" v-model:noBirds="transect.noBirds"/>
        </template>
        <template v-slot:mammalsData>
            <FormMammalsMLS v-model:mammals="transect.mammals" v-model:noMammals="transect.noMammals"/>
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import SelectTransect from "@/components/transectForm/selectTransect/SelectTransect.vue";
    import ControlTypeAndDates from "@/components/formSharedComponents/ControlTypeAndDates.vue";
    import FormBirdsMLS from "@/views/formMLS/components/FormBirdsMLS.vue";
    import FormMammalsMLS from "@/views/formMLS/components/FormMammalsMLS.vue";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "FormMLSObservations",

        components: {
            CustomInputText,
            CustomInputNumber,
            FormObservationsTemplate,
            FormMammalsMLS,
            FormBirdsMLS,
            ControlTypeAndDates,
            SelectTransect,
            CustomCalendar,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
            step: {
                type: Number,
                required: true,
            },
        },

        inject: ["editing", "showErrorMessages", "controlTimeFrame"],

        data() {
            return {
                transects: [],
                transect: _.cloneDeep(EmptyStructures.getEmptyTransect()),
                activeIndex: 0,
            };
        },

        emits: ["update:step", "update:form"],

        beforeMount() {
            this.transects = this.form.formTransects;
        },

        methods: {
            getDateErrorMessage(value) {
                if (this.internalForm.showErrorControlDate && value !== null && value !== "") {
                    return this.$t("message.validations.afterDate");
                }
                if (value && !ValidateUtils.validateIsDateInRange(value, this.controlTimeFrame.controlStart, this.controlTimeFrame.controlEnd)) {
                    return "Data musi być z przedziału " + this.controlTimeFrame.controlStart + " - "
                        + this.controlTimeFrame.controlEnd;
                }
                return this.getRequiredMessageControls(value);
            },
            getRequiredMessageControls(value) {
                if (this.showErrorMessages && (value === null || value === "")) {
                    return this.$t("message.validations.required");
                }
                return "";
            },
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            segmentsInTransects() {
                const first4 = this.transects.filter((t) => t.ordinal <= 4);
                return [first4];
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
            activeIndex(value) { this.transect = this.transects[value]; },
        },
    };
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1000px) {
    .flex-direction-column {
        flex-direction: column;
        .max-width {
            width: 100% !important;
        }
    }
}
</style>
