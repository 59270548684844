<template>
    <div class="p-3">
        <h2>Szablon e-mail</h2>
        <CustomSelectOne name="emailTemplate" label="Szablon e-mail" itemLabel="subject" v-model="emailTemplate" :items="templates"
                         class="w-6 pr-3" />
        <div class="flex mt-5 gap-5 flex-direction-column">
            <div v-if="emailTemplate" class="w-6 max-width">
                <CustomInputText v-model="emailTemplate.subject" label="Tytuł" name="tytuł" rules="required"/>
                <Editor editorStyle="height: 320px" v-model="emailTemplate.template" class="flex-1">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <!-- eslint-disable-next-line -->
                            <select class="ql-header">
                                <option value="1">Heading</option>
                                <option value="2">Subheading</option>
                                <option value="0">Normal</option>
                            </select>
                            <button type="button" class="ql-bold"></button>
                            <button type="button" class="ql-italic"></button>
                            <button type="button" class="ql-underline"></button>
                        </span>
                        <span class="ql-formats">
                            <button type="button" class="ql-list" value="ordered"></button>
                            <button type="button" class="ql-list" value="bullet"></button>
                        </span>
                        <span class="ql-formats">
                            <button type="button" class="ql-link"></button>
                        </span>
                    </template>
                </Editor>
                <div class="flex gap-3">
                    <CustomButton label="Zapisz" icon="pi pi-pencil" class="mt-3"
                                  bgColor="var(--secondary-color)" color="var(--surface-a)"
                                  @click="saveTemplate" />
                    <CustomButton label="Anuluj" class="mt-3"
                                  @click="goBack"/>
                </div>
            </div>
            <div v-if="emailTemplate" class="w-6">
                <h2 class="mt-1">Dostępne znaczniki</h2>
                <ul v-if="loadTags" class="list-none p-0 m-0" >
                    <li v-for="tag in tags" :key="JSON.stringify(tag)" class="mb-2">
                        {{ tag.mark }} - {{ tag.explanation }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import Editor from "primevue/editor";
    import {
        allTemplates,
        updateTemplate,
    } from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";

    export default {
        name: "EmailTemplate",

        components: {
            CustomInputText,
            Editor,
            CustomButton,
            CustomSelectOne,
        },

        data() {
            return {
                loadTags: false,
                tags: [],
                templates: [],
                emailTemplate: null,
            };
        },

        beforeMount() {
            allTemplates().then((response) => {
                this.templates = response.data;
            });
            this.getTags();
        },

        methods: {
            saveTemplate() {
                updateTemplate({body: this.emailTemplate})
                    .then(() => {
                        this.$toast.add({
                            severity: "success",
                            summary: "Zapisano",
                            life: 3000,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            life: 3000,
                        });
                    });
            },
            goBack() {
                this.$router.go(-1);
            },
            getTags() {
                // getTagsList()
                //     .then((response) => {
                //         this.tags = response.data;
                //         this.loadTags = true;
                //     });

                // only for mockup
                this.tags = [
                    {mark: "{$userName}", explanation: "imię i nazwisko adresata"},
                    {mark: "{$programEdition}", explanation: "nazwa programu"},
                ];
                this.loadTags = true;
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 800px) {
        .flex-direction-column {
            flex-direction: column;
            align-items: start !important;
            .max-width {
                width: 100% !important;
            }
        }
    }
</style>
