<template>
    <FormObservationsTemplate ref="observationsTemplate" geobjectType="transekt" v-model:geobject="transect" :geobjects="transects"
                              v-model:additionalInformation="internalForm" additionalInformationInMain
                              v-model:dangers="internalForm.dangers">
        <template v-slot:mainData>
            <TransectFormMainData v-model:form="internalForm" :numberOfTransects="1" required/>
        </template>
        <template v-slot:selectGeobject>
            <SelectTransect :transects="transects" :field="form.field.shape" v-model:step="internalStep"
                            v-model:activeIndex="activeIndex" :segmentsInTransects="segmentsInTransects"/>
        </template>
        <template v-slot:separateBirdsData>
            <FormBirds v-model:noBirds="transect.noBirds" v-model:birds="transect.formBirds" />
        </template>
        <template v-slot:controlHabitatData>
            <div class="flex gap-4 justify-content-between mx-3">
                <p>
                    Ostatnia zmiana danych siedliskowych: {{ habitatData?.controlDate }}
                </p>
                <CustomButton v-if="!editHabitatData" label="Edytuj dane siedliskowe"
                              @click="enableEditHabitatData" />
                <CustomButton v-if="editHabitatData" label="Cofnij zmiany danych siedliskowych"
                              @click="undoHabitatChanges" />
            </div>
            <HabitatDataMWOT v-model:habitatData="habitatData" required :editable="editHabitatData" />
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import TransectFormMainData from "@/components/transectForm/TransectFormMainData.vue";
    import SelectTransect from "@/components/transectForm/selectTransect/SelectTransect.vue";
    import FormBirds from "@/components/formBirds/FormBirds.vue";
    import HabitatDataMWOT from "./HabitatDataMWOT.vue";
    import CustomButton from "@/components/form/CustomButton";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";

    export default {
        name: "FormMWOTObservations",

        components: {
            FormObservationsTemplate,
            SelectTransect,
            TransectFormMainData,
            FormBirds,
            HabitatDataMWOT,
            CustomButton,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
            step: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                transects: [],
                transect: _.cloneDeep(EmptyStructures.getEmptyTransect()),
                activeIndex: null,
                editHabitatData: false,
                habitatData: null,
            };
        },

        inject: ["editing"],

        emits: ["update:step", "update:form"],

        beforeMount() {
            this.transects = this.form.formTransects;
            if (this.transects.length > 0) {
                this.transects.forEach((t, i) => {
                    if (!(t.notAudited === true) && this.activeIndex == null) this.activeIndex = i;
                });
            }
        },

        methods: {
            undoHabitatChanges() {
                if (this.transect.habitatData != null
                    && !(_.isEqual(this.transect.habitatData, EmptyStructures.getEmptyMWOTHabitatData()))) {
                    this.habitatData = this.transect.habitatData;
                    this.editHabitatData = false;
                } else {
                    this.habitatData = EmptyStructures.getEmptyMWOTHabitatData();
                    this.editHabitatData = true;
                }
            },
            enableEditHabitatData() {
                this.editHabitatData = true;
                if (this.transect.habitatData != null) {
                    this.habitatData = _.cloneDeep(this.transect.habitatData);
                } else {
                    this.habitatData = _.cloneDeep(EmptyStructures.getEmptyMWOTHabitatData());
                }
            },
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            segmentsInTransects() {
                const first5 = this.transects.filter((t) => t.ordinal <= 5);
                return [first5];
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            habitatData: {
                handler(value) {
                    if (this.editHabitatData) this.transect.habitatDataToChange = value;
                },
                deep: true,
            },
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
            activeIndex(value) {
                this.transect = this.transects[value];
                if (this.transect.habitatDataToChange != null) {
                    this.habitatData = _.cloneDeep(this.transect.habitatDataToChange);
                    this.editHabitatData = true;
                } else if (this.transect.habitatData != null
                    && !(_.isEqual(this.transect.habitatData, EmptyStructures.getEmptyMWOTHabitatData()))) {
                    this.habitatData = _.cloneDeep(this.transect.habitatData);
                    this.editHabitatData = false;
                } else if (this.transect.habitatData == null
                    || _.isEqual(this.transect.habitatData, EmptyStructures.getEmptyMWOTHabitatData())) {
                    this.habitatData = _.cloneDeep(EmptyStructures.getEmptyMWOTHabitatData());
                    this.editHabitatData = true;
                }
            },
        },
    };
</script>

<style lang="scss">
</style>
