<template>
    <div v-if="loaded" class="p-3 w-6 max-width">
        <div>
            <p>
                <b>Imię: </b>{{ user.firstName }}
            </p>
            <p>
                <b>Nazwisko: </b>{{ user.lastName }}
            </p>
            <p>
                <b>E-mail: </b>{{ user.email }}
            </p>
            <p>
                <b>Role: </b> {{ computedSystemRoles(user.systemRole) }}
            </p>
            <p>
                <b>Aktywny: </b> {{ user.enabled ? "Tak" : "Nie" }}
            </p>
            <p v-if="Object.keys(user.observer).length > 0">
                <b>Programy użytkownika (Obserwator): </b>
                <ul class="list-none p-0">
                    <li v-for="pr in Object.keys(user.observer)" :key="pr"
                        class="mb-2">
                        {{ pr }} <br>
                        ({{ user.observer[pr].join(", ") }});
                    </li>
                </ul>
            </p>
            <p v-if="Object.keys(user.regional).length > 0">
                <b>Programy użytkownika (Koordynator regionalny): </b>
                <ul class="list-none p-0">
                    <li v-for="pr in Object.keys(user.regional)" :key="pr"
                        class="mb-2">
                        {{ pr }} <br>
                        ({{ user.regional[pr].join(", ") }});
                    </li>
                </ul>
            </p>
            <p v-if="Object.keys(user.national).length > 0">
                <b>Programy użytkownika (Koordynator krajowy): </b>
                <ul class="list-none p-0">
                    <li v-for="pr in Object.keys(user.national)" :key="pr"
                        class="mb-2">
                        {{ pr }} <br>
                        ({{ user.national[pr].join(", ") }});
                    </li>
                </ul>
            </p>
        </div>
        <div class="flex justify-content-end mt-6">
            <CustomButton label="Wyjdź" @click="goBack" />
        </div>
    </div>
</template>

<script>
    import {
        getUser,
    } from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "UserDetailsView",

        components: {
            CustomButton,
        },

        data() {
            return {
                user: {
                    id: null,
                    firstName: null,
                    lastName: null,
                    email: null,
                    password: null,
                    systemRole: [],
                    regionalPrograms: [],
                    nationalPrograms: [],
                    observer: null,
                    enabled: false,
                },
                loaded: false,
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                getUser({id: this.$route.params.id}).then((response) => {
                    this.user = response.data;
                    this.loaded = true;
                });
            } else {
                this.loaded = true;
            }
        },

        methods: {
            goBack() {
                this.$router.go(-1);
            },
            computedSystemRoles(roles) {
                let userRoles = "";
                if (roles.includes("ADMIN")) userRoles += "Administrator, ";
                if (roles.includes("REGIONAL_COORDINATOR")) userRoles += "Koordynator regionalny, ";
                if (roles.includes("NATIONAL_COORDINATOR")) userRoles += "Koordynator krajowy, ";
                if (roles.includes("MAIN_COORDINATOR")) userRoles += "Koordynator główny, ";
                if (roles.includes("OBSERVER")) userRoles += "Obserwator, ";
                return userRoles.replace(/,\s*$/, "");
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 800px) {
        .max-width {
            width: 100% !important;
        }
    }
</style>
