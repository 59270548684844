<template>
    <div class="custom-select-one field relative" ref="customSelectOne"
         @mouseenter="showFullLabel" @mouseout="hideFullLabel"
         @focus="showFullLabel" @blur="hideFullLabel" @click="hideFullLabel">
        <span class="p-float-label" :style="computedWidth()">
            <Dropdown :id="name" :options="internalItems"
                      :optionLabel="itemLabel"
                      :optionValue="itemValue" :disabled="disabled"
                      @change="onChange" :rules="rules" :filter="filter"
                      v-model="internalValue" :placeholder="internalValue ? '' : label"
                      :class="computedClasses" :showClear="showClear" :optionDisabled="optionDisabled"
                      :virtual-scroller-options="scroll" @show="offTabindexOnFilter" :autoFilterFocus="filter"
                      :panelClass="computedPanelClass()" @focus="setWidth" resetFilterOnHide
                      ref="inputRef" @filter="selectFirstFilteredOnEnter">
                <template #option="slotProps" v-if="tooltip">
                    <div @mouseenter="showDescription(slotProps.index)" @mouseout="hideDescription"
                         @focus="showDescription(slotProps.index)" @blur="hideDescription" @click="hideDescription">
                        {{ slotProps.option.code }}
                    </div>
                </template>
                <template #footer>
                    <div v-show="showCodeDescription && codeDescription !== '' && codeDescription !== null"
                         class="code-description-tooltip">
                        {{ codeDescription }}
                    </div>
                </template>
            </Dropdown>
            <CustomLabel :name="name" :label="label" :required="required" :disabled="disabled"
                         :class="{'label-filled': internalValue != null && internalValue !== ''}"
                         :longLabelSelect="longLabelSelect" ref="labelRef" />
        </span>
        <div v-show="showLabel && isHiddenLabel" class="label-tooltip">
            <div class="label-tooltip-arrow" style="bottom:0;left:50%" data-pc-section="arrow"></div>
            <div class="label-tooltip-text">{{ label }}</div>
        </div>
        <CustomValidationMessage :errorMessage="errorMessage" />
        <CustomValidationMessage :errorMessage="customErrorMessage" />
        <CustomValidationMessage v-if="showRequiredError" :errorMessage="$t('message.validations.required')" />
        <!-- <div v-show="showCodeDescription && codeDescription !== '' && codeDescription !== null"
             class="absolute code-description-tooltip">
            {{ codeDescription }}
        </div> -->
    </div>
</template>

<script>
    import Dropdown from "primevue/dropdown";
    import {useField} from "vee-validate";
    import CustomValidationMessage from "./CustomValidationMessage";
    import CustomLabel from "./CustomLabel";

    export default {
        name: "CustomSelectOne",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            itemLabel: {
                default: "label",
            },
            itemValue: {
                type: String,
                default: "",
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            emptyValue: {
                type: Boolean,
                default: false,
            },
            items: {
                type: Array,
            },
            modelValue: null,
            rules: undefined,
            filter: {
                type: Boolean,
                default: true,
            },
            customErrorMessage: {
                type: String,
                default: "",
            },
            customErrorWithoutMessage: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
            showErrors: {
                type: Boolean,
                default: false,
            },
            tooltip: {
                type: Boolean,
                default: false,
            },
            showClear: {
                type: Boolean,
                default: true,
            },
            short: {
                type: Boolean,
                default: false,
            },
            width: {
                type: String,
                default: null,
            },
            optionDisabled: {
                type: String,
                default: "disabled",
            },
            virtualScrolling: {
                type: Boolean,
                default: false,
            },
            longLabelSelect: {
                type: Boolean,
                default: false,
            },
            checkFilteredOptionOnEnter: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["update:modelValue", "change"],

        data() {
            return {
                internalItems: this.items,
                selectedValue: null,
                showCodeDescription: false,
                codeDescription: "",
                isHiddenLabel: false,
                showLabel: false,
            };
        },

        mounted() {
            if (this.emptyValue) {
                this.internalItems = [{value: null, label: this.$t("message.other.emptyValue")}, ...this.items];
            }
            if ((!this.items || this.items?.length === 0) && this.internalValue) {
                this.internalItems = [this.internalValue];
            }
            setTimeout(() => {
                if (this.$refs.labelRef && this.$refs.inputRef) {
                    this.isHiddenLabel = this.$refs.labelRef.$el.clientWidth / this.$refs.inputRef.$el.clientWidth >= 0.84;
                }
            }, 1000);
        },

        watch: {
            items(newVal) {
                this.internalItems = [...newVal];
            },
            internalValue: {
                handler(value) {
                    if ((!this.items || this.items?.length === 0) && value) {
                        this.internalItems = [value];
                    }
                },
                deep: true,
            },
        },

        computed: {
            internalValue: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            computedClasses() {
                if (this.customErrorMessage !== "" || this.customErrorWithoutMessage || this.showRequiredError) {
                    return "p-invalid";
                }
                return "";
            },
            scroll() {
                return this.virtualScrolling ? { itemSize: 36 } : undefined;
            },
            showRequiredError() {
                return this.showErrors && (this.internalValue == null || this.internalValue === "");
            },
        },

        methods: {
            selectFirstFilteredOnEnter(ev) {
                if (this.checkFilteredOptionOnEnter) {
                    let filteredOptions = [];
                    filteredOptions = this.internalItems.filter((i) => i.toLowerCase().includes(ev.value.toLowerCase()));
                    document.querySelector(".p-dropdown-filter.p-inputtext").addEventListener("keydown", (e) => {
                        if (e.key === "Enter") {
                            if (filteredOptions.length === 1) {
                                this.internalValue = filteredOptions[0];
                            }
                            if (filteredOptions.length > 1) {
                                const options = document.querySelectorAll("li.p-dropdown-item");
                                options.forEach((o) => {
                                    if (o.getAttribute("data-p-focused") === "true") {
                                        filteredOptions.forEach((f) => {
                                            if (f.toLowerCase() === o.getAttribute("aria-label").toLowerCase()) {
                                                this.internalValue = f;
                                            }
                                        });
                                    } else {
                                        this.internalValue = filteredOptions[0];
                                    }
                                });
                            }
                            document.querySelector(`#${this.name} .p-inputtext`).focus(); // works as long as there is only one on page with checkFilteredOptionOnEnter, but maybe that's enough
                        }
                    });
                }
            },
            setWidth() {
                if (this.short) {
                    const width = document.activeElement.offsetWidth;
                    document.activeElement.style.width = `${width}px`;
                }
            },
            offTabindexOnFilter() {
                const filter = document.querySelector(".p-dropdown-filter");
                if (filter) {
                    filter.setAttribute("tabindex", "-1");
                }
            },
            onChange(val) {
                if (val && val.value) {
                    this.handleChange(val.value);
                    this.$emit("change", val.value);
                } else {
                    this.handleChange(undefined);
                    this.$emit("change", undefined);
                }
            },
            showDescription(id) {
                const tooltip = document.querySelector(".code-description-tooltip");
                window.addEventListener("mousemove", (e) => {
                    const x = e.clientX;
                    const y = e.clientY;
                    tooltip.style.top = (y + 20) + "px";
                    tooltip.style.left = (x + 20) + "px";
                });
                this.showCodeDescription = true;
                this.computedCodeDescription(id);
            },
            hideDescription() {
                this.codeDescription = "";
                this.showCodeDescription = false;
            },
            showFullLabel() {
                this.showLabel = true;
            },
            hideFullLabel() {
                this.showLabel = false;
            },
            computedCodeDescription(id) {
                this.codeDescription = this.items[id]?.label;
            },
            computedWidth() {
                if (this.width === null) return "";
                return "width: " + this.width;
            },
            computedPanelClass() {
                let panelClass = "";
                if (this.short) panelClass += "short-panel ";
                if (this.tooltip) panelClass += "tooltip-panel ";
                return panelClass;
            },
        },

        components: {Dropdown, CustomValidationMessage, CustomLabel},

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

    };
</script>

<style lang="scss" scoped>
    @import "../../../assets/theme/mytheme/variables";
    .custom-select-one {
        .p-dropdown {
            width: 100%;
        }
        .p-dropdown-trigger {
            border-left: 1px solid #ced4da;
        }
    }
    .code-description-tooltip {
        z-index: 999999;
        // background-color: var(--surface-0);
        color: black;
        // top: 0;
        // left: calc(100% + 4px);
        width: 100%;
        max-width: 300px;
        padding: 4px 8px;
        border-radius: 4px;
    }
    .label-tooltip {
        display: inline-block;
        pointer-events: none;
        opacity: 1.056;
        z-index: 1000;
        padding: .25em 0;
        position: absolute;
        max-width: 12.5rem;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-100%);
        &-text {
            background: var(--text-color);
            color: #ffffff;
            padding: 0.5rem 0.5rem;
            -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
            border-radius: 4px;
        }
        &-arrow {
            margin-left: -0.25rem;
            border-top-color: var(--text-color);
            position: absolute;
            scale: 2;
            width: 0;
            height: 0;
            border-left: 0.25em solid transparent;
            border-right: 0.25em solid transparent;
            border-top: 0.25em solid;
        }
    }
</style>
<style lang="scss">
    .custom-select-one {
        .p-dropdown-trigger {
            border-left: 1px solid #ced4da;
        }
        .p-dropdown .p-dropdown-label.p-placeholder {
            color: transparent;
        }
        .p-dropdown .p-dropdown-label {
            position: relative;
            top: 2px;
        }
    }
    .short-panel.p-dropdown-panel {
        max-width: 500px;
        .p-dropdown-items .p-dropdown-item {
            text-align: left;
            padding: 10px 1rem;
            white-space: pre-wrap;
        }
    }
    .tooltip-panel {
        .p-dropdown-item {
            padding: 0 !important;
            div {
                padding: 10px 1rem;
            }
        }
    }
</style>
