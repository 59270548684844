<!-- eslint-disable -->
<template>
    <DataTable :value="indicators" dataKey="id" lazy :totalRecords="totalRecords"
               :loading="loading" :search-criteria="searchCriteria"
               v-model:editingRows="editingRows" editMode="row"
               @row-edit-save="onRowEditSave"
               @page="onPage" @sort="onPage" removableSort size="small"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink
               LastPageLink CurrentPageReport RowsPerPageDropdown"
               currentPageReportTemplate="Wskaźników na stronie" paginator
               :rows="10" :rowsPerPageOptions="[5,10,20,50]"
               v-model:rows="page.limit" v-model:first="page.offset" v-model:filters="searchCriteria"
               filter-display="menu" @update:filters="search">
        <template #header>
            <AddIndicator :programs="programs" :dicts="dicts" @refreshTable="search" />
        </template>
        <Column v-for="(c,index) in columns" :key="index" v-show="c.visible" :header="c.header" :field="c.field"
                :filterField="c.filterField" :showFilterMatchModes="c.showFilterMatchModes"
                :filterMenuClass="c.filterMenuClass" :sortable="c.sortable">
            <template #body="slotProps">
                <div :class="c.bodyClass(slotProps.data)">
                    {{ c.bodyContent(slotProps.data) }}
                </div>
            </template>
            <template #filter="{filterModel,applyFilter}" v-if="c.filterField != null">
                <CustomIntervalFilter v-if="c.filterType === 'interval'" v-model="filterModel.value"
                                      :range="c.range" v-bind="c.filterOtherAttrs" :maxDigits="99"
                                      @keydown.enter="applyFilter"/>
                <CustomSelectOne v-else-if="c.filterType === 'selectOne'" :label="c.header" :name="c.field"
                                 :items="c.filterItems" :itemLabel="c.filterItemLabel" v-model="filterModel.value"
                                 :itemValue="c.filterItemValue" :class="c.filterClass" v-bind="c.filterOtherAttrs"
                                 @keydown.enter="applyFilter"/>
            </template>
            <template #filterclear="{ filterCallback }">
                <FilterClear v-if="c.filterType !== null" :callback="filterCallback" />
            </template>
            <template #filterapply="{ filterCallback }">
                <FilterApply v-if="c.filterType !== null" :callback="filterCallback" />
            </template>
            <template #editor="{data,field}">
                <CustomInputText v-if="c.editorType === 'inputText'" v-model="data[field]" :name="c.field" />
                <CustomInputNumber v-else-if="c.editorType === 'inputNumber'" v-model="data[field]" :name="c.field" :maxDigits="99" />
                <CustomCalendar v-else-if="c.editorType === 'calendar'" v-model="data[field]" dateFormat="yy"
                                view="year" :name="c.field" />
                <CustomSelectOne v-else-if="c.editorType === 'selectOne' && c.field === 'program'" v-model="data[field]" :items="programs" :emptyValue="c.editorEmptyValue" :name="c.field" filter />
                <CustomSelectOne v-else-if="c.editorType === 'selectOne' && c.field !== 'program'" v-model="data[field]" :items="c.field === 'species' ? dicts.programBirds : (dicts[c.editorItems] ? dicts[c.editorItems] : [])" :itemLabel="c.editorItemLabel" :itemValue="c.editorItemValue" :emptyValue="c.editorEmptyValue" :name="c.field" filter />
            </template>
        </Column>
        <Column :rowEditor="true" style="width: 120px; text-align: center;" />
        <Column style="width: 60px; text-align: center;">
            <template #body="slotProps">
                <CustomButton icon="pi pi-trash" borderColor="transparent" @click="deleteIndicator(slotProps.data)" />
            </template>
        </Column>
    </DataTable>
</template>

<script>
    // import _ from "lodash";
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {
        searchDataIndicator,
        searchDataIndicatorCount,
        deleteDataIndicator,
        getListOfPrograms,
        updateDataIndicator,
    } from "@/swagger/vue-api-client";
    import FilterApply from "@/components/filters/FilterApply.vue";
    import FilterClear from "@/components/filters/FilterClear.vue";
    // import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomIntervalFilter from "@/components/filters/CustomIntervalFilter.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import AddIndicator from "./AddIndicator.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import {FiltersUtils} from "@/utils/FiltersUtils";

    export default {
        name: "IndicatorsTable",

        components: {
            DataTable,
            Column,
            FilterApply,
            FilterClear,
            // CustomMultiSelect,
            CustomSelectOne,
            CustomInputText,
            CustomIntervalFilter,
            CustomButton,
            AddIndicator,
            CustomCalendar,
            CustomInputNumber,
        },

        props: {
            columns: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                indicators: [],
                editingRows: ref([]),
                loading: false,
                totalRecords: 0,
                searchCriteria: SearchCriteria.getClearSearchCriteriaIndicators(),
                page: SearchCriteria.getClearPage(),
                programs: [],
                dicts: {
                    programBirds: [
                        {id: 476, name: "perkoz dwuczuby", code: "PER1"},
                    ],
                },
            };
        },

        beforeMount() {
            if (FiltersUtils.loadFromSessionStorage(this.$route.path)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(this.$route.path);
            }
        },

        mounted() {
            this.search();
            getListOfPrograms()
                .then((response) => {
                    this.programs = response.data;
                });
        },

        methods: {
            async search() {
                this.loading = true;
                const criteria = SearchCriteria.prepareCriteria(
                    this.searchCriteria,
                    this.page,
                    this.filterDescription,
                );
                searchDataIndicator({body: criteria})
                    .then((response) => {
                        this.indicators = response.data;
                        this.indicators.forEach((ind) => {
                            ind.year = new Date(ind.year, 1, 1);
                        });
                        return searchDataIndicatorCount({body: criteria});
                    })
                    .then((response) => {
                        this.totalRecords = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        if (err.response.status === 403) {
                            this.$router.push({name: "forbidden"});
                        }
                    });
            },

            onPage(event) {
                this.searchCriteria.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
            },

            onRowEditSave(event) {
                const {newData, index} = event;
                this.indicators[index] = newData;
                const indicatorRequest = {
                    program: this.newIndicator.program,
                    speciesId: this.newIndicator.species.id,
                    year: this.newIndicator.year.getFullYear(),
                    areal: this.newIndicator.areal,
                    numberOfSettledSquares: this.newIndicator.numberOfSettledSquares,
                    populationSize: this.newIndicator.populationSize,
                    prevalence: this.newIndicator.prevalence,
                    prevalenceSE: this.newIndicator.prevalenceSE,
                    populationIndexSE: this.newIndicator.populationIndexSE,
                    propOfPairsWithSucBreadSE: this.newIndicator.propOfPairsWithSucBreadSE,
                    averageNumOfChicksPerHatchingSE: this.newIndicator.averageNumOfChicksPerHatchingSE,
                    averageNumOfChicksPerSucHatchingSE: this.newIndicator.averageNumOfChicksPerSucHatchingSE,
                    populationIndex: this.newIndicator.populationIndex,
                    propOfPairsWithSucBread: this.newIndicator.propOfPairsWithSucBread,
                    averageNumOfChicksPerHatching: this.newIndicator.averageNumOfChicksPerHatching,
                    averageNumOfChicksPerSucHatching: this.newIndicator.averageNumOfChicksPerSucHatching,
                    dataSource: this.newIndicator.dataSource,
                };
                updateDataIndicator({id: event.data.id, body: indicatorRequest});
            },

            deleteIndicator(data) {
                deleteDataIndicator({id: data.id})
                    .then(() => {
                        const index = this.indicators.indexOf(data);
                        this.indicators.splice(index, 1);
                    });
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    FiltersUtils.saveToSessionStorage(this.$route.path, value);
                },
                deep: true,
            },
        },

        computed: {
            filterDescription() {
                return SearchCriteria.getSearchCriteriaIndicatorsFilterDescription();
            },
        },
    };
</script>

<style lang="scss">
</style>
