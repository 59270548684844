<template>
    <div class="p-3">
        <h2 class="page-header"><i class="pi pi-users"></i> Dane użytkownika</h2>
        <div class="container">
            <VeeForm v-if="loaded" @submit="saveUser" v-slot="{ errors }">
                <div class="grid">
                    <div class="col-12 md:col-4">
                        <CustomInputText name="firstName" label="Imię" v-model="user.firstName" rules="required"
                                         :disabled="($route.params.id && !isAdmin) || isGios"/>
                        <CustomInputText name="lastName" label="Nazwisko" v-model="user.lastName" rules="required"
                                         :disabled="($route.params.id && !isAdmin) || isGios"/>
                        <CustomInputText name="email" label="e-mail" v-model="user.email" rules="required|email"
                                         :disabled="($route.params.id && !isAdmin) || isGios"/>
                        <!-- <CustomPassword name="password" label="Hasło"
                        v-model="user.password" rules="validPassword"/> -->
                        <!-- <CustomSelectOneSystemRole name="systemRole" label="Rola" v-model="user.systemRole" /> -->
                        <CustomMultiSelect name="systemRole" :items="userRoles" label="Rola"
                                           v-model="user.systemRole" itemValue="key"
                                           :filter="false" itemDisabled="disabled" :disabled="isGios" />
                        <CustomMultiSelect v-if="user.systemRole.includes('REGIONAL_COORDINATOR')"
                                           name="regionalPrograms" :items="programs"
                                           label="Programy koordynatora regionalnego"
                                           v-model="user.regionalPrograms" item-label="" item-value=""
                                           :filter="false" />
                        <CustomMultiSelect v-if="user.systemRole.includes('NATIONAL_COORDINATOR')"
                                           name="nationalPrograms" :items="programs" itemLabel=""
                                           label="Programy koordynatora krajowego" v-model="user.nationalPrograms"
                                           :filter="false"/>
                        <p>
                            <b>Aktywny: <span :style="user.enabled ? 'color: var(--secondary-color)' : 'color: var(--red)'">{{ user.enabled ? "Tak" : "Nie" }}</span></b>
                        </p>
                        <div class="flex justify-content-end gap-4">
                            <CustomButton v-if="!user.accepted && isAdmin && !isGios && $route.params.id" label="Akceptuj"
                                          color="var(--surface-a)"
                                          bgColor="var(--secondary-color)"
                                          @click="accept" />
                            <CustomButton label="Zapisz" type="submit" icon="pi pi-check"
                                          :disabled="isDisabled(errors)" color="var(--surface-a)"
                                          bgColor="var(--secondary-color)" />
                            <CustomButton v-if="isAdmin && !isGios && $route.params.id
                                              && !user.systemRole.includes('ADMIN')
                                              || (user.systemRole.includes('ADMIN') && selfEdit)"
                                          :label="user.enabled ? 'Dezaktywuj' : 'Aktywuj'"
                                          :color="user.enabled ? 'var(--red)' : 'var(--surface-a)'"
                                          :bgColor="user.enabled ? '' : 'var(--secondary-color)'"
                                          @click="activeUser" />
                            <CustomButton label="Wyjdź" @click="goBack" />
                        </div>
                    </div>
                </div>
            </VeeForm>
        </div>
    </div>
</template>

<script>
    import {Form as VeeForm} from "vee-validate";
    import {
        createUser,
        updateUser,
        getListOfPrograms,
        getUser,
        getUserRoles,
        enableUser,
        disableUser,
        acceptUser,
    } from "@/swagger/vue-api-client";
    import CustomInputText from "../../components/form/CustomInputText";
    // import {SystemRole} from "../../utils/SystemRole";
    // import CustomSelectOneSystemRole from "../../components/form/CustomSelectOneSystemRole.vue";
    import CustomButton from "../../components/form/CustomButton.vue";
    import CustomMultiSelect from "../../components/form/inner/CustomMultiSelect.vue";
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "UserFormView",

        components: {
            CustomInputText,
            VeeForm,
            // CustomSelectOneSystemRole,
            CustomButton,
            CustomMultiSelect,
        },

        props: {
            value: {},
        },

        data() {
            return {
                user: {
                    id: null,
                    firstName: null,
                    lastName: null,
                    email: null,
                    password: null,
                    systemRole: [],
                    regionalPrograms: [],
                    nationalPrograms: [],
                    enabled: false,
                },
                loaded: false,
                userRoles: [],
                programs: [],
                selfEdit: false,
                isGios: false,
            };
        },

        beforeMount() {
            getUserRoles()
                .then((response) => {
                    this.userRoles = response.data;
                    if (!this.$route.params.id) {
                        this.userRoles.forEach((uR, i) => {
                            if (uR.code === "ADMIN") {
                                this.userRoles.splice(i, 1);
                            }
                        });
                    }
                    console.log(response.data);
                });
            getListOfPrograms().then((response) => {
                this.programs = response.data;
            });
            if (this.$route.params.id) {
                getUser({id: this.$route.params.id}).then((response) => {
                    this.user = response.data;
                    this.selfEdit = this.user.email === localStorage.getItem("email");
                    this.isGios = this.user.email.includes("@gios.gov.pl");
                    if (!this.user.systemRole.includes("ADMIN")) {
                        this.userRoles.forEach((uR, i) => {
                            if (uR.code === "ADMIN") {
                                this.userRoles.splice(i, 1);
                            }
                        });
                    } else {
                        this.userRoles.forEach((uR) => {
                            if (uR.code === "ADMIN") {
                                uR.disabled = true;
                            }
                        });
                    }
                    this.loaded = true;
                });
            } else {
                this.loaded = true;
            }
        },

        methods: {
            isDisabled(errors) {
                return !(errors && Object.keys(errors).length === 0
                    && Object.getPrototypeOf(errors) === Object.prototype);
            },
            saveUser() {
                if (this.$route.params.id) {
                    if (this.user.systemRole.includes("ADMIN") && !this.selfEdit) {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Administartor nie może edytować danych innego administratora",
                            life: 3000,
                        });
                    } else {
                        updateUser({id: this.$route.params.id, body: this.user}).then(() => {
                            this.$toast.add({
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zapisano dane użytkownika",
                                life: 3000,
                            });
                            this.goBack();
                        });
                    }
                } else if (this.isGios) {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: "Nie można dodać nowego użytkownika w domenie gios.gov.pl",
                        life: 3000,
                    });
                } else {
                    createUser({body: this.user}).then(() => {
                        this.$toast.add({
                            severity: "success",
                            summary: "Sukces",
                            detail: "Zapisano dane użytkownika",
                            life: 3000,
                        });
                        this.$router.push({name: "userList"});
                    });
                }
            },
            activeUser() {
                if (this.user.enabled) {
                    disableUser({id: this.$route.params.id})
                        .then(() => {
                            this.user.enabled = false;
                        });
                } else {
                    enableUser({id: this.$route.params.id})
                        .then(() => {
                            this.user.enabled = true;
                        });
                }
            },
            accept() {
                acceptUser({id: this.$route.params.id});
            },
            goBack() {
                this.$router.go(-1);
            },
        },

        computed: {
            isAdmin() {
                return SystemRole.isAdmin();
            },
        },
    };
</script>

<style lang="less" scoped>
</style>
