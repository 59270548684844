<template>
    <div class="flex flex-1 flex-wrap add-in-table-header flex-column gap-3" v-focustrap>
        <div class="flex flex-1 gap-2 flex-wrap align-items-center">
            <CustomInputText style="width: 2.85rem" label="Para" v-model="ordinal" disabled name="ordinal" class="pair-info" />
            <div style="width: 20%">
                <CustomSelectOne label="Kontrola 1 - kryterium lęgowości" name="nestingCriteria1" v-model="newItem.nestingCriteria1"
                                 :items="internalNestingCriteria1.length > 0 ? internalNestingCriteria1 : nestingCriteria1"
                                 filter :required="!disabledNC1" :showErrors="showErrors && !disabledNC1"
                                 tooltip short :itemLabel="getDictLabelWithCodePrefix" :disabled="!editing || disabledNC1"/>
            </div>
            <div style="width: 20%">
                <CustomSelectOne label="Kontrola 2 - kryterium lęgowości" name="nestingCriteria2" v-model="newItem.nestingCriteria2"
                                 :items="internalNestingCriteria2.length > 0 ? internalNestingCriteria2 : nestingCriteria2"
                                 filter :disabled="newItem.loss || !editing || disabledNC2"
                                 :required="!disabledNC2" :showErrors="showErrors && newItem.loss !== true && !disabledNC2"
                                 tooltip short :itemLabel="getDictLabelWithCodePrefix" />
            </div>
            <div class="w-1">
                <CustomCheckbox label="Strata między kontrolami" name="loss" v-model="newItem.loss"
                                :disabled="!criteriaC.includes(newItem.nestingCriteria1?.code) || disabledNC1 || disabledNC2 || !editing" />
            </div>
            <div class="w-2">
                <CustomSelectOne label="Para mieszana" name="pair" v-model="newItem.pair" :items="dicts.pair"
                                 filter required :showErrors="showErrors" :disabled="!editing"/>
            </div>
            <div class="w-2">
                <CustomInputText label="Dodatkowe informacje" name="notesBird" v-model="newItem.notesBird" :disabled="!editing"/>
            </div>
            <CustomButton v-if="editing" icon="pi pi-plus" label="Dodaj" @click="add" :error="error"
                          style="position: absolute; right: 14px; margin-bottom: 1rem" v-on:keydown.tab="add"/>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import FocusTrap from "primevue/focustrap";
    import {SystemRole} from "@/utils/SystemRole";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";

    export default {
        name: "AddBirdsMMC",

        components: {
            CustomInputText,
            CustomCheckbox,
            CustomSelectOne,
            CustomButton,
        },

        directives: {
            focustrap: FocusTrap,
        },

        props: {
            modelValue: {
                type: Object,
            },
            error: {
                type: Boolean,
                default: false,
            },
            nestingCriteria1: {
                type: Array,
                default: () => [],
            },
            nestingCriteria2: {
                type: Array,
                default: () => [],
            },
            disabledNC1: {
                type: Boolean,
                default: false,
            },
            disabledNC2: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["editing", "dicts"],

        data() {
            return {
                newItem: _.cloneDeep(EmptyStructures.getEmptyMMCBird()),
                showErrors: false,
                ordinal: 1,
                criteriaC: ["GNS", "WYS", "JAJ", "PIS", "MŁO", "MLO"],
                internalNestingCriteria1: [],
                internalNestingCriteria2: [],
            };
        },

        methods: {
            add() {
                if (((this.disabledNC1 || !ValidateUtils.validateNotEmpty(this.newItem, ["pair"])) || !ValidateUtils.validateNotEmpty(this.newItem, ["nestingCriteria1", "pair"]))
                    && (this.newItem.loss === true
                        || (!this.disabledNC2 && !ValidateUtils.validateNotEmpty(this.newItem, ["nestingCriteria2"])))) {
                    this.showErrors = true;
                } else {
                    this.birds.push({
                        ...this.newItem,
                        ordinal: this.birds.length + 1,
                    });
                    this.newItem = _.cloneDeep(EmptyStructures.getEmptyMMCBird());
                    this.showErrors = false;
                }
            },
            getDictLabelWithCodePrefix(item) {
                if (item == null) return "";
                return this.textTruncate(item.code + " - " + item.label);
            },
            textTruncate(text) {
                if (text.length > 40) {
                    return _.truncate(text, {
                        length: 40,
                        separator: " ",
                    });
                }
                return text;
            },
        },

        computed: {
            birds: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            isAdmin() {
                return SystemRole.isAdmin();
            },
        },

        watch: {
            birds: {
                handler(value) { this.ordinal = this.birds.length + 1; this.$emit("update:modelValue", value); },
                deep: true,
            },
            "newItem.loss"(value) {
                if (value === true) this.newItem.nestingCriteria2 = null;
            },
            "newItem.nestingCriteria1"(value) {
                this.internalNestingCriteria2 = this.nestingCriteria2;
                if (!this.criteriaC.includes(value?.code)) this.newItem.loss = false;
                if (value?.code === "BRAK") {
                    this.internalNestingCriteria2 = this.internalNestingCriteria2.filter((i) => i.code !== "BRAK");
                }
            },
            "newItem.nestingCriteria2"(value) {
                this.internalNestingCriteria1 = this.nestingCriteria1;
                if (value?.code === "BRAK") {
                    this.internalNestingCriteria1 = this.internalNestingCriteria1.filter((i) => i.code !== "BRAK");
                }
            },
            disabledNC1(val) {
                if (val) this.newItem.nestingCriteria1 = null;
            },
            disabledNC2(val) {
                if (val) this.newItem.nestingCriteria2 = null;
            },
        },
    };
</script>

<style lang="scss">
.add-in-table-header {
    margin-bottom: -1rem,
}
.disabled-not-opacity {
    .p-disabled, .p-component:disabled {
        opacity: 1;
    }
}
.pair-info {
    .p-inputtext {
        padding: 0 !important;
        text-align: center;
    }
    label {
        left: 4px !important;
    }
}
</style>
