<template>
    <div v-if="yearsLoaded" class="flex gap-2 align-items-center mb-2 flex-wrap">
        <div v-for="year in years" :key="year.year"
             class="p-2 program-button cursor-pointer">
            <router-link :to="goToProgram(year.id)">
                {{ year.year }}
            </router-link>
        </div>
    </div>
    <div class="programs-list-internal-table">
        <!--filterDisplay="row"-->
        <DataTable dataKey="id" :value="forms" size="small" lazy
                   paginator :rows="10" :rowsPerPageOptions="[5,10,20,50]" removableSort
                   @page="onPage" @sort="onPage" sortMode="multiple"
                   v-model:rows="page.limit" v-model:first="page.offset"
                   v-model:filters="searchCriteria" filterDisplay="menu" @update:filters="search"
                   @rowClick="goToLink" class="programs-list-internal-datatable"
                   :loading="loading" :totalRecords="totalRecords">
            <Column field="field" header="Powierzchnia" class="w-2" sortable
                    filterField="field" :showFilterMatchModes="false" filter-menu-class="w-16rem"
                    sortField="field.name">
                <template #filter="{filterModel,applyFilter}">
                    <CustomMultiSelect v-if="loadLists" name="field" v-model="filterModel.value"
                                       :items="filterLists.field" itemValue="id"
                                       filter itemLabel="name" class="flex-1 w-0 mt-3"
                                       @keydown.enter="applyFilter" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
                <template #body="slotProps">
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ slotProps.data.field.name }}
                        </div>
                    </router-link>
                </template>
            </Column>
            <Column v-if="filterLists.control && filterLists.control.length > 1" header="Kontrola" field="control" class="w-2"
                    filterField="control" :showFilterMatchModes="false" filter-menu-class="w-16rem" sortable sortField="control.label">
                <template #filter="{filterModel,applyFilter}">
                    <CustomMultiSelect v-if="loadLists" name="control" v-model="filterModel.value" :items="filterLists.control"
                                       filter itemLabel="label" itemValue="key" class="flex-1 w-0 mt-3"
                                       @keydown.enter="applyFilter" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
                <template #body="slotProps">
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ slotProps.data.control.label }}
                        </div>
                    </router-link>
                </template>
            </Column>
            <Column header="Rok" field="year" class="w-1" sortable
                    filterField="year" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #filter="{filterModel,applyFilter}">
                    <CustomIntervalFilter v-if="loadLists" v-model="filterModel.value"
                                          :range="filterLists.year" @keydown.enter="applyFilter" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
                <template #body="slotProps">
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ slotProps.data.year }}
                        </div>
                    </router-link>
                </template>
            </Column>
            <Column header="Status" field="status" class="w-2"
                    filterField="status" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #filter="{filterModel,applyFilter}">
                    <CustomMultiSelect v-if="loadLists" name="status" v-model="filterModel.value" :items="filterLists.status"
                                       filter itemValue="" class="flex-1 w-0 mt-3" :itemLabel="computedStatus"
                                       @keydown.enter="applyFilter" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
                <template #body="slotProps">
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div :class="`control-state ${computedColorStatus(slotProps.data.status)}`">
                            <span>{{ computedStatus(slotProps.data.status) }}</span>
                        </div>
                    </router-link>
                </template>
            </Column>
            <Column header="Start" field="startDate" class="w-1" sortable>
                <template #body="slotProps">
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ slotProps.data.startDate }}
                        </div>
                    </router-link>
                </template>
            </Column>
            <Column header="Koniec" field="endDate" class="w-1" sortable>
                <template #body="slotProps">
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ slotProps.data.endDate }}
                        </div>
                    </router-link>
                </template>
            </Column>
            <Column v-if="filterLists.coordinator && filterLists.coordinator.length > 0" header="Koordynator regionalny" field="coordinator" filterField="coordinator"
                    :showFilterMatchModes="false" filter-menu-class="w-16rem" sortable
                    sortField="coordinator.name">
                <template #filter="{filterModel,applyFilter}">
                    <CustomMultiSelect v-if="loadLists" name="regionalCoordinator"
                                       v-model="filterModel.value" :items="filterLists.coordinator"
                                       filter itemValue="id" class="flex-1 w-0 mt-3" itemLabel="name"
                                       @keydown.enter="applyFilter" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
                <template #body="slotProps">
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ slotProps.data.coordinator?.name }}
                        </div>
                    </router-link>
                </template>
            </Column>
            <Column header="Obserwator" field="observer" class="w-1" filterField="observer"
                    :showFilterMatchModes="false" filter-menu-class="w-16rem" sortable
                    sortField="observer.name">
                <template #filter="{filterModel,applyFilter}">
                    <CustomMultiSelect v-if="loadLists" name="observer"
                                       v-model="filterModel.value" :items="filterLists.observer"
                                       filter itemValue="id" class="flex-1 w-0 mt-3" itemLabel="name"
                                       @keydown.enter="applyFilter" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
                <template #body="slotProps">
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ slotProps.data.observer?.name }}
                        </div>
                    </router-link>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Tooltip from "primevue/tooltip";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import FilterApply from "@/components/filters/FilterApply.vue";
    import FilterClear from "@/components/filters/FilterClear.vue";
    import CustomIntervalFilter from "@/components/filters/CustomIntervalFilter.vue";
    import {
        homeSearchForms,
        homeCountForms,
        homeFilterForms,
        getEditions,
    } from "@/swagger/vue-api-client";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {ControlState} from "@/utils/ControlState";

    export default {
        name: "ProgramsListInternalTable",

        components: {
            CustomIntervalFilter,
            FilterClear,
            FilterApply,
            DataTable,
            Column,
            CustomMultiSelect,
        },

        directives: {
            tooltip: Tooltip,
        },

        props: {
            program: {
                required: true,
            },
        },

        inject: ["showFinished"],

        data() {
            return {
                searchCriteria: SearchCriteria.getClearSearchCriteriaForms(),
                page: SearchCriteria.getClearPage(),
                loading: false,
                statuses: ControlState.getControlStates(),
                forms: [],
                totalRecords: null,
                filters: [],
                years: [],
                yearsLoaded: false,
                filterLists: {},
                loadLists: false,
            };
        },

        beforeMount() {
            this.search(this.program);
            getEditions({program: this.program, finished: this.showFinished})
                .then((response) => {
                    this.years = response.data;
                    this.yearsLoaded = true;
                });
        },

        mounted() {
            homeFilterForms({program: this.program})
                .then((response) => {
                    this.filterLists = response.data;
                    this.loadLists = true;
                });
        },

        methods: {
            search() {
                if (this.loading) return;
                this.loading = true;
                setTimeout(() => {
                    const criteria = SearchCriteria.prepareCriteria(
                        this.searchCriteria,
                        this.page,
                        this.filterDescription,
                    );
                    homeSearchForms({body: {...criteria, finished: this.showFinished, program: this.program}, program: this.program})
                        .then((response) => {
                            this.forms = response.data;
                            return homeCountForms({body: {...criteria, finished: this.showFinished, program: this.program}, program: this.program});
                        })
                        .then((response) => {
                            this.totalRecords = response.data;
                            this.loading = false;
                        });
                }, 100);
            },
            clear() {
                this.searchCriteria = SearchCriteria.getClearSearchCriteriaForms();
                this.search();
            },
            onPage(event) {
                this.page = {
                    offset: event.first,
                    limit: event.rows,
                    multiSort: event.multiSortMeta,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
                this.search();
            },
            computedColorStatus(status) {
                if (status === "RETURNED") return "status-returned";
                if (status === "RETURNED_DRAFT") return "status-returned";
                if (status === "RETURNED_SENT_TO_VERIFICATION") return "status-returned";
                if (status === "DRAFT") return "status-draft";
                if (status === "CORRECTED_DRAFT") return "status-draft";
                if (status === "COORDINATOR_DRAFT") return "status-draft";
                if (status === "SENT_TO_VERIFICATION") return "status-sent";
                if (status === "APPROVED") return "status-approved";
                return "";
            },
            computedStatus(status) {
                return ControlState.getStateLabel(status);
            },
            goToLink(data) {
                this.$router.push(this.getRouterLink(data.data));
            },
            getRouterLink(rowData) {
                if (rowData.formId != null) {
                    let path;
                    if (rowData.control.code === "0T") {
                        path = `/form-zero/transect/${rowData.formId}`;
                    } else if (rowData.control.code === "0P") {
                        path = `/form-zero/point/${rowData.formId}`;
                    } else {
                        path = `/form/${this.program}/${rowData.formId}`;
                        if (rowData.control.code !== null) path += `?controlCode=${rowData.control.code}`;
                    }
                    return path;
                }
                const query = {
                    observerID: rowData.programObserverId,
                    fieldId: rowData.field.id,
                    programCode: this.program,
                };
                if (rowData.control.code !== null) {
                    query.controlCode = rowData.control.code;
                }
                return {
                    name: "toCreateForm",
                    query,
                };
                // return `/form/${this.program}/${id}`;
            },
            goToProgram(id) {
                return `/monitoring-program/${id}`;
            },
        },

        computed: {
            filterDescription() {
                return SearchCriteria.getSearchCriteriaFormsFilterDescriptionHome();
            },
        },

        watch: {
            showFinished() {
                this.search();
            },
        },
    };
</script>

<style lang="scss">
@import "../../../assets/theme/mytheme/variables";

.programs-table-observer {
    .p-column-title {
        font-size: 1rem;
        color: var(--surface-a);
    }
    .p-datatable .p-datatable-thead > tr > th {
        background-color: var(--secondary-color);
    }
    .p-datatable .p-datatable-tbody .p-datatable .p-datatable-thead > tr > th {
        background-color: rgba(5, 104, 57, 0.27);
        border-width: 0;
        .p-column-title {
            color: var(--color-black);
        }
        .p-datatable {
            border: 1px solid var(--surface-border);
        }
    }
    .p-datatable .p-datatable-tbody > tr:focus {
        outline: none;
    }
    .p-datatable .p-datatable-tbody > tr > td {
        background-color: var(--primary-color);
        color: var(--color-black);
        font-weight: normal;
        border: 1px solid var(--surface-border);
        border-top: none;
        border-left: none;
        &:first-child {
            border-left: 1px solid var(--surface-border);
        }
        &.header-border:nth-of-type(1) {
            border-right: none;
        }
        &.header-border:nth-of-type(2) {
            border-left: none;
            border-right: none;
        }
        &.header-border:nth-of-type(3) {
            border-left: none;
            border-right: none;
        }
        &.header-border:nth-of-type(4) {
            border-left: none;
        }
    }
    .p-datatable .p-datatable-tbody > tr > td.program-header {
        font-weight: bold;
    }
    .p-datatable .p-datatable-wrapper {
        & .p-datatable-row-expansion, .p-datatable-row-expansion td .p-datatable .p-datatable-wrapper {
            // border: 1px solid rgba(5, 104, 57, 0.27);
        }
    }
    a {
        display: block;
        color: var(--text-color);
        padding: 1rem;
    }
    .program-button {
        a {
            color: var(--text-color-black);
            padding: 0;
        }
    }
    .p-datatable-row-expansion, .p-datatable-row-expansion td {
        background-color: var(--surface-a) !important;
    }
    .p-datatable-tbody tr.p-datatable-row-expansion td, .p-datatable-tbody tr.p-datatable-row-expansion th {
        padding: 1rem 2.5rem;
    }
}

.programs-list-internal-table {
    .p-datatable .p-datatable-tbody > tr > td {
        padding: 0;
    }
    .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
        padding: 0;
    }
    .programs-list-internal-datatable a {
        color: var(--text-black);
        padding: 1rem;
        display: block;
    }
    .control-state {
        color: var(--text-color-black);
        display: block;
        padding: 2px 8px;
        width: fit-content;
        text-align: center;
        border-radius: 4px;
    }
    .status-sent {
        background-color: var(--sent-color-light);
        color: var(--sent-color);
    }
    .status-draft {
        background-color: var(--draft-color-light);
        color: var(--draft-color);
    }
    .status-approved {
        background-color: var(--approved-color-light);
        color: var(--approved-color);
    }
    .status-returned {
        background-color: var(--returned-color-light);
        color: var(--returned-color);
    }
}
</style>
