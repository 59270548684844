<template>
    <div>
        <h2 v-if="createGeobject" class="mb-5">Stwórz nowy {{ biernik }}</h2>
        <h2 v-else class="mb-5">Edycja {{ dopelniacz }}</h2>
        <div class="border-1 surface-border">
            <HeaderComponent headerText="Informacje" class="m-0" :buttons="buttonsItem"
                             @clickButton="clickButtonItem"/>
            <div class="flex gap-8 p-5 flex-direction-column-700">
                <div class="w-4 map-container">
                    <slot name="map" :selectedHistory="selectedHistory"/>
                </div>
                <div class="w-8 flex flex-direction-column-1240 data-container">
                    <div v-if="loaded" class="flex flex-column gap-5 w-6 full-width">
                        <CustomInputText name="name" label="Nazwa" v-model="internalItem.name"
                                         required :showErrors="showErrors" class="m-0 w-8"
                                         :disabled="isCoordinator && internalItem.accepted"/>
                        <CustomInputText v-if="name === 'position'" name="commonName" label="Nazwa zwyczajowa" v-model="internalItem.commonName"
                                         class="m-0 w-8" />
                        <CustomAutocompleteUser v-if="name === 'position'" label="Właściciel" class="m-0 w-8"
                                                v-model="internalItem.owner" name="owner" :dropdown="false"
                                                @onSelectUserFromList="onSelectUserFromList"/>
                        <CustomInputNumber v-if="name === 'point' || name === 'transect'"
                                           name="order" label="Numer kolejności" v-model="internalItem.order"
                                           :required="name !== 'position'" :showErrors="showErrors" class="m-0 w-8"
                                           :disabled="isCoordinator && internalItem.accepted"/>
                        <CustomSelectOne id="program" v-model="internalItem.program" :items="programs"
                                         name="program" label="Program" itemLabel="" :disabled="!createGeobject"
                                         required :showErrors="showErrors"
                                         :showClear="false" class="m-0 w-8"/>
                        <div v-if="name !== 'field'" class="flex gap-3">
                            <CustomSelectOne id="field" v-model="internalItem.fieldId" :items="fields"
                                             :required="internalItem.program != null" :showErrors="showErrors"
                                             :disabled="internalItem.program == null || !createGeobject" :showClear="false"
                                             name="field" label="Powierzchnia" itemValue="id" itemLabel="name"
                                             class="m-0 w-8"/>
                            <div v-if="internalItem.fieldId != null" @click="navigateToField(internalItem.fieldId)"
                                 class="field-link">Zobacz</div>
                        </div>
                        <div v-if="programHasSubfields" class="flex gap-3">
                            <CustomSelectOne id="subfield" v-model="internalItem.subfieldId" :items="subfields"
                                             name="subfield" label="Podpowierzchnia" itemValue="id" itemLabel="name"
                                             :showClear="false" class="m-0 w-8" :disabled="!createGeobject"/>
                            <div v-if="internalItem.subfieldId != null" @click="navigateToField(internalItem.subfieldId)"
                                 class="field-link">Zobacz</div>
                        </div>
                        <div v-if="name === 'field' && programHasSuperFields" class="flex gap-3">
                            <CustomSelectOne id="superField" v-model="internalItem.superFieldId" :items="superFields"
                                             :required="internalItem.program != null" :showErrors="showErrors"
                                             :disabled="internalItem.program == null || !createGeobject" :showClear="false"
                                             name="superField" label="Nadpowierzchnia" itemValue="id" itemLabel="name"
                                             class="m-0 w-8"/>
                            <div v-if="internalItem.superFieldId != null" @click="navigateToField(internalItem.superFieldId)"
                                 class="field-link">Zobacz</div>
                        </div>
                        <CustomSelectOne v-if="internalItem.program === 'MFGP'" id="species" v-model="internalItem.speciesId"
                                         :items="species" name="species" label="Gatunek" itemLabel="name" itemValue="id"
                                         :disabled="!createGeobject" :required="internalItem.program != null && createGeobject"
                                         :showErrors="showErrors" :showClear="false" class="m-0 w-8"/>
                        <div v-if="!createGeobject && name === 'position'" class="flex flex-column gap-3">
                            <div class="status-info">Status:<div>{{ internalItem.status == null ? "" : internalItem.status.label }}</div></div>
                            <div v-if="canChangeStatus" class="flex gap-4">
                                <div @click="setStatusValue('ACTIVE')" class="status-link">Zmień na "Aktywny"</div>
                                <div @click="setStatusValue('ARCHIVED')" class="status-link">Zmień na "Zarchiwizowany"</div>
                            </div>
                        </div>
                        <CustomCheckbox v-if="internalItem.program === 'MZPW'" name="includeInMZPWP" label="Uwzględniaj w MZPWP"
                                        v-model="internalItem.includeInMZPWP"/>
                    </div>
                    <div class="flex flex-column w-6 justify-content-between full-width">
                        <div class="geography-info">Województwo:<div>{{ getNames("wojewodztwo") }}</div></div>
                        <div class="geography-info">Powiat:<div>{{ getNames("powiat") }}</div></div>
                        <div class="geography-info">Gmina:<div>{{ getNames("gmina") }}</div></div>
                        <div class="geography-info">RDLP:<div>{{ getNames("rdlp") }}</div></div>
                        <div class="geography-info">Nadleśnictwo:<div>{{ getNames("nadlesnictwo") }}</div></div>
                        <div class="geography-info">Leśnictwo:<div>{{ getNames("lesnictwo") }}</div></div>
                        <div class="geography-info">OSO:<div>{{ getNames("obszarSpecjalnejOchrony") }}</div></div>
                        <div class="geography-info">OChK:<div>{{ getNames("obszarChronionegoKrajobrazu") }}</div></div>
                        <div class="geography-info">SOO:<div>{{ getNames("specjalnyObszarOchrony") }}</div></div>
                        <div class="geography-info">RAMSAR:<div>{{ getNames("ramsar") }}</div></div>
                        <div class="geography-info">Rezerwat:<div>{{ getNames("rezerwat") }}</div></div>
                        <div class="geography-info">Park krajobrazowy:<div>{{ getNames("parkKrajobrazowy") }}</div></div>
                        <div class="geography-info">Park narodowy:<div>{{ getNames("parkNarodowy") }}</div></div>
                    </div>
                </div>
            </div>
            <div v-if="name === 'field'" class="ml-5">
                <h3 class="mb-2">Współrzędne</h3>
                <div class="flex m-3 align-items-center gap-3" v-for="(coordinate,index) in fieldShape" :key="index">
                    <span class="mb-3">{{ index + 1 }}.</span>
                    <div class="flex flex-direction-column-700 column-gap-3">
                        <CustomInputText id="longitude" type="number" step="any"
                                         min="-180" max="180" v-model="coordinate.longitude" name="longitude" label="Długość"
                                         :disabled="index === fieldShape.length - 1"
                                         :customErrorWithoutMessage="!correctCoordinate(coordinate.longitude)"
                                         @blur="coordinateChanged"/>
                        <CustomInputText id="latitude" type="number" step="any"
                                         min="-180" max="180" v-model="coordinate.latitude" name="latitude" label="Szerokość"
                                         :disabled="index === fieldShape.length - 1"
                                         :customErrorWithoutMessage="!correctCoordinate(coordinate.latitude)"
                                         @blur="coordinateChanged"/>
                    </div>
                    <CustomButton @click="removeCoordinate(index)" label="Usuń" class="field"
                                  style="min-width: 68px;"/>
                </div>
                <div class="flex mb-3 ml-6">
                    <CustomButton type="button" @click="addCoordinate" label="Dodaj współrzędne"/>
                </div>
            </div>
        </div>
        <Dialog v-model:visible="acceptItemHistoryDialogVisible" :draggable="false" modal :closable="false"
                :header="'Czy chcesz zaakceptować historię ' + dopelniacz + '?'"
                class="save-info-dialog">
            <div class="save-info-dialog-buttons">
                <CustomButton label="Akceptuj" @click="confirmAcceptItemHistory" />
                <CustomButton label="Anuluj" @click="acceptItemHistoryDialogVisible = false" />
            </div>
        </Dialog>
        <Dialog v-model:visible="acceptItemDialogVisible" :draggable="false" modal :closable="false"
                :header="'Czy chcesz zaakceptować ' + biernik + '?'"
                class="save-info-dialog">
            <div class="save-info-dialog-buttons">
                <CustomButton label="Akceptuj" @click="confirmAcceptItem" />
                <CustomButton label="Anuluj" @click="acceptItemDialogVisible = false" />
            </div>
        </Dialog>
        <Dialog v-model:visible="deleteItemHistoryDialogVisible" class="delete-transect-dialog"
                header="Czy na pewno chcesz usunąć ten wpis?" :draggable="false" modal :closable="false">
            <div class="delete-transect-dialog-container">
                <div class="delete-transect-dialog-buttons">
                    <CustomButton label="Tak" @click="confirmDeleteItemHistory" />
                    <CustomButton label="Nie" @click="() => deleteItemHistoryDialogVisible = false" />
                </div>
            </div>
        </Dialog>
        <HabitatDataDialog v-if="hasHabitatData && habitatDataDialogVisible" v-model:visible="habitatDataDialogVisible"
                           :gHistoryId="itemHistoryToShowHabitatData.id" :programCode="item.program" />
        <div v-if="name !== 'field'" class="history-table border-1 surface-border mt-7">
            <HeaderComponent headerText="Historia lokalizacji" :buttons="buttonsItemHistory"
                             @clickButton="clickButtonItemHistory" class="mt-0 mb-3"/>
            <DataTable :value="internalItemHistory" paginator :rows="10" class="p-3"
                       :totalRecords="totalRecords" @rowClick="selectHistoryRow">
                <Column v-if="name !== 'transect'" header="Współrzędne" field="coordinates" class="w-3">
                    <template #body="slotProps">
                        {{ slotProps.data.coordinates != null ? slotProps.data.coordinates.longitude : "" }},
                        {{ slotProps.data.coordinates != null ? slotProps.data.coordinates.latitude : "" }}
                    </template>
                </Column>
                <Column header="Zaakceptowany" field="accepted" class="w-2">
                    <template #body="slotProps">
                        <div class="flex flex-column gap-2">
                            <div :class="slotProps.data.accepted ? 'true-style' : 'false-style'">
                                <span>Koordynator: {{ slotProps.data.accepted ? "Tak" : "Nie" }}</span>
                            </div>
                            <div :class="slotProps.data.adminAccepted ? 'true-style' : 'false-style'">
                                <span>Administrator: {{ slotProps.data.adminAccepted ? "Tak" : "Nie" }}</span>
                            </div>
                        </div>
                    </template>
                </Column>
                <Column header="Rok" field="year" class="w-1">
                    <template #body="slotProps">{{ getYear(slotProps.data.year) }}</template>
                </Column>
                <Column header="Działania" class="w-6">
                    <template #body="slotProps">
                        <div class="flex gap-3 align-items-center">
                            <CustomButton v-if="isAdmin || !slotProps.data.adminAccepted" label="Edycja" icon="pi pi-pencil"
                                          @click="editItemHistory(slotProps.data)" style="min-width: 114px" />
                            <CustomButton v-if="(!slotProps.data.accepted && isCoordinator) || (!slotProps.data.adminAccepted && isAdmin)"
                                          label="Akceptuj"
                                          class="p-button-success"
                                          @click="acceptItemHistory(slotProps.data)"
                                          style="min-width: 94px;" />
                            <CustomButton v-if="false"
                                          label="Zobacz dane siedliskowe" class="p-button-success"
                                          @click="showHabitatData(slotProps.data)"
                                          style="min-width: 202px;" />
                            <CustomButton v-if="isAdmin || !slotProps.data.adminAccepted"
                                          label="Usuń" class="p-button-danger"
                                          @click="deleteItemHistory(slotProps.data)"
                                          style="min-width: 67px;" />
                            <div v-if="slotProps.index === 0" class="true-style flex h-min">
                                <span>Aktualny</span>
                            </div>
                            <div v-if="slotProps.data.id === selectedHistory.id" class="true-style flex h-min">
                                <span>Wyświetlany</span>
                            </div>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import Dialog from "primevue/dialog";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import HeaderComponent from "@/components/HeaderComponent.vue";
    import {
        getListOfPrograms,
        getListOfStatuses,
        listFieldsForProgram,
        listSubfieldsForField,
        listSuperfieldsForProgram,
        searchSpecialSpecies,
    } from "@/swagger/vue-api-client";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {DateUtils} from "@/utils/DateUtils";
    import HabitatDataDialog from "@/views/dictTransect/HabitatDataDialog.vue";
    import {SystemRole} from "@/utils/SystemRole";
    import CustomAutocompleteUser from "@/components/form/CustomAutocompleteUser.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";

    export default {
        name: "DictDetailsDataTemplate",

        components: {
            CustomCheckbox,
            CustomAutocompleteUser,
            HabitatDataDialog,
            Dialog,
            DataTable,
            Column,
            CustomButton,
            CustomSelectOne,
            CustomInputText,
            CustomInputNumber,
            HeaderComponent,
        },

        props: {
            item: {
                type: Object,
                required: true,
            },
            itemHistory: {
                type: Array,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            biernik: {
                type: String,
                required: true,
            },
            dopelniacz: {
                type: String,
                required: true,
            },
            hasHabitatData: {
                type: Boolean,
                default: false,
            },
            createGeobject: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                programs: [],
                fields: [],
                subfields: [],
                loaded: false,
                totalRecords: 0,
                programHasHabitatData: false, // TODO
                showErrors: false,
                itemHistoryToDelete: null,
                deleteItemHistoryDialogVisible: false,
                itemHistoryToAccept: null,
                acceptItemHistoryDialogVisible: false,
                acceptItemDialogVisible: false,
                itemHistoryToShowHabitatData: null,
                habitatDataDialogVisible: false,
                somethingChanged: false,
                savedNew: false,
                selectedHistory: {},
                users: [],
                statuses: [],
                species: [],
                superFields: [],
                fieldShape: [],
                programType: null,
            };
        },

        emits: ["update:item", "update:itemHistory", "deleteItemHistory", "acceptItemHistory", "acceptItem",
                "saveItem", "reloadItemData", "editItemHistory", "createItemHistory", "exit", "selectHistoryToShow"],

        beforeMount() {
            this.programType = this.checkProgramType(this.$route.path);
            if (this.internalItem.histories != null && this.internalItem.histories.length > 0) {
                this.selectedHistory = this.internalItem.histories[0];
                this.totalRecords = this.internalItem.histories.length;
            }
            if (this.name === "field") {
                if (this.internalItem.shape == null || this.internalItem.shape.length === 0) {
                    const coordinates = {longitude: 0, latitude: 0};
                    this.fieldShape = [coordinates, coordinates];
                } else {
                    this.fieldShape = _.cloneDeep(this.internalItem.shape);
                }
            }
            getListOfStatuses()
                .then((response) => { this.statuses = response.data; });
            getListOfPrograms({type: this.programType})
                .then((responsePrograms) => {
                    this.programs = responsePrograms.data;
                    if (this.internalItem.program != null) {
                        listFieldsForProgram({program: this.internalItem.program})
                            .then((responseFields) => {
                                this.fields = responseFields.data;
                                if (this.internalItem.field != null) {
                                    listSubfieldsForField({fieldId: this.internalItem.field.id})
                                        .then((responseSubfields) => {
                                            this.subfields = responseSubfields.data;
                                            this.loaded = true;
                                        });
                                } else {
                                    this.loaded = true;
                                }
                            });
                    } else {
                        this.loaded = true;
                    }
                });
        },

        methods: {
            checkProgramType(url) {
                if (url.includes("position")) return "POSITION";
                if (url.includes("transect")) return "TRANSECT";
                if (url.includes("point")) return "POINT";
                return "";
            },
            coordinateChanged() {
                if (this.allCorrectCoordinates() && this.fieldShape.length > 2) {
                    this.internalItem.shape = _.cloneDeep(this.fieldShape);
                }
            },
            allCorrectCoordinates() {
                let foundIncorrect = false;
                this.fieldShape.forEach((c) => {
                    if (!this.correctCoordinate(c.latitude) || !this.correctCoordinate(c.longitude)) foundIncorrect = true;
                });
                return !foundIncorrect;
            },
            correctCoordinate(value) {
                return value != null && value !== "" && value !== 0 && value.toString().slice(-1) !== ".";
            },
            clickButtonItem(value) {
                if (this.buttonsItem[value].onClick) {
                    this.buttonsItem[value].onClick();
                }
            },
            clickButtonItemHistory(value) {
                if (this.buttonsItemHistory[value].onClick) {
                    this.buttonsItemHistory[value].onClick();
                }
            },
            editItemHistory(entry) {
                this.$emit("editItemHistory", entry);
            },
            createItemHistory() {
                this.$emit("createItemHistory");
            },
            deleteItemHistory(entry) {
                this.itemHistoryToDelete = entry;
                this.deleteItemHistoryDialogVisible = true;
            },
            confirmDeleteItemHistory() {
                this.$emit("deleteItemHistory", this.itemHistoryToDelete.id);
                this.deleteItemHistoryDialogVisible = false;
                this.itemHistoryToDelete = null;
            },
            acceptItemHistory(entry) {
                this.itemHistoryToAccept = entry;
                this.acceptItemHistoryDialogVisible = true;
            },
            confirmAcceptItemHistory() {
                this.$emit("acceptItemHistory", this.itemHistoryToAccept);
                this.acceptItemHistoryDialogVisible = false;
                this.itemHistoryToAccept = null;
            },
            acceptItem() {
                this.acceptItemDialogVisible = true;
            },
            confirmAcceptItem() {
                this.$emit("acceptItem");
                this.acceptItemDialogVisible = false;
            },
            showHabitatData(entry) {
                this.itemHistoryToShowHabitatData = entry;
                this.habitatDataDialogVisible = true;
            },
            saveItem(exit) {
                if (this.validateItem()) {
                    console.log("emit save item", exit);
                    this.$emit("saveItem", exit);
                    this.savedNew = true;
                    this.showErrors = false;
                } else {
                    this.showErrors = true;
                }
            },
            validateItem() {
                if (this.name === "position") {
                    if (this.internalItem.ownerId == null) this.internalItem.owner = null;
                    if (this.internalItem.program === "MFGP" && !ValidateUtils.validateNotEmpty(this.internalItem, ["speciesId"])) return false;
                    return ValidateUtils.validateNotEmpty(this.internalItem, ["name", "fieldId"]);
                }
                if (this.name === "field") {
                    return ValidateUtils.validateNotEmpty(this.internalItem, ["name", "program"]);
                }
                return ValidateUtils.validateNotEmpty(this.internalItem, ["name", "order", "fieldId"]);
            },
            updateFieldsForProgram(program) {
                listFieldsForProgram({program})
                    .then((response) => { this.fields = response.data; });
            },
            updateSuperFieldsForProgram(program) {
                listSuperfieldsForProgram({program})
                    .then((response) => { this.superFields = response.data; });
            },
            updateSubfieldsForField(fieldId) {
                listSubfieldsForField({fieldId})
                    .then((response) => { this.subfields = response.data; });
            },
            getYear(date) {
                if (date instanceof Date) return DateUtils.getYearOnly(date);
                return date;
            },
            navigateToField(fieldId) {
                const route = this.$router.resolve(`/field/${fieldId}`);
                window.open(route.href, "_blank");
                // this.$router.push(`/field/${fieldId}`);
            },
            getNames(geographyKey) {
                if (this.name === "field") {
                    if (this.internalItem == null || this.internalItem.geography == null) return "";
                    return this.internalItem.geography[geographyKey].map((d) => d.name).join(", ");
                }

                if (this.selectedHistory == null || this.selectedHistory.geography == null) return "";
                return this.selectedHistory.geography[geographyKey].map((d) => d.name).join(", ");
            },
            selectHistoryRow(event) {
                this.selectedHistory = event.data;
                const index = this.internalItemHistory.indexOf(event.data);
                this.$emit("selectHistoryToShow", index);
            },
            setStatusValue(value) {
                this.internalItem.status = {key: value};
                this.$emit("saveItem", false);
            },
            onSelectUserFromList(user) {
                this.internalItem.owner = user.label;
                this.internalItem.ownerId = user.value;
            },
            addCoordinate() {
                const length = this.fieldShape.length;
                this.fieldShape.splice(length - 1, 0, { longitude: 0, latitude: 0 });
            },
            removeCoordinate(index) {
                this.fieldShape.splice(index, 1);
                this.coordinateChanged();
            },
        },

        computed: {
            canChangeStatus() {
                if (this.internalItem.status == null) return false;
                return Array.of("CREATED", "REPORTED_TO_REMOVE", "REPORTED_TO_RESTORE").includes(this.internalItem.status.key);
            },
            programHasSubfields() {
                return this.subfields.length > 0;
            },
            programHasSuperFields() {
                return this.superFields.length > 0;
            },
            isAdmin() {
                return SystemRole.isAdmin();
            },
            isCoordinator() {
                return SystemRole.isCoordinator();
            },
            internalItem: {
                get() { return this.item; },
                set(value) { this.$emit("update:item", value); },
            },
            internalItemHistory: {
                get() { return this.itemHistory; },
                set(value) { this.$emit("update:itemHistory", value); },
            },
            buttonsItem() {
                const buttons = [
                    {
                        label: "Zapisz",
                        show: true,
                        onClick: () => { this.saveItem(false); },
                    },
                    {
                        label: "Zapisz i wyjdź",
                        show: true,
                        onClick: () => { this.saveItem(true); },
                    },
                ];
                if (!this.createGeobject) {
                    buttons.push({
                        label: "Wycofaj zmiany",
                        bgColor: "var(--surface-a)",
                        labelColor: "var(--text-color)",
                        borderColor: "var(--custom-button-border-color)",
                        show: true,
                        onClick: () => { this.$emit("reloadItemData"); },
                        disabled: !this.somethingChanged,
                    });
                    if (!this.internalItem.accepted && this.isAdmin) {
                        buttons.push({
                            label: "Akceptuj",
                            show: true,
                            onClick: () => { this.acceptItem(); },
                        });
                    }
                }
                // zależy nam, żeby ten przycisk był ostatni, już po ewentualnym "wycofaj zmiany"
                buttons.push({
                    label: "Powrót",
                    bgColor: "var(--surface-a)",
                    labelColor: "var(--text-color)",
                    borderColor: "var(--custom-button-border-color)",
                    show: true,
                    onClick: () => { this.$emit("exit"); },
                });
                return buttons;
            },
            buttonsItemHistory() {
                return [
                    {
                        label: "Utwórz nową historię " + this.dopelniacz,
                        show: true,
                        onClick: () => { this.createItemHistory(); },
                        disabled: this.createGeobject && !this.savedNew,
                    },
                ];
            },
        },

        watch: {
            internalItem: {
                handler(value) {
                    this.somethingChanged = true;
                    if (this.internalItem.histories != null && this.internalItem.histories.length > 0) {
                        this.selectedHistory = this.internalItem.histories[0];
                    }
                    this.$emit("update:item", value);
                },
                deep: true,
            },
            internalItemHistory: {
                handler(value) { this.$emit("update:itemHistory", value); },
                deep: true,
            },
            "internalItem.program"(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.internalItem.field = null;
                    this.fields = [];
                    if (newValue != null) this.updateFieldsForProgram(newValue);

                    this.internalItem.superField = null;
                    this.superFields = [];
                    if (newValue != null) this.updateSuperFieldsForProgram(newValue);
                }
                if (newValue === "MFGP") {
                    searchSpecialSpecies({program: "MFGP"})
                        .then((response) => { this.species = response.data; });
                }
            },
            "internalItem.fieldId"(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.internalItem.subfield = null;
                    this.subfields = [];
                    if (newValue != null) this.updateSubfieldsForField(newValue);
                }
            },
            fieldShape: {
                handler(value) {
                    if (value.length > 1) {
                        this.fieldShape[this.fieldShape.length - 1].latitude = this.fieldShape[0].latitude;
                        this.fieldShape[this.fieldShape.length - 1].longitude = this.fieldShape[0].longitude;
                    }
                },
                deep: true,
            },
        },
    };
</script>

<style scoped lang="scss">
@import "../../assets/theme/mytheme/variables";
.geography-info, .status-info {
    display: flex;
    div {
        margin-left: 0.5rem;
        color: var(--text-color-black);
    }
}

.history-table .p-datatable .p-datatable-tbody > tr:focus {
    outline: 0.15rem solid red;
    outline-offset: -0.15rem;
    background: red;
}

.field-link, .status-link {
    display: flex;
    align-items: center;
    text-decoration: underline;
    text-decoration-color: var(--text-color-secondary);
    cursor: default;

    &:hover {
        transition: 0.5s;
        text-decoration-color: var(--text-color-black);
        color: var(--text-color-black);
    }
}
@media screen and (max-width: 1240px) {
    .map-container {
        width: 50% !important;
    }
    .flex-direction-column-1240 {
        flex-direction: column;
        width: 50% !important;
        .full-width {
            width: 100% !important;
        }
    }
}
@media screen and (max-width: 700px) {
    .flex-direction-column-700 {
        flex-direction: column;
        width: 100% !important;
        .map-container, .data-container {
            width: 100% !important;
        }
        .full-width {
            width: 100% !important;
        }
    }
}
</style>
