<template>
    <Dialog class="new-positions-dialog" header="Dodaj nowe stanowisko" :draggable="false" v-model:visible="visible">
        <div class="new-positions-dialog-container flex flex-column">
            <div class="new-positions-dialog-content flex justify-content-between mb-5 gap-8">
                <div class="new-positions-dialog-content__right">
                    <CustomInputText label="Nazwa stanowiska" v-model="newPosition.name" name="newPositionName"
                                     required :showErrors="showErrorMessages"
                                     @input="validateNamePosition" :class="{ 'red-outline-input': isDisabled }" />
                    <CustomInputNumber label="Koordynaty N" v-model="newPosition.coordinates.latitude"
                                       :maxDigits="6" @change="updateMap" name="newPositionLat"
                                       required :showErrors="showErrorMessages" />
                    <CustomInputNumber label="Koordynaty E" v-model="newPosition.coordinates.longitude"
                                       :maxDigits="6" @change="updateMap" name="newPositionLng"
                                       required :showErrors="showErrorMessages" />
                    <div v-if="isDisabled" class="max-w-14rem">Stanowisko o takiej nazwie już istnieje</div>
                    <div v-if="!markerInside && (newPosition.coordinates.latitude != null
                             || newPosition.coordinates.longitude != null)"
                         class="max-w-14rem outside-error">Podane koordynaty wskazują na punkt poza obszarem</div>
                </div>
                <div class="new-positions-dialog-content__left">
                    <div class="new-positions-map"
                         style="height: 400px; width: 600px;">
                        <MapSingleMarker v-model="mapCoordinates" mapId="newPositionMap" height="400px"
                                         @emitCoor="setLatLng" :zoomControls="false"
                                         :field="field.superField ? field.superField.shape : field.shape"
                                         :subfield="field.superField ? field.shape : []"
                                         @isMarkerInside="isMarkerInside" />
                    </div>
                </div>
            </div>
            <CustomButton label="Dodaj nowe stanowisko" :disabled="isDisabled" icon="pi pi-plus"
                          rowReverse bgColor="var(--secondary-color)"
                          color="var(--surface-a)" class="align-self-end" @click="addNewPosition" />
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import {MapMixin} from "@/mixins/MapMixin";
    import {addPosition} from "@/swagger/vue-api-client";
    import MapSingleMarker from "@/components/map/MapSingleMarker.vue";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "NewPositionDialog",

        mixins: [MapMixin],

        components: {
            MapSingleMarker,
            Dialog,
            CustomButton,
            CustomInputNumber,
            CustomInputText,
        },

        emits: [
            "update:modelValue",
            "add",
            "similarPosition",
            "similarPositionObject",
            "newPositionObject",
        ],

        props: {
            modelValue: {
                type: Boolean,
                default: false,
            },
            field: {
                type: Object,
                default: () => {},
            },
            programCode: null,
            allPositions: {
                type: Object,
                default: () => {},
            },
        },

        data() {
            return {
                newPosition: this.getClearNewPosition(),
                mapCoordinates: [],
                showErrorMessages: false,
                isDisabled: false,
                markerInside: true,
            };
        },

        watch: {
            "newPosition.coordinates.latitude"() {
                this.updateMap();
            },
            "newPosition.coordinates.longitude"() {
                this.updateMap();
            },
        },

        methods: {
            validateNamePosition() {
                this.allPositions.forEach((position) => {
                    this.isDisabled = position.name.toLowerCase() === this.newPosition.name.toLowerCase();
                });
            },
            addNewPosition() {
                if (this.markerInside && this.validateNewPositionData(this.newPosition)) {
                    this.showErrorMessages = false;
                    this.newPosition.coordinates.longitude.toString().replace(",", ".");
                    this.newPosition.coordinates.latitude.toString().replace(",", ".");
                    addPosition({programCode: this.programCode, formId: this.$route.params.id, body: this.newPosition})
                        .then((response) => {
                            this.$emit("similarPosition", response.status === 208);
                            if (response.status === 208) {
                                this.$emit("similarPositionObject", response.data[0]);
                                this.$emit("newPositionObject", {
                                    name: this.newPosition.name,
                                    coordinates: this.newPosition.coordinates,
                                });
                            } else {
                                this.$emit("add", response.data);
                            }
                            this.visible = false;
                        })
                        .catch((err) => {
                            console.log(err);
                            ToastUtils.showErrorToast(this, err, "Nie udało się dodać stanowiska, spróbuj ponownie.");
                        });
                } else {
                    this.showErrorMessages = true;
                }
            },
            setLatLng(coordinates) {
                this.newPosition.coordinates.latitude = coordinates.lat;
                this.newPosition.coordinates.longitude = coordinates.lng;
            },
            updateMap() {
                if (this.newPosition.coordinates.latitude == null
                    || this.newPosition.coordinates.longitude == null) return;
                this.mapCoordinates = [
                    this.newPosition.coordinates.latitude,
                    this.newPosition.coordinates.longitude,
                ];
            },
            validateNewPositionData(newPosition) {
                return !(newPosition.name === "" || newPosition.name === null
                    || newPosition.coordinates.longitude === "" || newPosition.coordinates.longitude === null
                    || newPosition.coordinates.latitude === "" || newPosition.coordinates.latitude === null);
            },
            getClearNewPosition() {
                return {
                    fieldId: this.field.id ? this.field.id : null,
                    name: null,
                    coordinates: {
                        longitude: null,
                        latitude: null,
                    },
                };
            },
            isMarkerInside(value) {
                this.markerInside = value;
            },
        },

        computed: {
            visible: {
                get() { return this.modelValue; },
                set(value) {
                    this.newPosition = this.getClearNewPosition();
                    this.mapCoordinates = [];
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../assets/theme/mytheme/variables";
    @import "../../../views/assets/scss/dialogs";
    .outside-error {
        color: $color-red;
    }
</style>
