<template>
    <FormObservationsTemplate ref="observationsTemplate" geobjectType="transekt" v-model:geobject="transect" :geobjects="transects"
                              v-model:additionalInformation="internalForm" additionalInformationInMain
                              v-model:dangers="internalForm.dangers">
        <template v-slot:mainData>
            <TransectFormMainData v-model:form="internalForm" :numberOfTransects="1"/>
        </template>
        <template v-slot:selectGeobject>
            <SelectTransect :transects="transects" :field="form.field.shape" v-model:step="internalStep"
                            v-model:activeIndex="activeIndex" :segmentsInTransects="segmentsInTransects"/>
        </template>
        <template v-slot:separateBirdsData>
            <FormBirds v-model:noBirds="transect.noBirds" v-model:birds="transect.formBirds" />
        </template>
        <template v-slot:habitatData>
            <TransectHabitatData v-model:form="internalForm"/>
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import TransectFormMainData from "@/components/transectForm/TransectFormMainData.vue";
    import SelectTransect from "@/components/transectForm/selectTransect/SelectTransect.vue";
    import FormBirds from "@/components/formBirds/FormBirds.vue";
    import TransectHabitatData from "@/components/transectForm/habitatData/TransectHabitatData.vue";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";

    export default {
        name: "FormMPMObservations",

        components: {
            FormObservationsTemplate,
            TransectHabitatData,
            FormBirds,
            SelectTransect,
            TransectFormMainData,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
            step: {
                type: Number,
                required: true,
            },
        },

        inject: ["editing"],

        data() {
            return {
                transects: [],
                transect: _.cloneDeep(EmptyStructures.getEmptyTransect()),
                activeIndex: null,
            };
        },

        emits: ["update:step", "update:form"],

        beforeMount() {
            this.transects = this.form.formTransects;
            if (this.transects.length > 0) {
                this.transects.forEach((t, i) => {
                    if (!(t.notAudited === true) && this.activeIndex == null) this.activeIndex = i;
                });
            }
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            segmentsInTransects() {
                const first5 = this.transects.filter((t) => t.ordinal <= 5);
                return [first5];
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
            activeIndex(value) { this.transect = this.transects[value]; },
        },
    };
</script>

<style lang="scss">
</style>
