<template>
    <Dialog header="Lista błędów importu" v-model:visible="visible" class="import-errors-dialog" modal
            :draggable="false">
        <ul class="list-none p-0">
            <li class="my-2" style="white-space: pre-line">
                {{ errors.message }}
            </li>
        </ul>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";

    export default {
        name: "ImportErrorsDialog",

        components: {
            Dialog,
        },

        props: {
            modelValue: null,
            errors: null,
        },

        computed: {
            visible: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../assets/theme/mytheme/variables";
    @import "../../../views/assets/scss/dialogs.scss";

    .import-errors-dialog.p-dialog {
        min-width: 320px;
        .p-dialog-content {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }
</style>
