<template>
    <div class="p-3">
        <CustomMultiSelect label="Wyświetlane kolumny" :items="allColumns" v-model="selectColumns" item-label="header"
                           item-value="field" name="selectColumns" prevent-sorting @hide="updateTableColumns"/>
        <RawDataTable :columns="tableColumns" :programCode="programCode"/>
    </div>
</template>

<script>
    import _ from "lodash";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import RawDataTable from "@/views/rawData/components/RawDataTable.vue";

    export default {
        name: "RawDataView",

        components: {
            RawDataTable,
            CustomMultiSelect,
        },

        data() {
            return {
                tableColumns: [],
                selectColumns: [],
                allColumns: [],
                fieldsPrograms: ["MZPW", "MWOP"], // add program code if field program
                manyControlsPrograms: ["MCH", "MDU", "MOP", "MOG", "MPB", "MRY", "MKR", "MPS", "MRC", "MWOP", "MFGP", "MSL", "MMC", "MRD"], // add program code if many-controls program
                programCode: this.$route.params.programCode,
                multiSelectProps: {
                    filterType: "dictionary",
                    filterFilter: true,
                    filterItemLabel: "name",
                    filterItemValue: "id",
                    filterClass: "flex-1 w-0 mt-3",
                    filterEmptyValue: false,
                },
                year: null,
                field: null,
                superfield: null,
                noFormControl: null,
                notAudited: null,
                noControl: null,
                position: null,
                observer: null,
                additionalObservers: null,
                nestType: null,
                treeType: null,
                nestLat: null,
                nestLng: null,
                nestAdditionalInfo: null,
                control: null,
                controlType: null,
                controlDate: null,
                startTime: null,
                startTime1: null,
                startTime2: null,
                endTime: null,
                endTime1: null,
                endTime2: null,
                clouds: null,
                rain: null,
                wind: null,
                visibility: null,
                audibility: null,
                countingMethod: null,
                nestingCriteria: null,
                nestingCriteriaNoFilter: null,
                males: null,
                females: null,
                otherAdults: null,
                nestlings: null,
                young: null,
                all: null,
                birdsAdditionalInfo: null,
                notes: null,
                habitatType: null,
                habitatType2: null,
                plantsType: null,
                species: null,
                nests: null,
                adults: null,
                pairs: null,
                transect: null,
                sex: null,
                distance: null,
                distanceNum: null,
                count: null,
                birds: null,
                noBirds: null,
                noMammals: null,
                noNests: null,
                mammalsPresent: null,
                mammals: null,
                shipName: null,
                captain: null,
                inFlight: null,
                // shipSide: null,
                depth: null,
                seaState: null,
                transectZone: null,
                controlMethod: null,
                flockNumber: null,
                age: null,
                icing: null,
                completeness: null,
                humanTraces: null,
                occupiedNests: null,
                occupiedNestsOnGround: null,
                humanTracesDescription: null,
                colonyLocation: null,
                dominantTreeType: null,
                lossesCircumstances: null,
                lossesStage: null,
                additionalInfoNestlings: null,
                breedingResult: null,
                entireBroodLost: null,
                humanActivity: null,
                countApproximation: null,
                countSpecial: null,
                notCounted: null,
                counted: null,
                habitatTypeMCZ: null,
                otherHabitat: null,
                habitatType2MCZ: null,
                habitatControlNotes: null,
                buildingDistance: null,
                pointStartTime: null,
                pointEndTime: null,
                thermalModernization: null,
                swiftBoxes: null,
                magpieNests: null,
                crowNests: null,
                point: null,
                temperature: null,
                temperatureMin: null,
                temperatureMax: null,
                snowMin: null,
                snowMax: null,
                pressureMin: null,
                pressureMax: null,
                reaction: null,
                districts: null,
                azimuth: null,
                notesBird: null,
                positionDanger: null,
                observationTime: null,
                count1: null,
                count2: null,
                count3: null,
                minimum: null,
                maximum: null,
                activity: null,
                singingMales: null,
                bitingNumber: null,
                bitingSpecies: null,
                smieszkaNest: null,
                categoryA: null,
                categoryB: null,
                categoryC: null,
                noZolna: null,
                noZolnaHabitat: null,
                zolnaHoles: null,
                noBrzegowka: null,
                brzegowkaHoles: null,
                breedingNotCount: null,
                loss: null,
                pair: null,
                noOthers: null,
                when: null,
                noCounting: null,
                controlNotRealizedReason: null,
                animalDisplay: null,
                obsevationMethod: null,
                pairCounter: null,
                noWater: null,
                otherHabitatText: null,
                vegetationType: null,
                otherVegetation: null,
                polygamousBehavior: null,
                polygamousDescription: null,
                youngSize: null,
                regionalCoordinator: null,
                danger: null,
                form: null,
            };
        },

        beforeMount() {
            this.assignColumnProps();
            /* eslint-disable */
            if (this.$route.path.includes("dangers")) {
                this.programCode = "danger";
                this.allColumns = this.mergeArrays([this.subfield, this.danger, this.regionalCoordinator]);
            } else if (this.programCode === "MPS") {
                this.allColumns = this.mergeArrays([this.position, this.controlType, this.controlDate, this.startTime, this.endTime, this.noBirds, this.males, this.females, this.otherAdults, this.nestlings, this.countingMethod, this.nestingCriteria, this.birdsAdditionalInfo,
                    this.nestLat, this.nestLng, this.nestType, this.nestAdditionalInfo, this.treeType, this.clouds, this.rain, this.wind, this.visibility]);
            } else if (this.programCode === "MCH") {
                this.allColumns = this.mergeArrays([this.superfield, this.position, this.controlType, this.controlDate, this.startTime, this.endTime, this.noBirds, this.species,
                    this.nests, this.adults, this.pairs, this.countingMethod, this.nestingCriteriaNoFilter, this.habitatType, this.plantsType]);
            } else if (this.programCode === "MNG") {
                this.allColumns = this.mergeArrays([this.position, this.control, this.countingMethod, this.controlMethod, this.wind, this.clouds, this.visibility, this.rain, this.controlMethod,
                    this.controlDate, this.startTime, this.endTime, this.noBirds, this.habitatType, this.birds, this.flockNumber]);
            } else if (this.programCode === "MPPLBirds") {
                this.allColumns = this.mergeArrays([this.transect, this.control, this.controlDate, this.startTime1, this.endTime1, this.startTime2, this.endTime2, this.noBirds, this.species,
                    this.count, this.clouds, this.rain, this.wind, this.visibility, this.sex, this.distance]);
            } else if (this.programCode === "MPPLMammals") {
                this.allColumns = this.mergeArrays([this.transect, this.control, this.controlDate, this.noMammals, this.count, this.mammals, this.mammalsPresent, this.distance]);
            } else if (this.programCode === "MPPLNests") {
                this.allColumns = this.mergeArrays([this.control, this.controlDate, this.noNests, this.nestSpecies, this.count, this.nestlings]);
            } else if (this.programCode === "MZPM") {
                this.allColumns = this.mergeArrays([this.transect, this.control, this.controlDate, this.startTime1, this.species,
                    this.count, this.transectZone, this.clouds, this.rain, this.depth, this.seaState, this.noBirds, this.inFlight, /*this.shipSide,*/
                    this.shipName, this.captain]);
            } else if (this.programCode === "MPM") {
                this.allColumns = this.mergeArrays([this.transect, this.control, this.controlDate, this.startTime, this.endTime, this.noBirds, this.species,
                    this.count, this.clouds, this.rain, this.wind, this.visibility, this.sex, this.distance]);
            } else if (this.programCode === "MWOT") {
                this.allColumns = this.mergeArrays([this.transect, this.control, this.controlDate, this.startTime, this.endTime, this.noBirds, this.count,
                    this.clouds, this.rain, this.wind, this.visibility, this.distance]);
            } else if (this.programCode === "MKR") {
                this.allColumns = this.mergeArrays([this.position, this.controlType, this.controlDate, this.startTime, this.endTime, this.noBirds, this.adults,
                    this.young, this.nestlings, this.nestingCriteria, this.clouds, this.rain, this.wind]);
            } else if (this.programCode === "MZPW") {
                this.allColumns = this.mergeArrays([this.controlDate, this.startTime, this.endTime, this.noBirds, this.species,
                    this.count, this.controlMethod, this.sex, this.age, this.completeness, this.icing, this.inFlight]);
            } else if (this.programCode === "MKO") {
                this.allColumns = this.mergeArrays([this.position, this.controlDate, this.startTime, this.endTime, this.noBirds, this.occupiedNests, this.occupiedNestsOnGround, this.humanTraces, this.humanTracesDescription, this.colonyLocation,
                    this.dominantTreeType, this.controlMethod]);
            } else if (this.programCode === "MRC") {
                this.allColumns = this.mergeArrays([this.position, this.controlType, this.controlDate, this.startTime, this.endTime, this.noBirds, this.nestlings, this.occupiedNests, this.controlMethod, this.additionalInfoNestlings]);
            } else if (this.programCode === "MRCLosses") {
                this.allColumns = this.mergeArrays([this.position, this.count, this.lossesCircumstances, this.lossesStage]);
            } else if (["MOP", "MOG", "MPB", "MRY"].includes(this.programCode)) {
                this.allColumns = this.mergeArrays([this.position, this.controlDate, this.controlType,
                    this.treeType, this.newNest, this.artificialNest,
                    this.nestingCriteria, this.breedingResult, this.controlMethod, this.nestlings, this.lossesStage,
                    this.lossesCircumstances, this.entireBroodLost, this.humanActivity]);
            } else if (this.programCode === "MGZ") {
                this.allColumns = this.mergeArrays([this.position, this.controlDate, this.startTime, this.endTime,
                    this.noBirds, this.species, this.count, this.countApproximation,
                    this.countSpecial, this.habitatType, this.habitatType2, this.notCounted]);
            } else if (this.programCode === "MCZ") {
                this.allColumns = this.mergeArrays([this.position, this.species, this.controlDate, this.startTime, this.endTime, this.buildingDistance, this.controlMethod, this.habitatTypeMCZ, this.otherHabitat,
                    this.habitatType2MCZ, this.habitatControlNotes, this.noBirds, this.counted, this.treeType, this.nests, this.nestsOnTrees]);
            } else if (this.programCode === "MPPM") {
                this.allColumns = this.mergeArrays([this.point, this.species, this.control, this.controlDate, this.startTime, this.endTime, this.pointStartTime, this.pointEndTime,
                    /*this.habitatType, this.habitatType2, this.thermalModernization, this.swiftBoxes, */this.noBirds, this.count, this.sex, this.magpieNests,
                    this.crowNests]);
            } else if (this.programCode === "MSKR") {
                this.allColumns = this.mergeArrays([this.control, this.point, this.species, this.controlDate, this.temperatureMin, this.temperatureMax, this.snowMin, this.snowMax,
                    this.pressureMin, this.pressureMax, this.startTime, this.endTime, this.pointStartTime, this.wind, this.clouds, this.rain, this.audibility,
                    this.noBirds, this.males, this.females, this.otherAdults, this.young, this.all, this.reaction, this.districts]);
            } else if (this.programCode === "MPD") {
                this.allColumns = this.mergeArrays([this.point, this.species, this.controlDate, this.startTime, this.endTime, this.noBirds, this.districts,
                    this.count, this.pointStartTime, this.wind, this.clouds, this.rain, this.audibility]);
            } else if (this.programCode === "MNZ") {
                this.allColumns = this.mergeArrays([this.position, this.controlDate, this.startTime, this.endTime, this.clouds,
                    this.rain, this.wind, this.visibility, this.temperature, this.controlMethod, this.noBirds, this.count]);
            } else if (this.programCode === "MLSL") {
                this.allColumns = this.mergeArrays([this.control, this.point, this.controlDate, this.temperatureMin, this.temperatureMax, this.snowMin, this.snowMax,
                    this.pressureMin, this.pressureMax, this.pointStartTime, this.clouds, this.rain, this.wind, this.audibility,
                    this.noBirds, this.species, this.count, this.sex, this.age, this.distanceNum, this.azimuth, this.reaction, this.notesBird]);
            } else if (this.programCode === "MDU") {
                this.allColumns = this.mergeArrays([this.position, this.controlDate, this.controlType, this.startTime, this.endTime, this.clouds,
                    this.rain, this.wind, this.positionDanger, this.noBirds, this.countingMethod, this.observationTime,
                    this.count1, this.count2, this.count3, this.minimum, this.maximum, this.activity]);
            } else if (this.programCode === "MWOP") {
                this.allColumns = this.mergeArrays([this.superfield, this.controlType, this.controlDate, this.startTime, this.endTime, this.clouds, this.rain, this.wind, this.visibility, this.singingMales]);
            } else if (this.programCode === "MPG") {
                this.allColumns = this.mergeArrays([this.point, this.controlDate, this.startTime, this.endTime, this.clouds, this.rain, this.wind, this.visibility,
                    this.pointStartTime, this.noBirds, this.species, this.count, this.sex, this.distance]);
            } else if (this.programCode === "MLSBirds") {
                this.allColumns = this.mergeArrays([this.transect, this.controlDate, this.startTime, this.endTime, this.visibilityNumber, this.bitingNumber, this.bitingSpecies, this.noBirds, this.species, this.count, this.nestingCriteria, this.distance]);
            } else if (this.programCode === "MLSMammals") {
                this.allColumns = this.mergeArrays([this.transect, this.controlDate, this.startTime, this.endTime, this.visibilityNumber, this.bitingNumber, this.bitingSpecies, this.noMammals, this.mammals, this.count, this.nestingCriteria, this.distance]);
            } else if (this.programCode === "MPWR") {
                this.allColumns = this.mergeArrays([this.position, this.controlDate, this.startTime, this.endTime, this.habitatType, this.smieszkaNest, this.noBirds, this.species, this.count, this.nestingCriteria, this.nests, this.pairs, this.categoryA, this.categoryB, this.categoryC, this.notesBird]);
            } else if (this.programCode === "MZO") {
                this.allColumns = this.mergeArrays([this.position, this.controlDate, this.startTime, this.endTime, this.noZolnaHabitat, this.noZolna, this.count, this.zolnaHoles, this.noBrzegowka, this.breedingNotCount, this.brzegowkaHoles]);
            } else if (this.programCode === "MSL") {
                this.allColumns = this.mergeArrays([this.position, this.controlDate, this.startTime, this.endTime, this.noBirds, this.count, this.nestingCriteria]);
            } else if (this.programCode === "MFGP") {
                this.allColumns = this.mergeArrays([this.position, this.controlDate, this.controlMethod, this.habitatType, this.treeType, this.noBirds, this.species, this.pairs, this.nests, this.nestingCriteria, this.breedingResult, this.notesBird])
            } else if (this.programCode === "MMC") {
                this.allColumns = this.mergeArrays([this.position, this.controlType, this.noControl, this.controlDate, this.startTime, this.endTime, this.controlMethod, this.noBirds, this.species, this.pairCounter, this.nestingCriteria, this.loss, this.pair, this.notesBird, this.noOthers, this.countingMethod, this.pairs])
            } else if (this.programCode === "MRD") {
                this.allColumns = this.mergeArrays([this.control, this.point, this.controlDate, this.startTime, this.endTime, this.clouds, this.wind, this.pointStartTime, this.noCounting, this.controlNotRealizedReason, this.animalDisplay, this.noBirds, this.species, this.count, this.obsevationMethod]);
            } else if (this.programCode === "MPO") {
                this.allColumns = this.mergeArrays([this.controlDate, this.startTime, this.endTime, this.noWater, this.habitatType, this.otherHabitatText, this.vegetationType, this.otherVegetation, this.noBirds, this.nestingCriteria, this.count, this.young, this.notesBird]);
            } else if (this.programCode === "MCY") {
                this.allColumns = this.mergeArrays([this.control, this.position, this.controlDate, this.controlMethod, this.noBirds, this.species, this.count, this.nestingCriteria, this.polygamousBehavior, this.polygamousDescription, this.breedingResult, this.young, this.youngSize]);
            } else {
                this.allColumns = [];
            }
            /* eslint-enable */
            this.allColumns.forEach((c) => this.selectColumns.push(c.field));
            this.updateTableColumns();
        },

        methods: {
            assignColumnProps() {
                /* eslint-disable */
                this.year = {header: "Rok", field: "year", filterField: "year", showFilterMatchModes: false,
                    filterMenuClass: "w-16rem", sortable: true, bodyLink: true,
                    linkTo: (data) => this.getProgramLink(data), bodyContent: (data) => data.year,
                    filterType: "range", filterOtherAttrs: {}};
                this.field = {header: "Powierzchnia", field: "field.name", filterField: "field",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: true,
                    linkTo: (data) => this.getFieldLink(data), bodyContent: (data) => data.field.name,
                    filterItemsKey: "fields", ...this.multiSelectProps, filterOtherAttrs: {}};
                this.superfield = {header: "Powierzchnia główna", field: "superfield.name", filterField: "superfield",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: true,
                    linkTo: (data) => this.getSuperfieldLink(data), bodyContent: (data) => data.superfield.name,
                    filterItemsKey: "fields", ...this.multiSelectProps, filterOtherAttrs: {}};
                this.subfield = {header: "Podpowierzchnia", field: "subfield", filterField: "subfield.id",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: true,
                    linkTo: (data) => this.getSubfieldLink(data), bodyContent: (data) => data.subfield?.name,
                    filterItemsKey: "fields", ...this.multiSelectProps, filterOtherAttrs: {}};
                this.noFormControl = this.getNoControlColumn("Nie skontrolowano", "noFormControl");
                this.notAudited = this.getNoControlColumn("Nie skontrolowano obiektu", "notAudited");
                this.noControl = this.getNoControlColumn("Nie wykonano kontroli", "noControl");
                this.position = {header: "Stanowisko", field: "position.name", filterField: "position",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: true,
                    linkTo: (data) => this.getPositionLink(data), bodyContent: (data) => data.position?.name,
                    filterItemsKey: "positions", ...this.multiSelectProps, filterOtherAttrs: {}};
                this.transect = {header: "Transekt", field: "transect.name", filterField: "transect",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: true,
                    linkTo: (data) => this.getTransectLink(data), bodyContent: (data) => data.transect?.name,
                    filterItemsKey: "transects", ...this.multiSelectProps, filterItemLabel: "label",
                    filterItemValue: "key", filterOtherAttrs: {}};
                this.point = {header: "Punkt", field: "point.name", filterField: "point",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: true,
                    linkTo: (data) => this.getPointLink(data), bodyContent: (data) => data.point?.name,
                    filterItemsKey: "points", ...this.multiSelectProps, filterItemLabel: "label",
                    filterItemValue: "key", filterOtherAttrs: {}}
                this.observer = {header: "Obserwator", field: "observer.name", filterField: "observer",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: false,
                    bodyClass: () => "", bodyTooltip: false, bodyContent: (data) => data.observer?.name,
                    filterItemsKey: "observers", ...this.multiSelectProps, filterOtherAttrs: {}};
                this.regionalCoordinator = {header: "Koordynator regionalny", field: "regionalCoordinator.name", filterField: "regionalCoordinator",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: false,
                    bodyClass: () => "", bodyTooltip: false, bodyContent: (data) => data.regionalCoordinator?.name,
                    filterItemsKey: "regionalCoordinators", ...this.multiSelectProps, filterOtherAttrs: {}};
                this.additionalObservers = this.getLongTextColumn("Dodatkowi obserwatorzy", "additionalObservers");
                this.nestType = this.getTooltipColumn("Rodzaj gniazda", "nestType");
                this.treeType = this.getTooltipColumn("Nazwa drzewa", "treeType");
                this.nestLat = {header: "Współrzędna N gniazda", field: "nestLat", filterField: null,
                    showFilterMatchModes: false, filterMenuClass: "", sortable: true, bodyLink: false,
                    bodyClass: () => "", bodyTooltip: false, bodyContent: (data) => data.nestLat, filterOtherAttrs: {}};
                this.nestLng = {header: "Współrzędna E gniazda", field: "nestLng", filterField: null,
                    showFilterMatchModes: false, filterMenuClass: "", sortable: true, bodyLink: false,
                    bodyClass: () => "", bodyTooltip: false, bodyContent: (data) => data.nestLng, filterOtherAttrs: {}};
                this.nestAdditionalInfo = this.getLongTextColumn("Dodatkowe informacje o gnieździe", "nestAdditionalInfo");
                this.controlType = this.getTooltipColumn("Kontrola", "controlType");
                this.control = this.getTooltipColumn("Kontrola", "control");
                this.controlMethod = this.getTooltipColumn("Metoda kontroli", "controlMethod");
                this.controlDate = {header: "Data kontroli", field: "controlDate", filterField: "controlDate",
                    showFilterMatchModes: false, filterMenuClass: "w-27.5rem", sortable: true, bodyLink: false,
                    bodyClass: () => "nowrap", bodyTooltip: false,
                    bodyContent: (data) => data.controlDate,
                    filterType: "date-range", filterSelectionMode: "range", filterOtherAttrs: {}};
                this.startTime = this.getTimeColumn("Czas rozpoczęcia", "startTime");
                this.startTime1 = this.getTimeColumn("Czas rozpoczęcia", "startTime1");
                this.startTime2 = this.getTimeColumn("Czas rozpoczęcia", "startTime2");
                this.pointStartTime = this.getTimeColumn("Czas rozpoczęcia liczenia", "pointStartTime");
                this.endTime = this.getTimeColumn("Czas zakończenia", "endTime");
                this.endTime1 = this.getTimeColumn("Czas zakończenia", "endTime1");
                this.endTime2 = this.getTimeColumn("Czas zakończenia", "endTime2");
                this.pointEndTime = this.getTimeColumn("Czas zakończenia liczenia", "pointEndTime");
                this.clouds = this.getTooltipColumn("Zachmurzenie", "clouds");
                this.rain = this.getTooltipColumn("Deszcz", "rain");
                this.wind = this.getTooltipColumn("Wiatr", "wind");
                this.visibility = this.getTooltipColumn("Widoczność", "visibility");
                this.audibility = this.getTooltipColumn("Słyszalność", "audibility");
                this.noBirds = this.getBooleanColumn("Brak ptaków", "noBirds");
                this.noMammals = this.getBooleanColumn("Brak ssaków", "noMammals");
                this.mammalsPresent = this.getBooleanColumn("Liczono ssaki", "mammalsPresent");
                this.noNests = this.getBooleanColumn("Brak gniazd", "nests");
                this.countingMethod = this.getTooltipColumn("Metoda liczenia", "countingMethod");
                this.nestingCriteria = this.getTooltipColumn("Kryterium lęgowości", "nestingCriteria");
                this.nestingCriteria1 = this.getTooltipColumn("Kontrola 1 - kryterium lęgowości", "nestingCriteria1");
                this.nestingCriteria2 = this.getTooltipColumn("Kontrola 2 - kryterium lęgowości", "nestingCriteria2");
                this.sex = this.getTooltipColumn("Płeć", "sex");
                this.distance = this.getTooltipColumn("Kategoria odległości", "distance");
                this.distanceNum = this.getNumberColumn("Odległość [m]", "distance");
                this.nestingCriteriaNoFilter = this.getLongTextColumn("Kryteria lęgowości", "nestingCriteria");
                this.males = this.getNumberColumn("L. samców", "males");
                this.females = this.getNumberColumn("L. samic", "females");
                this.otherAdults = this.getNumberColumn("L. ad. nieozn.", "otherAdults");
                this.nestlings = this.getNumberColumn("Liczba piskląt", "nestlings");
                this.young = this.getNumberColumn("Liczba osobników młodych", "young");
                this.count = this.getNumberColumn("Liczba osobników", "count");
                this.visibilityNumber = this.getNumberColumn("Widoczność", "visibility");
                this.birds = this.getNumberColumn("Liczba osobników", "birds");
                this.all = this.getNumberColumn("Suma l. os.", "all");
                this.birdsAdditionalInfo = this.getLongTextColumn("Dodatkowe informacje o ptakach", "formBirdsAdditionalInfo");
                this.notes = this.getLongTextColumn("Uwagi", "notes");
                this.habitatType = this.getTooltipColumn("Siedlisko", "habitatType");
                this.plantsType = this.getTooltipColumn("Typ roślinności", "plantsType");
                this.species = {header: "Gatunek", field: "species.code", filterField: "species",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: false,
                    bodyClass: () => "", bodyTooltip: true, bodyTooltipContent: (data) => data.species?.name,
                    bodyContent: (data) => data.species?.code,
                    filterItemsKey: "programBirds", ...this.multiSelectProps, filterItemLabel: "name",
                    filterItemValue: "id", filterOtherAttrs: {}};
                this.nestSpecies = {header: "Gatunek", field: "species.code", filterField: "species",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: false,
                    bodyClass: () => "", bodyTooltip: true, bodyTooltipContent: (data) => data.species.label,
                    bodyContent: (data) => data.species.code,
                    filterItemsKey: "programSpecies", ...this.multiSelectProps, filterItemLabel: "name",
                    filterItemValue: "code", filterOtherAttrs: {}};
                this.mammals = {header: "Ssaki", field: "mammal.name", filterField: "mammals",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: false,
                    bodyClass: () => "", bodyContent: (data) => data.mammal?.name,
                    filterItemsKey: "mammals", ...this.multiSelectProps, filterItemLabel: "name",
                    filterItemValue: "id", filterOtherAttrs: {}};
                this.nests = this.getNumberColumn("L. gniazd", "nests");
                this.nestsOnTrees = this.getLongTextColumn("L. gniazd na poszczególnych drzewach", "nestsOnTrees");
                this.adults = this.getNumberColumn("L. dorosłych os.", "adults");
                this.pairs = this.getNumberColumn("L. par", "pairs");
                this.shipName = this.getLongTextColumn("Nazwa statku", "shipName");
                this.captain = {header: "Kapitan", field: "captain.name", filterField: "captain",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: false,
                    bodyClass: () => "", bodyTooltip: false, bodyContent: (data) => data.captain?.name,
                    filterItemsKey: "captains", ...this.multiSelectProps, filterOtherAttrs: {}};
                this.newNest = this.getBooleanColumn("Nowe gniazdo", "newNest");
                this.artificialNest = this.getBooleanColumn("Sztuczne gniazdo", "artificialNest");
                this.inFlight = this.getBooleanColumn("W locie", "inFlight");
                // this.shipSide = {header: "Strona statku", field: "shipSide", filterField: "shipName",
                //     showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: false,
                //     filterType: "boolean", filterItems: [{label: 'Lewa strona', id: "left"}, {label: 'Prawa stron', id: "right"}],
                //     filterItemLabel: "label", filterItemValue: "id",
                //     bodyClass: () => "", bodyTooltip: false, bodyContent: (data) => data[field],
                //     filterOtherAttrs: {}};
                this.depth = this.getNumberColumn("Głębokość [m]", "depth");
                this.seaState = this.getTooltipColumn("Stan morza", "seaState");
                this.transectZone = this.getTooltipColumn("Strefa transektu", "transectZone");
                this.flockNumber = this.getNumberColumn("L. stad", "flockNumber");
                this.icing = this.getNumberColumn("Zlodzenie", "icing");
                this.completeness = this.getNumberColumn("Kompletność", "completeness");
                this.age = this.getTooltipColumn("Wiek", "age");
                this.humanTraces = this.getBooleanColumn("Ślady aktywności ludzkiej", "humanTraces");
                this.occupiedNests = this.getNumberColumn("Liczba zajętych gniazd", "occupiedNests");
                this.occupiedNestsOnGround = this.getNumberColumn("W tym gniazd na ziemi", "occupiedNestsOnGround");
                this.pairCounter = this.getNumberColumn("Numer pary", "pairCounter");
                this.humanTracesDescription = this.getLongTextColumn("Opis śladów", "humanTracesDescription");
                this.colonyLocation = this.getTooltipColumn("Lokalizacja kolonii", "colonyLocation", "habitatType");
                this.dominantTreeType = this.getTooltipColumn("Dominujący rodzaj drzew gniazdowych", "dominantTreeType", "treeType");
                this.lossesCircumstances = this.getTooltipColumn("Okoliczności straty", "lossesCircumstances");
                this.lossesStage = this.getTooltipColumn("Etap straty", "lossesStage");
                this.additionalInfoNestlings = this.getLongTextColumn("Dodatkowe informacje o pisklętach", "additionalInfoNestlings");
                this.breedingResult = this.getTooltipColumn("Wynik lęgu", "breedingResult");
                this.entireBroodLost = this.getBooleanColumn("Stracony cały lęg", "entireBroodLost");
                this.humanActivity = this.getTooltipColumn("Aktywność ludzka", "humanActivity");
                this.notCounted = this.getBooleanColumn("Nie liczono", "notCounted");
                this.counted = this.getBooleanColumn("Liczono", "counted");
                this.countApproximation = this.getNumberColumn("Szacunek liczebności stada", "countApproximation");
                this.countSpecial = this.getNumberColumn("Szacunek liczebności A. fabalis/serrirostris", "countSpecial");
                this.habitatType2 = this.getTooltipColumn("Siedlisko 2", "habitatType2");
                this.habitatTypeMCZ = this.getTooltipColumn("Główne usytuowanie kolonii", "habitatType");
                this.otherHabitat = this.getBooleanColumn("Inny typ usytuowania", "otherHabitat");
                this.habitatType2MCZ = this.getTooltipColumn("Dodatkowe usytuowanie kolonii", "habitatType2", "habitatType");
                this.habitatControlNotes = this.getLongTextColumn("Usytuowanie kolonii - Uwagi", "controlNotes");
                this.buildingDistance = this.getNumberColumn("Odległość od użytkowanych zabudowań (m)", "buildingDistance");
                this.magpieNests = this.getNumberColumn("Liczba gniazd sroki", "magpieNests");
                this.crowNests = this.getNumberColumn("Liczba gniazd wrony", "crowNests");
                this.thermalModernization = this.getTooltipColumn("Termomodernizacja", "thermalModernization");
                this.swiftBoxes = this.getBooleanColumn("Budki dla jerzyków", "swiftBoxes");
                this.temperature = this.getTooltipColumn("Temperatura", "temperature");
                this.temperatureMin = this.getNumberColumn("Temperatura min. (°C)", "temperatureMin");
                this.temperatureMax = this.getNumberColumn("Temperatura max. (°C)", "temperatureMax");
                this.snowMin = this.getNumberColumn("Pokrywa śnieżna min. (cm)", "snowMin");
                this.snowMax = this.getNumberColumn("Pokrywa śnieżna max. (cm)", "snowMax");
                this.pressureMin = this.getNumberColumn("Ciśnienie min. (hPa)", "pressureMin");
                this.pressureMax = this.getNumberColumn("Ciśnienie max. (hPa)", "pressureMax");
                this.reaction = this.getTooltipColumn("Czas reakcji", "reaction");
                this.districts = this.getNumberColumn("L. rewirów", "districts");
                this.azimuth = this.getNumberColumn("Kierunek [°]", "azimuth");
                this.notesBird = this.getLongTextColumn("Uwagi - ptaki", "notesBird");
                this.positionDanger = this.getLongTextColumn("Zagrożenia stanowiska", "positionDanger");
                this.observationTime = this.getTimeColumn("Godzina obserwacji", "observationTime");
                this.count1 = this.getNumberColumn("Liczba głosów samców w 1 liczeniu", "count1");
                this.count2 = this.getNumberColumn("Liczba głosów samców w 2 liczeniu", "count2");
                this.count3 = this.getNumberColumn("Liczba głosów samców w 3 liczeniu", "count3");
                this.minimum = this.getNumberColumn("Minimum", "minimum");
                this.maximum = this.getNumberColumn("Maximum", "maximum");
                this.activity = this.getTooltipColumn("Aktywność", "activity");
                this.singingMales = this.getNumberColumn("L. śpiewających samców", "singingMales");
                this.bitingNumber = this.getNumberColumn("L. zwierząt zgryzających (do 300 m od tr.)", "bitingNumber");
                this.bitingSpecies = this.getLongTextColumn("Gatunek/-ki zwierząt zgryzających", "bitingSpecies");
                this.smieszkaNest = this.getBooleanColumn("Gniazda śmieszki", "smieszkaNest");
                this.categoryA = this.getNumberColumn("L. par/terytoriów w kategorii A", "categoryA");
                this.categoryB = this.getNumberColumn("L. par/terytoriów w kategorii B", "categoryB");
                this.categoryC = this.getNumberColumn("L. par/terytoriów w kategorii C", "categoryC");
                this.noZolnaHabitat = this.getBooleanColumn("Brak siedlisk odpowiadających żołnie", "noZolnaHabitat");
                this.noZolna = this.getBooleanColumn("Brak żołn", "noZolna");
                this.zolnaHoles = this.getNumberColumn("L. zajętych nor (żołna)", "zolnaHoles");
                this.noBrzegowka = this.getBooleanColumn("Brak brzegówek", "noBrzegowka");
                this.breedingNotCount = this.getBooleanColumn("Lęgowe, ale nieliczone", "breedingNotCount");
                this.brzegowkaHoles = this.getNumberColumn("L. zajętych nor (brzegówka)", "brzegowkaHoles");
                this.loss = this.getBooleanColumn("Strata między kontrolami", "loss");
                this.pair = this.getTooltipColumn("Para mieszana", "pair");
                this.noOthers = this.getBooleanColumn("Brak innych gatunków", "noOthers");
                this.when = this.getTooltipColumn("Kiedy oceniono liczebność", "when");
                this.noCounting = this.getBooleanColumn("Nie liczono", "noCounting");
                this.controlNotRealizedReason = this.getTooltipColumn("Przyczyna pominięcia punktu", "controlNotRealizedReason");
                this.animalDisplay = this.getTooltipColumn("Wabienie", "animalDisplay");
                this.obsevationMethod = this.getTooltipColumn("Rodzaj obserwacji", "obsevationMethod");
                this.noWater = this.getBooleanColumn("Brak wody", "noWater");
                this.otherHabitatText = this.getLongTextColumn("Inny typ zbiornika", "otherHabitat");
                this.vegetationType = this.getTooltipColumn("Roślinność wynurzona", "vegetationType");
                this.otherVegetation = this.getLongTextColumn("Inna roślinność", "otherVegetation");
                this.polygamousBehavior = this.getBooleanColumn("Zachowania poligamiczne", "polygamousBehavior");
                this.polygamousDescription = this.getLongTextColumn("Opis zachowań poligamicznych", "polygamousDescription");
                this.youngSize = this.getTooltipColumn("Wielkość młodych", "youngSize");
                this.danger = this.getTooltipColumn("Zagrożenie", "danger", null, false);
                this.form = {header: "Formularz", field: "formId", bodyLink: true,
                    linkTo: (data) => this.getFormLink(data), bodyContent: () => "Przejdź do formularza"};
            },
            getTimeColumn(header, field) {
                return {header: header, field: field, filterField: null,
                    showFilterMatchModes: false, filterMenuClass: "", sortable: true, bodyLink: false,
                    bodyClass: () => "", bodyTooltip: false, bodyContent: (data) => data[field]?.slice(0,5),
                    filterOtherAttrs: {}}
            },
            getBooleanColumn(header, field) {
                return {header: header, field: field, filterField: field, showFilterMatchModes: false,
                    filterMenuClass: "w-16rem", sortable: false, bodyLink: false, bodyClass: (data) => data[field] ? "true-style" : "false-style",
                    bodyTooltip: false, bodyContent: (data) => this.computedBoolean(data[field]),
                    filterType: "boolean", filterItems: [{label: 'Tak', id: true}, {label: 'Nie', id: false}],
                    filterItemLabel: "label", filterItemValue: "id", filterClass: "flex-1 w-0 mt-3",
                    filterOtherAttrs: {}};
            },
            getLongTextColumn(header, field) {
                return {header: header, field: field, filterField: null, showFilterMatchModes: false,
                    filterMenuClass: "", sortable: true, bodyLink: false,
                    bodyClass: (data) => this.getWidth(data[field]), bodyTooltip: false,
                    bodyContent: (data) => data[field], filterOtherAttrs: {}};
            },
            getNumberColumn(header, field) {
                return {header: header, field: field, filterField: field, showFilterMatchModes: false,
                    filterMenuClass: "w-16rem", sortable: true, bodyLink: false,
                    bodyClass: () => "", bodyTooltip: false, bodyContent: (data) => data[field],
                    filterType: "range", filterOtherAttrs: {}};
            },
            getTooltipColumn(header, field, filterField = field, sortable = true) {
                return {header: header, field: `${field}.label`, filterField: filterField, showFilterMatchModes: false,
                    filterMenuClass: field === "dominantTreeType" ? "w-24rem" : "w-16rem", sortable: sortable, bodyLink: false,
                    bodyClass: () => "", bodyTooltip: true, bodyTooltipContent: (data) => data[field]?.label,
                    bodyContent: (data) => data[field]?.code,
                    filterItemsKey: filterField, ...this.multiSelectProps, filterItemLabel: "label",
                    filterItemValue: "key", filterOtherAttrs: {}};
            },
            getNoControlColumn(header, field) {
                return {header: header, field: field, filterField: field,
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: false, bodyLink: false,
                    bodyClass: (data) => data[field]?.value ? "true-style" : "false-style",
                    bodyTooltip: (data) => data[field]?.value, bodyTooltipContent: (data) => data[field]?.value ? ("Powód: " + data[field]?.reason + (data[field]?.notes != null && data[field]?.notes.length > 0 ? ("\nNotatki: " + data[field]?.notes) : "")) : "",
                    bodyContent: (data) => this.computedBoolean(data[field]?.value),
                    filterType: "boolean", filterItems: [{label: 'Tak', id: true}, {label: 'Nie', id: false}],
                    filterItemLabel: "label", filterItemValue: "id", filterClass: "flex-1 w-0 mt-3",
                    filterOtherAttrs: {}}
            },
            /* eslint-enable */

            getFieldLink(rowData) {
                return `/field/${rowData.field?.id}`;
            },
            getSuperfieldLink(rowData) {
                return `/field/${rowData.superfield?.id}`;
            },
            getSubfieldLink(rowData) {
                return `/field/${rowData.subfield?.id}`;
            },
            getPositionLink(rowData) {
                return `/position/${rowData.position?.id}`;
            },
            getTransectLink(rowData) {
                return `/transect/${rowData.transect?.id}`;
            },
            getPointLink(rowData) {
                return `/point/${rowData.point?.id}`;
            },
            getProgramLink(rowData) {
                return `/monitoring-program/${rowData.programEditionId}`;
            },
            getFormLink(rowData) {
                return `/form/${rowData.program}/${rowData.formId}`;
            },
            computedBoolean(value) {
                if (value) return "Tak";
                if (value === false) return "Nie";
                return null;
            },
            getWidth(text) {
                if (!text) return "";
                if (text.length > 100) return "w-30rem";
                if (text.length > 20) return "w-23rem";
                return "";
            },
            updateTableColumns() {
                const set = new Set(this.selectColumns);
                const temporaryTable = [];
                this.allColumns.forEach((c) => {
                    if (set.has(c.field)) temporaryTable.push(_.cloneDeep(c));
                });
                this.tableColumns = temporaryTable;
            },
            mergeArrays(arr) {
                let index = 3;
                let newArr;
                if (this.programCode === "danger") {
                    newArr = [this.year, this.observer, this.field, ...arr, this.notes, this.form];
                } else {
                    newArr = [this.year, this.observer, this.field, this.noFormControl, ...arr, this.notes, this.additionalObservers, this.form];
                }

                if (this.programCode !== "danger" && !this.fieldsPrograms.includes(this.programCode)) {
                    index = newArr.indexOf(this.noFormControl);
                    newArr.splice(index + 1, 0, this.notAudited);
                }
                if (this.manyControlsPrograms.includes(this.programCode)) {
                    index = newArr.indexOf(this.noFormControl);
                    newArr.splice(index + 1, 0, this.noControl);
                }
                return newArr;
            },
        },

        watch: {
            "$route.params.programCode"(value) {
                this.programCode = value;
            },
        },
    };
</script>

<style lang="scss">
</style>
