<template>
    <div class="monitoring-program-event-logs-table">
        <DataTable lazy :loading="loading" :value="eventLogs" :totalRecords="totalRecords"
                   dataKey="id" paginator v-model:rows="page.limit" v-model:first="page.offset"
                   @page="onPage" :rows="10" :rowsPerPageOptions="[10,25,50,100,10000]" size="small"
                   @sort="onPage" removableSort selectionMode="single"
                   v-model:filters="searchCriteria" filter-display="menu" @update:filters="search"
                   v-model:expandedRows="expandedRows" @row-click="expandRow"
                   :rowClass="rowClass" class="event-logs-table">
            <Column field="dateTime" header="Data i czas"></Column>
            <Column field="fieldList" header="Powierzchnie"
                    filterField="fieldIds" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #filter="{filterModel,applyFilter}">
                    <CustomMultiSelect v-if="loadLists" label="Powierzchnia"
                                       name="eventLogsField" v-model="filterModel.value"
                                       :items="filterLists.fields" filter
                                       itemLabel="name" itemValue="id" class="flex-1 w-0 mt-3"
                                       @keydown.enter="applyFilter" />
                </template>
                <template #filterclear="{ filterCallback }">
                    <FilterClear :callback="filterCallback" />
                </template>
                <template #filterapply="{ filterCallback }">
                    <FilterApply :callback="filterCallback" />
                </template>
            </Column>
            <Column field="activeUser" header="Wykonał"
                    filterField="activeUserIds" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #body="slotProps">
                    {{ slotProps.data.activeUser?.name }}
                </template>
                <template #filter="{filterModel,applyFilter}">
                    <CustomMultiSelect v-if="loadLists" class="flex-1 w-0 mt-3"
                                       label="Użytkownik" :items="filterLists.activeUsers"
                                       itemLabel="name" itemValue="id" v-model="filterModel.value"
                                       name="eventLogsObservers" dataKey="id"
                                       @keydown.enter="applyFilter" />
                </template>
                <template #filterclear="{ filterCallback }">
                    <FilterClear :callback="filterCallback" />
                </template>
                <template #filterapply="{ filterCallback }">
                    <FilterApply :callback="filterCallback" />
                </template>
            </Column>
            <Column field="passiveUser" header="Zmiana dotyczyła"
                    filterField="passiveUserIds" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #body="slotProps">
                    {{ slotProps.data.passiveUser?.name }}
                </template>
                <template #filter="{filterModel,applyFilter}">
                    <CustomMultiSelect v-if="loadLists" class="flex-1 w-0 mt-3"
                                       label="Użytkownik" :items="filterLists.passiveUsers"
                                       itemLabel="name" itemValue="id" v-model="filterModel.value"
                                       name="eventLogsObservers" dataKey="id"
                                       @keydown.enter="applyFilter" />
                </template>
                <template #filterclear="{ filterCallback }">
                    <FilterClear :callback="filterCallback" />
                </template>
                <template #filterapply="{ filterCallback }">
                    <FilterApply :callback="filterCallback" />
                </template>
            </Column>
            <Column field="log" header="Log"></Column>
            <Column expander class="w-1 hidden" />
            <Column class="w-1 expander text-right">
                <template #body="slotProps">
                    <i v-if="slotProps.data.expand" class="pi pi-angle-down expand-icon cursor-pointer" />
                    <i v-else class="pi pi-angle-right expand-icon cursor-pointer" />
                </template>
            </Column>
            <template #expansion="slotProps">
                <DataTable v-if="slotProps.data.subLogs" lazy :loading="loading"
                           :value="slotProps.data.subLogs" :totalRecords="slotProps.data.subLogs.length"
                           dataKey="id" :paginator="slotProps.data.subLogs.length >= 15" v-model:rows="page.limit" v-model:first="page.offset"
                           @page="onPage" :rows="15" :rowsPerPageOptions="[15,30,50,100]" size="small"
                           @sort="onPage" removableSort class="event-logs-subtable">
                    <Column field="dateTime" />
                    <Column field="fieldList" />
                    <Column field="activeUser">
                        <template #body="slotProps">
                            {{ slotProps.data.activeUser?.name }}
                        </template>
                    </Column>
                    <Column field="passiveUser">
                        <template #body="slotProps">
                            {{ slotProps.data.passiveUser?.name }}
                        </template>
                    </Column>
                    <Column field="log" />
                </DataTable>
            </template>
        </DataTable>
    </div>
</template>

<script>
    import { ref } from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchEventLogs,
        searchEventLogsCount,
        searchEventLogsFilters,
    } from "@/swagger/vue-api-client";
    import FilterApply from "@/components/filters/FilterApply.vue";
    import FilterClear from "@/components/filters/FilterClear.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {FiltersUtils} from "@/utils/FiltersUtils";

    export default {
        name: "EventLogsTable",

        components: {
            CustomMultiSelect,
            FilterClear,
            FilterApply,
            DataTable,
            Column,
        },

        inject: ["fields", "observers"],

        data() {
            return {
                eventLogs: [],
                totalRecords: 0,
                searchCriteria: SearchCriteria.getClearSearchCriteriaEventLogs(),
                page: SearchCriteria.getClearPage(),
                loading: false,
                expandedRows: ref([]),
                filterLists: {},
                loadLists: false,
            };
        },

        emits: ["update:modelValue"],

        beforeMount() {
            if (FiltersUtils.loadFromSessionStorage(`${this.$route.path}[eventLogs]`)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(`${this.$route.path}[eventLogs]`);
            }
        },

        mounted() {
            this.search();
            searchEventLogsFilters({programEditionId: this.$route.params.id})
                .then((response) => {
                    this.filterLists = response.data;
                    this.loadLists = true;
                });
        },

        methods: {
            search() {
                if (this.loading) return;
                this.loading = true;
                setTimeout(() => {
                    const criteria = SearchCriteria.prepareCriteria(
                        this.searchCriteria,
                        this.page,
                        this.filterDescription,
                    );
                    searchEventLogs({programEditionId: this.$route.params.id, body: criteria})
                        .then((response) => {
                            this.eventLogs = response.data;
                            this.eventLogs.forEach((eL, i) => {
                                eL.id = i;
                            });
                            this.eventLogs.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
                            return searchEventLogsCount({programEditionId: this.$route.params.id, body: criteria});
                        })
                        .then((response) => {
                            this.totalRecords = response.data;
                            this.loading = false;
                        });
                }, 100);
            },

            onPage(event) {
                this.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
                this.search();
            },

            expandRow(row) {
                const rows = document.querySelectorAll(".p-row-toggler");
                if (row.data.subLogs && row.data.subLogs.length > 0) {
                    rows[row.index].click();
                }
                row.data.expand = !row.data.expand;
            },

            rowClass(rowData) {
                if (rowData.subLogs && rowData.subLogs.length > 0) return "";
                return "no-expander";
            },
        },
        watch: {
            searchCriteria: {
                handler(value) {
                    FiltersUtils.saveToSessionStorage(`${this.$route.path}[eventLogs]`, value);
                },
                deep: true,
            },
        },
        computed: {
            filterDescription() {
                return SearchCriteria.getSearchCriteriaEventLogsFilterDescription();
            },
        },
    };
</script>

<style lang="scss">
    .p-datatable.event-logs-table {
        .p-datatable-tbody tr.no-expander .expander .expand-icon {
            display: none;
        }
        .expand-icon {
            font-size: 1.5rem;
        }
    }
    .p-datatable.event-logs-subtable {
        .p-datatable-thead {
            display: none;
        }
        .p-datatable-tbody tr td {
            background-color: var(--primary-color);
        }
    }
</style>
