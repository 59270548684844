import {createApp, h} from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import ToastService from "primevue/toastservice";
import PrimeVue from "primevue/config";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import {createI18n} from "vue-i18n";
import router from "./router";
import store from "./store";
import App from "./App";
import {setDomain, isTokenValid} from "./swagger/vue-api-client";
import "./assets/theme/mytheme/theme.scss";
import {SystemRole} from "./utils/SystemRole";
import {messagesEN} from "./messages/messages_en";
import {messagesPL} from "./messages/messages_pl";
import "./validators";

setDomain(process.env.VUE_APP_API_URL);

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        isTokenValid({ $config: { ignoreDefaultInterceptor: true }}).then(() => {
            // token jest ważny, teraz należy sprawdzić role
            const role = SystemRole.getUserRole();

            if (to.matched.some((record) => record.meta.roles && record.meta.roles.indexOf(role) < 0)) {
                // użytkownik nie ma odpowiedniej roli aby wejść na daną stronę
                next({name: "forbidden"});
            } else {
                next();
            }
        }, () => {
            localStorage.removeItem("token");
            next({name: "login", query: {next: to.fullPath}});
        });
    } else {
        next();
    }
});

const messages = {
    EN: {
        message: messagesEN,
    },
    PL: {
        message: messagesPL,
    },
};

// Create VueI18n instance with options
const i18n = createI18n({
    locale: localStorage.getItem("language") ? localStorage.getItem("language") : "PL",
    messages,
});

const app = createApp({
    i18n,
    render: () => h(App),
    }).use(router)
    .use(store)
    .use(VueAxios, axios)
    .use(ToastService)
    .use(i18n)
    .use(PrimeVue, {
        locale: {
            dayNames: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
            dayNamesShort: ["Ndz", "Pon", "Wt", "Śr", "Czw", "Pt", "Sob"],
            dayNamesMin: ["Nd", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
            monthNames: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec",
            "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
            monthNamesShort: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"],
            apply: "Zastosuj",
            clear: "Wyczyść",
            aria: {
                trueLabel: "Tak",
                falseLabel: "Nie",
                nullLabel: "Brak",
                pageLabel: "Strona {page}",
            },
        },
    })
    .mount("#app");

app.axios.interceptors.request.use(async (config) => {
    const tokenValidity = localStorage.getItem("token_validity");
    if (localStorage.getItem("token") !== null && tokenValidity !== null && tokenValidity > Date.now() + 20000) {
        config.headers.Authorization = "Bearer " + localStorage.getItem("token");
        config.withCredentials = true;
    } else {
        const uninterceptedAxiosInstance = axios.create();
        await uninterceptedAxiosInstance.post(process.env.VUE_APP_API_URL + "/token/refresh", undefined, { withCredentials: true })
            .then((response) => {
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("token_validity", String(Date.now() + response.data.expires_in * 1000));
                localStorage.setItem("role", response.data.activeRole);
                localStorage.setItem("roles", response.data.roles);
                localStorage.setItem("fullName", response.data.name);
                config.headers.Authorization = "Bearer " + response.data.token;
                config.withCredentials = true;
            },
        ).catch(() => {});
    }

    return config;
});

const toastList = [];

app.axios.interceptors.response.use(undefined, (error) => {
    const statusCode = error.response ? error.response.status : null;
    console.log(error.config);

    if (!error.config.ignoreDefaultInterceptor) {
        if (statusCode === 403) {
            console.log(app);
            if (!toastList.includes(403)) {
                app.$toast.add({
                    severity: "error",
                    summary: "Odmowa dostępu",
                    detail: "Nie masz uprawnień do wykonania tej akcji",
                    life: 4000,
                });
            }
            toastList.push(403);
            setTimeout(() => {
                if (toastList.includes(403)) {
                    const index = toastList.indexOf(403);
                    toastList.splice(index, 1);
                }
            }, 400);
        }
        return Promise.reject(error);
    }
    throw error;
});
