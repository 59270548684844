<template>
    <div class="flex justify-content-between">
        <div class="flex flex-wrap">
            <div v-for="filter in filterDescription" :key="filter.name">
                <Tag v-if="isFilteredBy(filter)"
                     :value="filter.name" :key="`tag-${filter.name}`" class="filter-tag mx-2 my-1">
                    <div class="filter-tag-content flex gap-2 align-items-center">
                        <span>{{ filter.label }}: {{ computeFilterValue(filter) }}</span>
                        <i class="pi pi-times cursor-pointer" @click="deleteTag(filter)" />
                    </div>
                </Tag>
            </div>
        </div>
        <div>
            <CustomButton label="Wyczyść" @click="$emit('clear')" classes="mx-1 mt-1"/>
            <CustomButton v-for="aA in additionalActions" :label="aA.label" @click="aA.action" classes="mx-1 mt-1" :key="aA.label"/>
        </div>
    </div>
</template>
<script>
    import Tag from "primevue/tag";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "FilterTagsContainer",
        components: {CustomButton, Tag},
        props: {
            filterDescription: {
                type: Array,
                required: true,
            },
            modelValue: {
                type: Object,
                required: true,
            },
            dictionary: {
                type: Object,
                required: false,
            },
            additionalActions: {
                type: Array,
                default: () => [],
            },
            actionLabel: {
                type: String,
                default: "",
            },
        },
        emits: ["update:modelValue", "filterChange", "clear", "action"],
        methods: {
            isFilteredBy(description) {
                const { name } = description;
                if (!description || !description.type) console.log(description);
                if (description.type.includes("range")) {
                    return this.val[name] && this.val[name].value
                        && ((Array.isArray(this.val[name].value)
                            && this.val[name].value.length > 0
                            && this.val[name].value[0]) || (
                            this.val[name].value.length > 1
                            && this.val[name].value[1]));
                }
                if (description.type === "dictionary") {
                    return this.val[name] && this.val[name] !== ""
                        && this.val[name].value && Array.isArray(this.val[name].value)
                        && this.val[name].value.length > 0;
                }
                if (description.type === "boolean") {
                    return this.val[name] && this.val[name] !== ""
                        && this.val[name].value != null;
                }
                return false;
            },
            computeFilterValue(description) {
                const { name } = description;
                if (description.type === "range") {
                    return this.val[name].value[0]
                        + (
                            this.val[name].value.length > 1
                                ? ` – ${this.val[name].value[1]}` : ""
                        );
                }
                if (description.type === "date-range") {
                    return this.val[name].value[0].toLocaleDateString("pl-PL")
                        + (
                            this.val[name].value.length > 1
                                ? ` – ${this.val[name].value[1].toLocaleDateString("pl-PL")}` : ""
                        );
                }
                if (description.type === "dictionary") {
                    return `Wybrano ${this.val[name].value.length}`;
                }
                if (description.type === "boolean") {
                    return this.val[name].value ? "Tak" : "Nie";
                }
                return "";
            },
            deleteTag(description) {
                const { name } = description;
                if (description.type.includes("range")) {
                    this.val[name].value = null;
                }
                if (description.type === "dictionary" || description.type === "boolean") {
                    this.val[name] = "";
                }
                this.$emit("filterChange");
            },
        },
        computed: {
            val: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>
