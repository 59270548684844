<template>
    <div class="p-fluid custom-password field relative" :style="'width: ' + width + 'px;'">
        <span class="p-float-label p-input-icon-right">
            <i v-if="!disabled" :class="icon" class="icon" />
            <Password :id="name" :name="name" v-model="internalValue" :feedback="feedback" :toggleMask="true"
                      @input="handleChange" @blur="handleBlur"
                      v-tooltip.top="title" :class="computedClasses"
                      :autocomplete="autocomplete" :aria-label="name" :disabled="disabled">
                <template #content>
                    <!-- This overrides default content-->
                    <div/>
                </template>
                <template #footer v-if="lowercase || uppercase || numeric
                    || charactersNumber || specialCharacter">
                    <p class="p-mt-2">Wymagania</p>
                    <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                        <li v-if="lowercase">Przynajmniej jedna mała litera</li>
                        <li v-if="uppercase">Przynajmniej jedna wielka litera</li>
                        <li v-if="numeric">Przynajmniej jedna cyfra</li>
                        <li v-if="specialCharacter">Przynajmniej 1 znak specjalny</li>
                        <li v-if="charactersNumber">Przynajmniej 8 znaków</li>
                    </ul>
                </template>
                <template #footer v-else>
                    <p class="p-mt-2">Hasło spełnia wymagania</p>
                </template>
            </Password>
            <CustomLabel :name="name" :label="label" />
        </span>
        <CustomValidationMessage :errorMessage="errorMessage" />
        <CustomValidationMessage :errorMessage="customErrorMessage" />
    </div>
</template>

<script>
    import Password from "primevue/password";
    import {useField} from "vee-validate";
    import Tooltip from "primevue/tooltip";
    import CustomValidationMessage from "./inner/CustomValidationMessage";
    import CustomLabel from "./inner/CustomLabel";

    export default {
        name: "CustomPassword",

        props: {
            name: {
                type: String,
            },
            feedback: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
            },
            icon: {
                type: String,
                default: "pi pi-lock",
            },
            title: {
                type: String,
                default: null,
            },
            width: {
                type: String,
            },
            autocomplete: {
                type: String,
                default: undefined,
            },
            rules: undefined,
            modelValue: null,
            customErrorMessage: {
                type: String,
                default: "",
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["update:modelValue"],

        directives: {
            tooltip: Tooltip,
        },

        data() {
            return {
                lowercase: true,
                uppercase: true,
                numeric: true,
                charactersNumber: true,
                specialCharacter: true,
            };
        },

        watch: {
            modelValue() {
                this.inputValue = this.modelValue;
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.specialCharacter = !(/(?=.*[!@#$%^&*()[\]{};:+=|,<.>`~£§?/'\\-_"])/.test(value));
                    this.uppercase = !(/(?=.*[A-ZŻŹĆŃÓŁĘĄŚ])/.test(value));
                    this.lowercase = !(/(?=.*[a-zżźćńółęąś])/.test(value));
                    this.numeric = !(/(?=.*\d)/.test(value));
                    this.charactersNumber = !(/(?=.*[a-zA-ZżźćńółęąśZŻŹĆŃÓŁĘĄŚ]).{8,}$/.test(value));
                    this.$emit("update:modelValue", value);
                },
            },
            computedClasses() {
                return this.customErrorMessage !== "" ? "p-invalid" : "";
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {Password, CustomValidationMessage, CustomLabel},
    };
</script>

<style lang="scss">
    .custom-password {
        .p-icon {
            right: 1rem;
        }
    }
</style>
