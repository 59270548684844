<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="position" :geobjects="positions"
                              v-model:additionalInformation="position" v-model:dangers="internalForm.dangers">
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition :field="field" v-model="position" :positions="positions" v-model:step="internalStep"/>
        </template>
        <template v-slot:controlData>
            <ControlTypeAndDates v-model="position" />
            <CustomCheckbox label="Brak siedlisk odpowiadających żołnie" name="noZolnaHabitat"
                            v-model="position.noZolnaHabitat" :disabled="!editing" />
            <div v-if="!position.noZolnaHabitat">
                <HeaderComponent headerText="Żołna" :hierarchy="2" uppercase class="mb-3"/>
                <div class="flex column-gap-3 flex-direction-column">
                    <CustomCheckbox label="Brak żołn" name="noZolna" v-model="position.noZolna"
                                    class="w-4 max-width" :disabled="!editing" />
                    <CustomInputNumber v-if="!position.noZolna" label="Maks. l. os. dorosłych" name="count" v-model="position.count"
                                       class="w-4 max-width" required :showErrors="showErrorMessages" :disabled="!editing" />
                    <CustomInputNumber v-if="!position.noZolna" label="L. zajętych nor" name="zolnaHoles" v-model="position.zolnaHoles"
                                       class="w-4 max-width" required :disabled="!editing" :showErrors="showErrorMessages" />
                </div>
                <HeaderComponent headerText="Brzegówka" :hierarchy="2" uppercase class="mb-3"/>
                <div class="flex column-gap-3 flex-direction-column">
                    <CustomCheckbox label="Brak brzegówek" name="noBrzegowka" v-model="position.noBrzegowka"
                                    class="w-4 max-width" :disabled="!editing" />
                    <CustomCheckbox v-if="!position.noBrzegowka" label="Lęgowe, ale nieliczone" name="breedingNotCount" v-model="position.breedingNotCount"
                                    class="w-4 max-width" :disabled="!editing" />
                    <CustomInputNumber v-if="!position.noBrzegowka && !position.breedingNotCount"
                                       label="L. zajętych nor" name="brzegowkaHoles" v-model="position.brzegowkaHoles"
                                       class="w-4 max-width" required :showErrors="showErrorMessages" :disabled="!editing" />
                </div>
            </div>
        </template>
        <template v-if="!position.noZolnaHabitat" v-slot:controlHabitatData>
            <HabitatDataMZO :habitatData="position.habitatData" v-model:habitatDataToChange="position.habitatDataToChange"/>
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import HabitatDataMZO from "./HabitatDataMZO.vue";
    import ControlTypeAndDates from "@/components/formSharedComponents/ControlTypeAndDates.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import HeaderComponent from "@/components/HeaderComponent.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";

    export default {
        name: "FormMZOObservations",

        components: {
            FormObservationsTemplate,
            ObservationsSelectPosition,
            HabitatDataMZO,
            ControlTypeAndDates,
            CustomCheckbox,
            HeaderComponent,
            CustomInputNumber,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
            allPositions: {
                type: Array,
                default: () => [],
            },
            field: {
                type: Array,
                default: () => [],
            },
            step: {
                type: Number,
                required: true,
            },
        },

        inject: ["editing", "controlTimeFrame", "showErrorMessages"],

        data() {
            return {
                positions: [],
                position: _.cloneDeep(EmptyStructures.getEmptyMZOPosition()),
                activeIndex: 0,
            };
        },

        emits: ["update:step"],

        beforeMount() {
            this.positions = this.allPositions.filter((p) => (p.notAudited === null || !p.notAudited));
            // for (let i = 0; i < this.positions.length; i += 1) {
            //     if (this.positions[i].controls == null) {
            //         this.positions[i].controls = [];
            //     }
            //     if (this.positions[i].controls.length === 0) {
            //         this.positions[i].controls.push(_.cloneDeep(EmptyStructures.getEmptyMPSControl()));
            //         this.activeIndex = this.positions[i].controls.length - 1;
            //     }
            // }
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 600px) {
        .flex-direction-column {
            flex-direction: column;
            .max-width {
                width: 100% !important;
            }
        }
    }
</style>
