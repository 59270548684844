<template>
    <CustomButton :label="label" @click="visible = true" :bg-color="bgColor" :color="color" />
    <ImportErrorsDialog v-model="showImportErrorsDialog" :errors="errors" />
    <Dialog v-model:visible="visible" class="import-cast-dialog"
            :closable="false" modal :draggable="false">
        <template #header>
            <CustomFileUpload @importFile="importFile" :multiple="false" accept=".xlsx, .xls"
                              class="import-cast-file-upload" />
        </template>
        <div class="dialog-file-upload-buttons import-cast-dialog-buttons w-100 h-100">
            <CustomButton label="Anuluj" class="flex justify-content-center red-button" @click="visible = false"
                          bgColor="transparent" />
            <CustomButton :label="label" class="green-button" @click="send"
                          :disabled="waitForServer" />
        </div>
    </Dialog>
</template>

<script>
    import axios from "axios";
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomFileUpload from "@/components/CustomFileUpload.vue";
    import ImportErrorsDialog from "@/components/formSharedComponents/dialogs/ImportErrorsDialog.vue";

    export default {
        name: "ImportButton",

        components: {
            CustomFileUpload,
            CustomButton,
            Dialog,
            ImportErrorsDialog,
        },

        props: {
            label: {
                type: String,
                default: "Importuj",
            },
            url: {
                type: String,
                required: true,
            },
            bgColor: {
                type: String,
                default: "var(--surface-a)",
            },
            color: {
                type: String,
                default: "var(--text-color)",
            },
        },

        inject: ["programCode"],

        data() {
            return {
                importedFile: null,
                waitForServer: false,
                showImportErrorsDialog: false,
                errors: null,
                visible: false,
            };
        },

        methods: {
            importFile(value) {
                this.importedFile = value;
            },

            send() {
                this.waitForServer = true;
                // importControlsFromExcel({programEditionId: this.$route.params.id, file: this.importFile})
                //     .then((response) => {
                //         console.log(response);
                //     });
                axios.post(
                    `${process.env.VUE_APP_API_URL}${this.url}`,
                    this.importedFile,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((response) => {
                        console.log(response);
                        this.waitForServer = false;
                        this.visible = false;
                        this.$toast.add({
                            severity: "success",
                            summary: "Zaimportowano pomyślnie",
                            life: 3000,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.response.status === 500) {
                            this.errors = {message: "Wystąpił nieoczekiwany błąd"};
                        } else {
                            this.errors = err.response.data;
                        }
                        this.waitForServer = false;
                        this.showImportErrorsDialog = true;
                        this.visible = false;
                        this.$toast.add({
                            severity: "error",
                            summary: "Wystąpił nieoczekiwany błąd",
                            life: 3000,
                        });
                    });
            },
        },
    };
</script>

<style lang="scss">
@import "../assets/theme/mytheme/variables";
@import "../views/assets/scss/dialogs";
</style>
