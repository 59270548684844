<template>
    <DataTable lazy :loading="loading" :value="eventLogs" :totalRecords="totalRecords"
               dataKey="id" paginator v-model:rows="page.limit" v-model:first="page.offset"
               @page="onPage" :rows="10" :rowsPerPageOptions="[10,25,50,100,10000]" size="small"
               @sort="onPage" removableSort selectionMode="single"
               v-model:filters="searchCriteria" filter-display="menu" @update:filters="search"
               v-model:expandedRows="expandedRows" @row-click="expandRow" class="event-logs-table"
               :rowClass="rowClass">
        <Column field="programEdition" header="Rok">
            <template #body="slotProps">
                <router-link :to="getRouterLink(slotProps.data.programEdition?.id,slotProps.data.program)">
                    <div>
                        {{ slotProps.data.programEdition?.year }}
                    </div>
                </router-link>
            </template>
        </Column>
        <Column field="dateTime" header="Data i czas"></Column>
        <Column field="program" header="Program"
                filterField="programs" :showFilterMatchModes="false" filter-menu-class="w-16rem">
            <template #filter="{filterModel,applyFilter}">
                <CustomMultiSelect v-if="loadLists" label="Program"
                                   name="eventLogsProgram" v-model="filterModel.value"
                                   :items="programs" filter
                                   itemLabel="name" itemValue="name" class="flex-1 w-0 mt-3"
                                   @keydown.enter="applyFilter" />
            </template>
            <template #filterclear="{ filterCallback }">
                <FilterClear :callback="filterCallback" />
            </template>
            <template #filterapply="{ filterCallback }">
                <FilterApply :callback="filterCallback" />
            </template>
        </Column>
        <Column field="fieldList" header="Powierzchnie"
                filterField="fieldList" :showFilterMatchModes="false" filter-menu-class="w-16rem">
            <template #filter="{filterModel,applyFilter}">
                <CustomMultiSelect v-if="loadLists" label="Powierzchnia"
                                   name="eventLogsField" v-model="filterModel.value"
                                   :items="fields" filter
                                   itemLabel="name" itemValue="name" class="flex-1 w-0 mt-3"
                                   @keydown.enter="applyFilter" />
            </template>
            <template #filterclear="{ filterCallback }">
                <FilterClear :callback="filterCallback" />
            </template>
            <template #filterapply="{ filterCallback }">
                <FilterApply :callback="filterCallback" />
            </template>
        </Column>
        <Column field="activeUser" header="Wykonał"
                filterField="activeUserIds" :showFilterMatchModes="false" filter-menu-class="w-16rem">
            <template #body="slotProps">
                {{ slotProps.data.activeUser?.name }}
            </template>
            <template #filter="{filterModel,applyFilter}">
                <CustomMultiSelect v-if="loadLists" class="flex-1 w-0 mt-3"
                                   label="Użytkownik" :items="filterLists.activeUsers"
                                   itemLabel="name" itemValue="id" v-model="filterModel.value"
                                   name="eventLogsObservers" dataKey="id"
                                   @keydown.enter="applyFilter" />
            </template>
            <template #filterclear="{ filterCallback }">
                <FilterClear :callback="filterCallback" />
            </template>
            <template #filterapply="{ filterCallback }">
                <FilterApply :callback="filterCallback" />
            </template>
        </Column>
        <Column field="passiveUser" header="Zmiana dotyczyła"
                filterField="passiveUserIds" :showFilterMatchModes="false" filter-menu-class="w-16rem">
            <template #body="slotProps">
                {{ slotProps.data.passiveUser?.name }}
            </template>
            <template #filter="{filterModel,applyFilter}">
                <CustomMultiSelect v-if="loadLists" class="flex-1 w-0 mt-3"
                                   label="Użytkownik" :items="filterLists.passiveUsers"
                                   itemLabel="name" itemValue="id" v-model="filterModel.value"
                                   name="eventLogsObservers" dataKey="id"
                                   @keydown.enter="applyFilter" />
            </template>
            <template #filterclear="{ filterCallback }">
                <FilterClear :callback="filterCallback" />
            </template>
            <template #filterapply="{ filterCallback }">
                <FilterApply :callback="filterCallback" />
            </template>
        </Column>
        <Column field="log" header="Log"></Column>
        <Column expander class="w-1 hidden" />
        <Column class="w-1 expander text-right">
            <template #body="slotProps">
                <i v-if="slotProps.data.expand" class="pi pi-angle-down expand-icon cursor-pointer" />
                <i v-else class="pi pi-angle-right expand-icon cursor-pointer" />
            </template>
        </Column>
        <template #expansion="slotProps">
            <DataTable v-if="slotProps.data.subLogs" lazy :loading="loading"
                       :value="slotProps.data.subLogs" :totalRecords="slotProps.data.subLogs.length"
                       dataKey="id" :paginator="slotProps.data.subLogs.length >= 15" v-model:rows="page.limit" v-model:first="page.offset"
                       @page="onPage" :rows="15" :rowsPerPageOptions="[15,30,50,100]" size="small"
                       @sort="onPage" removableSort class="event-logs-subtable">
                <Column field="dateTime" />
                <Column field="fieldList" />
                <Column field="activeUser">
                    <template #body="slotProps">
                        {{ slotProps.data.activeUser?.name }}
                    </template>
                </Column>
                <Column field="passiveUser">
                    <template #body="slotProps">
                        {{ slotProps.data.passiveUser?.name }}
                    </template>
                </Column>
                <Column field="log" />
            </DataTable>
        </template>
    </DataTable>
</template>

<script>
    import { ref } from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import FilterApply from "@/components/filters/FilterApply.vue";
    import FilterClear from "@/components/filters/FilterClear.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {FiltersUtils} from "@/utils/FiltersUtils";
    import {
        searchCollectiveEventLogs,
        searchCollectiveEventLogsCount,
        searchEventLogsFilters,
    } from "@/swagger/vue-api-client";

    export default {
        name: "AllEventLogsTable",

        components: {
            DataTable,
            Column,
            CustomMultiSelect,
            FilterClear,
            FilterApply,
        },

        data() {
            return {
                eventLogs: [],
                totalRecords: 0,
                searchCriteria: SearchCriteria.getClearSearchCriteriaEventLogs(),
                page: SearchCriteria.getClearPage(),
                loading: false,
                filterLists: {},
                loadLists: false,
                expandedRows: ref([]),
            };
        },

        beforeMount() {
            if (FiltersUtils.loadFromSessionStorage(`${this.$route.path}[eventLogs]`)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(`${this.$route.path}[eventLogs]`);
            }
        },

        mounted() {
            this.search();
            searchEventLogsFilters()
                .then((response) => {
                    this.filterLists = response.data;
                    this.loadLists = true;
                });
        },

        methods: {
            search() {
                if (this.loading) return;
                this.loading = true;
                setTimeout(() => {
                    const criteria = SearchCriteria.prepareCriteria(
                        this.searchCriteria,
                        this.page,
                        this.filterDescription,
                    );
                    searchCollectiveEventLogs({body: criteria})
                        .then((response) => {
                            this.eventLogs = response.data;
                            this.eventLogs.forEach((eL, i) => {
                                eL.id = i;
                            });
                            this.eventLogs.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
                            return searchCollectiveEventLogsCount({body: criteria});
                        })
                        .then((response) => {
                            this.totalRecords = response.data;
                            this.loading = false;
                        });
                }, 100);
            },

            onPage(event) {
                this.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
                this.search();
            },

            expandRow(row) {
                const rows = document.querySelectorAll(".p-row-toggler");
                if (row.data.subLogs && row.data.subLogs.length > 0) {
                    rows[row.index].click();
                }
                row.data.expand = !row.data.expand;
            },

            rowClass(rowData) {
                if (rowData.subLogs && rowData.subLogs.length > 0) return "";
                return "no-expander";
            },

            getRouterLink(id, program) {
                return `/form/${program}/${id}`;
            },
        },

        computed: {
            filterDescription() {
                return SearchCriteria.getSearchCriteriaEventLogsFilterDescription();
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    FiltersUtils.saveToSessionStorage(`${this.$route.path}[eventLogs]`, value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
    .event-logs-table {
        a {
            display: block;
            color: var(--text-color);
            &:hover {
                color: var(--text-color-black);
            }
        }
    }
</style>
