<template>
    <div class="flex flex-1 flex-wrap add-in-table-header flex-column gap-3" v-focustrap>
        <div class="flex flex-1 column-gap-2 flex-wrap align-items-center">
            <div class="w-2" style="min-width: 200px">
                <CustomSelectOne label="Gatunek" itemLabel="name"
                                 :items="internalProgramBirds.length > 0 ? internalProgramBirds : dicts.programBirds"
                                 v-model="newItem.species" :disabled="!editing"
                                 :name="`species-${index}`" required :showErrors="showErrorMessagesBirds" />
            </div>
            <div class="w-1" style="min-width: 120px">
                <CustomSelectOne label="Kontrola" :items="dicts.controlType"
                                 v-model="newItem.controlType"
                                 :disabled="!editing"
                                 :name="`control-${index}`" required :showErrors="showErrorMessagesBirds"/>
            </div>
            <div class="w-2" style="min-width: 200px">
                <CustomSelectOne label="Metoda liczenia" :items="dicts.countingMethod" :name="`countingMethod-${index}`"
                                 v-model="newItem.countingMethod" :disabled="!editing"
                                 :required="countingMethodRequired" :showErrors="showErrorMessagesBirds" />
            </div>
            <div class="w-1" style="min-width: 100px">
                <CustomInputNumber label="L. gniazd"
                                   :disabled="!editing || newItem.countingMethod?.code === '4' || newItem.countingMethod?.code === '5'"
                                   v-model="newItem.nests" :name="`nestsValue-${index}`" :min="1" />
            </div>
            <div class="w-1" style="min-width: 120px">
                <CustomInputNumber label="L. dorosłych os." v-model="newItem.adults"
                                   :disabled="!editing || !adultsRequired" :min="1"
                                   :name="`adultsValue-${index}`"
                                   :required="adultsRequired" :showErrors="showErrorMessagesBirds"/>
            </div>
            <div class="w-1" style="min-width: 100px">
                <CustomInputNumber label="L. par" v-model="newItem.pairs"
                                   :disabled="!editing || !pairsRequired" :required="pairsRequired" :showErrors="showErrorMessagesBirds"
                                   :name="`nestlingsValue-${index}`" :min="1" />
            </div>
            <div class="w-2" style="min-width: 200px">
                <CustomMultiSelect label="Kryteria lęgowości" v-model="newItem.nestingCriteria" :disabled="!editing"
                                   :name="`nestingCriteria-${index}`" :items="dicts.nestingCriteria" />
            </div>
            <CustomButton v-if="editing" icon="pi pi-plus" label="Dodaj" @click="add"
                          style="position: absolute; right: 14px; margin-bottom: 1rem" v-on:keydown.tab="add" :error="error" class="media-q" />
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import FocusTrap from "primevue/focustrap";
    import {SystemRole} from "@/utils/SystemRole";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";

    export default {
        name: "AddBirdsMCH",

        components: {
            CustomSelectOne,
            CustomInputNumber,
            CustomButton,
            CustomMultiSelect,
        },

        directives: {
            focustrap: FocusTrap,
        },

        props: {
            modelValue: {
                type: Array,
                default: () => [],
            },
            index: null,
            error: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["editing", "dicts", "showErrorMessages"],

        data() {
            return {
                newItem: _.cloneDeep(EmptyStructures.getEmptyMCHBirds()),
                showErrorMessagesBirds: false,
                countingMethodRequired: false,
                adultsRequired: false,
                pairsRequired: false,
                internalProgramBirds: [],
            };
        },

        mounted() {
            if (this.birds === null) this.birds = [];
        },

        methods: {
            add() {
                if (this.newItem.species === null
                    || this.newItem.controlType === null
                    || (this.countingMethodRequired && this.newItem.countingMethod === null)
                    || (this.adultsRequired && this.newItem.adults === null)
                    || (this.pairsRequired && this.newItem.pairs === null)) {
                    this.showErrorMessagesBirds = true;
                } else {
                    this.birds.push({
                        ...this.newItem,
                        ordinal: this.birds.length >= 0 ? this.birds.length + 1 : 1,
                    });
                    this.newItem = _.cloneDeep(EmptyStructures.getEmptyMCHBirds());
                    this.showErrorMessagesBirds = false;
                }
            },
        },

        computed: {
            birds: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            isAdmin() {
                return SystemRole.isAdmin();
            },
        },

        watch: {
            birds: {
                handler(value) {
                    if (value === null) value = [];
                    this.$emit("update:modelValue", value);
                },
                deep: true,
            },
            newItem: {
                handler(value) {
                    if (value.species !== null) {
                        if (value.species.code === "CHLHYB" // rybitwa białowąsa
                            || value.species.code === "CHLNIG" // rybitwa czarna
                            || value.species.code === "CHLLEU") { // rybitwa białoskrzydła
                            this.countingMethodRequired = true;
                        } else {
                            this.countingMethodRequired = false;
                        }
                        if (value.species.code === "PODTUS" // perkoz dwuczuby
                            || value.species.code === "PODENA" // perkoz rdzawoszyi
                            || value.species.code === "PODNIG" // zausznik
                            || value.species.code === "TACRUF") { // perkozek
                            this.adultsRequired = true;
                        } else {
                            this.adultsRequired = false;
                        }
                        if (value.species.code === "CHLHYB" // rybitwa białowąsa
                            || value.species.code === "CHLNIG" // rybitwa czarna
                            || value.species.code === "CHLLEU") { // rybitwa białoskrzydła
                            this.pairsRequired = true;
                        } else {
                            this.pairsRequired = false;
                        }
                    }
                    this.birds.forEach((b) => {
                        if (_.isEqual(b.controlType, value.controlType)) {
                            this.internalProgramBirds = this.dicts.programBirds.filter((pB) => pB.code !== b.species.code);
                            const arr = [];
                            this.internalProgramBirds.forEach((pB) => {
                                arr.push(pB.code);
                            });
                            if (!arr.includes(value.species?.code)) {
                                value.species = null;
                            }
                        } else {
                            this.internalProgramBirds = this.dicts.programBirds;
                        }
                    });
                    if (value.countingMethod?.code === "4" || value.countingMethod?.code === "5") {
                        this.newItem.nests = null;
                    }
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
.add-in-table-header {
    margin-bottom: -1rem,
}
.disabled-not-opacity {
    .p-disabled, .p-component:disabled {
        opacity: 1;
    }
}
.media-q {
    @media screen and (max-width: 1580px) {
        position: relative !important;
        right: 0 !important;
    }
}
</style>
