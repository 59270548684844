<template>
    <Toast/>
    <div id="vue-app">
        <router-view :key="$route.path"/>
    </div>
</template>
<script>
    import Toast from "primevue/toast";
    import { ThemeSettingsHelper } from "@/mixins/themeSettings";

    export default {
        components: {
            Toast,
        },
        beforeCreate() {
            ThemeSettingsHelper.checkLocalStorage();
        },
    };
</script>

<style lang="scss">
</style>
